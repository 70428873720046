import React from 'react';
import * as icons from 'react-feather';
import './Icon.scss';

export type IconName = keyof typeof icons;

export type IconProps = {
  name: IconName;
} & icons.IconProps;

const Icon = ({ name, ...rest }: IconProps) => {
  const IconComponent = icons[name];
  return <IconComponent {...rest} className={'custom-icon'} />;
};

export const MemoizedIconComponent = React.memo(Icon);
