import React from 'react';
import './services-assignment-modal.scss';
import TextBase from '../../../components/TextBase/TextBase';
import { Ticket } from '../../../types/tickets.type';

interface PatientInfoProps {
  ticket: Ticket | undefined;
}

const PatientInfo: React.FC<PatientInfoProps> = ({ ticket }) => {
  const patientPhone = ticket?.patientAssigned.contact.split(' ')[1];
  const patientEmail = ticket?.patientAssigned.contact.split(' ')[0];
  return (
    <div className="patient-info-wrapper">
      <TextBase variant={'h6'} fontWeight={600} content={'ccAssignmentToPatient.patient'} />
      <div className="patient-info">
        <TextBase className="name" variant={'body2'}>
          {ticket?.patientAssigned.name}
        </TextBase>
        <TextBase className="primary-color" variant={'body2'} fontWeight={400} fontSize={14}>
          {patientPhone}
        </TextBase>
        <TextBase className="primary-color">{patientEmail}</TextBase>
        <TextBase className="secondary-color">{ticket?.patientAssigned.address}</TextBase>
      </div>
    </div>
  );
};

export default PatientInfo;
