import { useMemo } from 'react';
import { errorMessageByKey, MessageContent, successMessageByKey } from 'utils/message-utils';
import { TextContentPath } from 'i18n/i18n-config';

export interface UseTicketUpdateInput {
  messageKey: string;
  successMessage: MessageContent;
  errorMessage: MessageContent;
}

export const useMessages = (messageKey: string) => {
  const messages = useMemo<UseTicketUpdateInput>(
    () => ({
      messageKey,
      successMessage: { content: successMessageByKey(messageKey as TextContentPath) },
      errorMessage: { content: errorMessageByKey(messageKey as TextContentPath) }
    }),
    [messageKey]
  );

  return { messages };
};
