import React from 'react';
import ScenarioBuilderHeader from './ScenarioBuilderHeader';
import ScenarioBuilderDiagram from './ScenarioBuilderDiagram';
import './scenarioBuilder.scss';
import { useAppNavigation } from 'utils/app-routing';
import { rappid } from 'services/rappid.service';
import { getChecklistNodes, isValidScenario } from './utils';
import { openPopup, PopupType } from 'utils/popup-utils';
import { useScenarioIdPersistence } from '../common/hooks/useScenarioIdPersistence';
import { useSelectSelectedScenario } from 'redux-store/features/scenarios/scenarios.hooks';
import { useAppDispatch } from 'redux-store/hooks';
import { fetchScenarios, updateScenario } from 'redux-store/features/scenarios/scenarios.actions';

const ScenarioBuilder = () => {
  const dispatch = useAppDispatch();
  const handleNavigateTo = useAppNavigation();
  const scenario = useSelectSelectedScenario();
  useScenarioIdPersistence(scenario);

  const saveDiagram = async () => {
    if (!rappid) return;
    const checklistNodes = getChecklistNodes(rappid.graph);
    const isScenarioValid = isValidScenario(rappid.graph, checklistNodes);
    const validationMessageType = isScenarioValid ? PopupType.SCENARIO_VALID : PopupType.SCENARIO_INVALID;
    openPopup(validationMessageType);
    if (scenario) {
      try {
        dispatch(
          updateScenario({
            scenario: rappid.graph.getCells() ?? [],
            valid: isScenarioValid,
            checklistNodes: checklistNodes,
            id: scenario?.id,
            name: scenario?.name
          })
        );
        openPopup(PopupType.SCENARIO_SAVED_SUCCESS);
        dispatch(fetchScenarios());
      } catch (err) {
        openPopup(PopupType.SCENARIO_SAVED_ERROR);
      }
    } else {
      openPopup(PopupType.SCENARIO_SAVED_ERROR);
    }
  };

  return (
    <>
      <ScenarioBuilderHeader
        handleNavigateTo={handleNavigateTo}
        saveDiagram={saveDiagram}
        scenarioName={scenario?.name ? scenario.name : ''}
      />
      <ScenarioBuilderDiagram />
    </>
  );
};

export default ScenarioBuilder;
