import React from 'react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField } from '@mui/material';
import { uniqueId } from 'lodash';
import DateAdapter from './DateAdapter';
import { TextContentPath } from '../../i18n/i18n-config';
import LabelBase from '../LabelBase/LabelBase';
import { ListSelectOption } from '../../types/select-option.types';
import { InitialFormState } from '../../views/common/hooks/useForm';
import { MemoizedIconComponent } from '../Icon/Icon';
import { TimeValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useTimeValidation';

interface TimePickerBaseProps<T extends InitialFormState> {
  selectedTime: ListSelectOption | undefined;
  handleChange: (eventTargetName: keyof T, selectedValue: ListSelectOption | undefined) => void;
  handleError?: (reason: TimeValidationError | undefined) => void;
  pickerName?: string;
  labelText?: TextContentPath;
  fullWidth?: boolean;
  required?: boolean;
  disabled?: boolean;
  minTime?: Date;
  maxTime?: Date;
}

const TimePickerBase: React.FC<TimePickerBaseProps<InitialFormState>> = ({
  selectedTime,
  handleChange,
  handleError,
  pickerName,
  labelText,
  fullWidth = true,
  required = true,
  disabled = false,
  minTime,
  maxTime
}) => {
  return (
    <DateAdapter>
      <LabelBase label={labelText} />
      <TimePicker
        onError={(reason) => handleError && handleError(reason ?? undefined)}
        disabled={disabled}
        value={selectedTime?.value ?? null}
        inputFormat="hh:mmaa"
        mask={'__:___M'}
        minTime={minTime}
        maxTime={maxTime}
        onChange={(value: Date | null) =>
          handleChange(pickerName as keyof InitialFormState, {
            key: selectedTime?.key ?? uniqueId(),
            value: value ?? undefined
          })
        }
        components={{ OpenPickerIcon: () => <MemoizedIconComponent name="Clock" /> }}
        renderInput={(params) => (
          <TextField
            required={required}
            className="text-field-base"
            {...params}
            fullWidth={fullWidth}
            inputProps={{ ...params.inputProps, placeholder: 'HH:MM' }}
          />
        )}
      />
    </DateAdapter>
  );
};

export default TimePickerBase;
