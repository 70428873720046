import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TablePaginationUnstyled from '@mui/base/TablePaginationUnstyled';
import { SelectChangeEvent } from '@mui/material/Select';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { ChevronDown } from 'react-feather';
import './TablePaginationBase.scss';
import SelectBase from '../../SelectBase/SelectBase';
import { ListSelectOption } from '../../../types/select-option.types';
import PageChangerInput from './PageChangerInput/PageChangerInput';

interface TablePaginationProps {
  count: number;
  page: number;
  rowsPerPage: ListSelectOption;
  rowsPerPageOptions: ListSelectOption[];
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TablePaginationBase: React.FC<TablePaginationProps> = ({
  count,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  handleChangePage,
  handleChangeRowsPerPage
}) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(rowsPerPage);
  const handleChangeList = (event: SelectChangeEvent<unknown>) => {
    setSelectedOption({ key: event.target.value as string, value: parseInt(event.target.value as string, 10) });
    handleChangeRowsPerPage(event as React.ChangeEvent<HTMLInputElement>);
  };
  return (
    <TablePaginationUnstyled
      className="pagination"
      component={'div'}
      components={{
        Actions: () => (
          <TablePaginationActions
            count={count}
            rowsPerPage={selectedOption.value as number}
            page={page}
            onPageChange={handleChangePage}
            getItemAriaLabel={() => ''}
            showFirstButton={false}
            showLastButton={false}
          />
        ),
        DisplayedRows: 'div',
        Select: () => (
          <SelectBase
            onChange={handleChangeList}
            options={rowsPerPageOptions}
            selectedOption={selectedOption}
            IconComponent={ChevronDown}
          />
        )
      }}
      count={count}
      rowsPerPage={rowsPerPage.value as number}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage={t('table.itemsPerPageLabel')}
      labelDisplayedRows={({ count, page }) => (
        <PageChangerInput page={page} rowsPerPage={rowsPerPage} count={count} handleChangePage={handleChangePage} />
      )}
    />
  );
};

export default TablePaginationBase;
