import { MessageContentTypes, MessageKeyTypes, MessageTypes } from 'views/common/enums/message-types.enum';
import { TextContentPath } from 'i18n/i18n-config';

export interface MessageContent {
  content: TextContentPath;
  freeText?: string;
}

export interface Message {
  messageKey: string;
  messageContent: MessageContent;
}

export interface SnackbarMessage {
  type: MessageTypes;
  message: Message;
}

export const successMessageByKey = (messageKey: TextContentPath) =>
  `${messageKey}.${MessageContentTypes.SUCCESS_UPDATE}` as TextContentPath;

export const errorMessageByKey = (messageKey: TextContentPath): TextContentPath =>
  `${messageKey}.${MessageContentTypes.ERROR_UPDATE}` as TextContentPath;

export const setSnackbarMessage = (snackbarMessageSettings: SnackbarMessage) => {
  const {
    type,
    message: { messageKey, messageContent }
  } = snackbarMessageSettings;
  const isError = type === MessageTypes.ERROR_MSG;
  const key = `${messageKey}-${isError ? MessageKeyTypes.ERROR : MessageKeyTypes.SUCCESS}`;
  return { key, content: messageContent.content, freeText: messageContent.freeText };
};
