import React, { FormEvent, useEffect } from 'react';
import { add, addMinutes } from 'date-fns';
import ModalBase from 'components/ModalBase/ModalBase';
import ButtonBase from 'components/ButtonBase/ButtonBase';
import TextFieldBase from 'components/TextFieldBase/TextFieldBase';
import { InitialFormState, useForm } from 'views/common/hooks/useForm';
import { ListSelectOption } from 'types/select-option.types';
import DatePickerBase from 'components/DateTimePicker/DatePickerBase';
import TimePickerBase from 'components/DateTimePicker/TimePickerBase';
import { useModal } from 'views/common/hooks/useModal';
import { ModalTypes } from 'views/common/enums/modal-types.enum';
import { useSelectSelectedTicket } from 'redux-store/features/tickets/tickets.hooks';
import { TicketStatuses } from 'views/common/enums/ticket.enum';
import { Ticket } from 'types/tickets.type';
import { useTicketUpdate } from 'views/common/hooks/useTicketUpdate';
import './ScheduleVisitModal.scss';
import { useMessages } from 'views/common/hooks/useMessage';

interface InitialModalFormState extends InitialFormState {
  ticketId: ListSelectOption | undefined;
  visitDate: ListSelectOption | undefined;
  startHour: ListSelectOption | undefined;
  endHour: ListSelectOption | undefined;
}

const initialScheduleVisitModalState: InitialModalFormState = {
  ticketId: undefined,
  visitDate: undefined,
  startHour: undefined,
  endHour: undefined
};

const ScheduleVisitModal: React.FC = () => {
  const selectedTicket = useSelectSelectedTicket();
  const { isModalOpen, onModalClose } = useModal(ModalTypes.SCHEDULE_VISIT);
  const { messages } = useMessages('scheduleVisit');
  const { onChange, values, onSetError, isFormInvalid, handleResetForm } =
    useForm<InitialModalFormState>(initialScheduleVisitModalState);
  const { handleTicketUpdate } = useTicketUpdate(messages);

  useEffect(() => {
    // selectedTicket is fetched from selector and then on component init injected into form values
    selectedTicket && onChange('ticketId', { key: selectedTicket?.id, value: selectedTicket?.id });
  }, [selectedTicket]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTimeChange = (eventTargetName: never, selectedValue: ListSelectOption | undefined) => {
    onChange(eventTargetName, selectedValue);
    // if (values.endHour?.value) onChange('endHour', { key: values.endHour.key, value: undefined });
  };

  const updateSelectedTicket = (startTime: string, endTime: string, startDate: string) => {
    if (!selectedTicket) return;
    const updatedTicket: Ticket = {
      ...selectedTicket,
      scheduledDate: { startTime, endTime, startDate },
      ticketStatus: TicketStatuses.VISIT_SCHEDULED,
      lastUpdatedDate: new Date().toISOString()
    };
    handleTicketUpdate(updatedTicket);
  };

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    onModalClose();
    const { startHour, endHour, visitDate } = values;
    if (!!startHour && !!endHour && !isFormInvalid(values)) {
      updateSelectedTicket(startHour.value as string, endHour.value as string, visitDate?.value as string);
    }
  };

  useEffect(() => {
    const newDate = new Date();

    if (values.startHour === undefined) {
      onChange('startHour', { key: 'startHour', value: newDate });
    }
    if (values.endHour === undefined) {
      onChange('endHour', { key: 'endHour', value: addMinutes(newDate, 30) });
    }
  }, [selectedTicket, isModalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseModal = () => {
    handleResetForm();
    onModalClose();
  };

  return (
    <ModalBase isModalOpen={isModalOpen} closeModal={handleCloseModal} headerText={'scheduleVisit.scheduleVisit'}>
      <form className="schedule-visit-modal visit-modal modal" onSubmit={(e) => onFormSubmit(e)}>
        <div>
          <TextFieldBase
            fullWidth
            disabled
            labelText={'scheduleVisit.ticketId'}
            name={'ticketId'}
            value={`#${values.ticketId?.value}`}
          />
        </div>
        <div>
          <DatePickerBase
            disablePast={false}
            handleChange={onChange}
            selectedDate={values.visitDate}
            pickerName={'visitDate'}
            labelText={'scheduleVisit.visitDate'}
            handleError={(error) => onSetError('visitDate', error)}
          />
        </div>
        <div className="schedule-visit-modal__timepicker">
          <div>
            <TimePickerBase
              selectedTime={values.startHour}
              handleChange={(eventTargetName, selectedValue) => handleTimeChange(eventTargetName, selectedValue)}
              pickerName={'startHour'}
              labelText={'scheduleVisit.startHour'}
              handleError={(error) => onSetError('startHour', error)}
            />
          </div>
          <div className="schedule-visit-modal__timepicker-separator">-</div>
          <div>
            <TimePickerBase
              selectedTime={values.endHour}
              handleChange={onChange}
              pickerName={'endHour'}
              labelText={'scheduleVisit.endHour'}
              disabled={!values.startHour?.value}
              minTime={add(new Date(values.startHour?.value as string), { minutes: 15 })}
              handleError={(error) => onSetError('endHour', error)}
            />
          </div>
        </div>
        <div className="modal__btn-group">
          <ButtonBase content={'common.cancel'} size={'large'} type={'button'} onClick={handleCloseModal} />
          <ButtonBase
            content={'scheduleVisit.scheduleVisit'}
            variant={'contained'}
            disableElevation
            size={'large'}
            type={'submit'}
            disabled={isFormInvalid(values)}
          />
        </div>
      </form>
    </ModalBase>
  );
};

export default ScheduleVisitModal;
