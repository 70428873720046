import { useCallback, useMemo } from 'react';
import { Ticket } from 'types/tickets.type';
import { setSelectedTicket } from 'redux-store/features/tickets/tickets.slice';
import { useAppDispatch } from 'redux-store/hooks';
import { addMessageToQueue } from 'redux-store/features/snackbar/snackbar.slice';
import { MessageTypes } from 'views/common/enums/message-types.enum';
import { Message, MessageContent, setSnackbarMessage } from 'utils/message-utils';
import { updateAndSelectTicket } from 'redux-store/features/tickets/tickets.actions';

export interface UseTicketUpdateInput {
  messageKey: string;
  successMessage: MessageContent;
  errorMessage: MessageContent;
}

export const useTicketUpdate = (input: UseTicketUpdateInput) => {
  const dispatch = useAppDispatch();

  const successMessage = useMemo(
    () => ({
      messageKey: input.messageKey,
      messageContent: input.successMessage
    }),
    [input]
  );

  const errorMessage = useMemo(
    () => ({
      messageKey: input.messageKey,
      messageContent: input.errorMessage
    }),
    [input]
  );

  const setMessage = useCallback(
    (type: MessageTypes, ticketId: string, message: Message) => {
      const { key, content, freeText } = setSnackbarMessage({ type, message });
      dispatch(addMessageToQueue({ key, content, freeText, type, ticketId }));
    },
    [dispatch]
  );

  const handleTicketUpdate = async (ticket: Ticket): Promise<boolean> => {
    try {
      dispatch(updateAndSelectTicket(ticket));
      dispatch(setSelectedTicket({ id: ticket.id }));
      setMessage(MessageTypes.SUCCESS_MSG, ticket.id, successMessage);
      return true;
    } catch (error) {
      setMessage(MessageTypes.ERROR_MSG, ticket.id, errorMessage);
      return false;
    }
  };

  return { handleTicketUpdate };
};
