import { TableHeader } from 'components/TableBase/table.types';
import { Ticket } from 'types/tickets.type';
import { InboxTableRow } from 'types/inbox.type';

export const inboxListHeaders: TableHeader[] = [
  {
    column: 'table.column.id',
    id: 'ticketId',
    enableSort: true
  },
  {
    column: 'table.column.name',
    id: 'name',
    enableSort: true
  },
  {
    column: 'table.column.address',
    id: 'address',
    enableSort: true
  },
  {
    column: 'table.column.status',
    id: 'statusDetails',
    enableSort: false
  },
  {
    column: 'table.column.careCoordinator',
    id: 'careCoordinator',
    enableSort: true
  },
  {
    column: 'table.column.updated',
    id: 'lastUpdatedDate',
    enableSort: true
  }
];

export const getInboxRows = (tickets: Ticket[]): InboxTableRow[] => {
  return tickets.map((ticket) => {
    return {
      id: ticket.id,
      ticketId: `#${ticket.id}`,
      name: ticket.patientAssigned.name,
      address: ticket.patientAssigned.address,
      statusDetails: [ticket.ticketStatusLabel, ticket.ticketStatus],
      careCoordinator: ticket.ticketOwner,
      lastUpdatedDate: ticket.lastUpdatedDate
    };
  });
};
