import React from 'react';
import clsx from 'clsx';
import './ListItemLabel.scss';
import { MemoizedIconComponent } from '../../../Icon/Icon';
import TextBase from '../../../TextBase/TextBase';
import { MenuItem } from '../../../../utils/menu-utils';

interface ListItemProps {
  menuItem: MenuItem;
  isSelected?: boolean;
}

const ListItemLabel: React.FC<ListItemProps> = ({ menuItem, isSelected }) => {
  return (
    <div className={clsx('list-item', !menuItem.icon && 'list-item-inset')}>
      {menuItem.icon && (
        <div className="list-item__icon">
          <MemoizedIconComponent name={menuItem.icon} />
        </div>
      )}
      <TextBase content={menuItem.title} className={clsx(isSelected && 'list-item-selected')} />
    </div>
  );
};

export default ListItemLabel;
