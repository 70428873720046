import React from 'react';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import TextBase from 'components/TextBase/TextBase';
import './PatientInfo.scss';
import { emailAddress, phoneNumber } from '../patients.utils';
import { PatientWithId } from 'types/patient.types';

interface PatientDrawerProps {
  currentPatient: null | PatientWithId;
}

const PatientInfo: React.FC<PatientDrawerProps> = ({ currentPatient }) => {
  return (
    <React.Fragment>
      {!!currentPatient && (
        <Box className="patient-info__wrapper">
          <Box className="row">
            <Box className="row-title">
              <TextBase content={'patientDetails.personalInfo'} />
            </Box>
            <Box className="row-data__container">
              <Box className="data">
                <TextBase className="key" content={'patientDetails.gender'} />
                <TextBase className="value">{currentPatient.gender}</TextBase>
              </Box>
              <Box className="data">
                <TextBase className="key" content={'patientDetails.birthday'} />
                <TextBase className="value">{format(new Date(currentPatient.birthdate), 'dd/MM/yyyy')}</TextBase>
              </Box>
            </Box>
          </Box>
          <Box className="row">
            <Box className="row-title">
              <TextBase className="key" content={'patientDetails.contactInfo'} />
            </Box>
            <Box className="row-data__container">
              <Box className="data">
                <TextBase className="key" content={'patientDetails.phone'} />
                <TextBase className="value value--blue">{phoneNumber(currentPatient.contact)}</TextBase>
              </Box>
              <Box className="data">
                <TextBase className="key" content={'patientDetails.email'} />
                <TextBase className="value value--blue">{emailAddress(currentPatient.contact)}</TextBase>
              </Box>
            </Box>
          </Box>
          <Box className="row">
            <Box className="row-title">
              <TextBase className="key" content={'patientDetails.address'} />
            </Box>
            <Box className="row-data__container">
              <Box className="data">
                <TextBase className="key" content={'patientDetails.postalCode'} />
                <TextBase className="value">{currentPatient.address.split(' ')[0].replace(',', '')}</TextBase>
              </Box>
              <Box className="data">
                <TextBase className="key" content={'patientDetails.prefecture'} />
                <TextBase className="value">{currentPatient.address.split(' ')[1].replace(',', '')}</TextBase>
              </Box>
              <Box className="data">
                <TextBase className="key" content={'patientDetails.address'} />
                <TextBase className="value">{currentPatient.address.split(' ')[2].replace(',', '')}</TextBase>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default PatientInfo;
