import { ToStoreObject } from 'services/data/types';
import { CareCoordinator } from 'types/care-coordinator.types';

export const defaultCareCoordinatorsData: ToStoreObject<CareCoordinator>[] = [
  {
    name: 'Yosuke',
    surname: 'Sasada',
    imagePath: '/placeholder/profile-pic-0.svg'
  },
  {
    name: 'Yamaguchi',
    surname: 'Yui',
    imagePath: '/placeholder/profile-pic-1.svg'
  },
  {
    name: 'Ryosuke',
    surname: 'Uno',
    imagePath: '/placeholder/profile-pic-2.svg'
  },
  {
    name: 'Sasaki',
    surname: 'Kaori',
    imagePath: '/placeholder/profile-pic-3.svg'
  },
  {
    name: 'Uno',
    surname: 'Osamu',
    imagePath: '/placeholder/profile-pic-4.svg'
  },
  {
    name: 'Anna',
    surname: 'Osamu',
    imagePath: '/placeholder/profile-pic-1.svg'
  }
];
