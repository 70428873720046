import React, { useRef } from 'react';
import { Button, Input } from '@mui/material';
import TextBase from '../TextBase/TextBase';

interface UploadFileInputBaseProps {
  buttonDisabled: boolean;
  inputOnChange: (e: any) => void;
}

const UploadFileInputBase: React.FC<UploadFileInputBaseProps> = (props) => {
  const { buttonDisabled, inputOnChange } = props;
  const InputRef = useRef<null | HTMLElement>(null);
  const handleClickButton = () => {
    if (InputRef.current !== null) {
      InputRef.current.click();
    }
  };
  return (
    <div>
      <Input
        inputProps={{ accept: 'image/*' }}
        style={{ display: 'none' }}
        type="file"
        onChange={inputOnChange}
        inputRef={InputRef}
      />
      <Button
        disabled={buttonDisabled}
        variant="outlined"
        size="small"
        onClick={() => handleClickButton()}
        className="upload-button"
      >
        <TextBase sx={{ fontSize: '12px' }}>Upload File</TextBase>
      </Button>
    </div>
  );
};

export default UploadFileInputBase;
