import React from 'react';

interface InspectorContainerProps {
  hidden: boolean;
  children?: React.ReactNode;
}

const InspectorContainer: React.FC<InspectorContainerProps> = ({ hidden, children }) => {
  return <div className={`inspector-container ${hidden ? 'hidden' : 'visible'}`}>{children}</div>;
};

export default InspectorContainer;
