import React, { ChangeEvent, FormEvent } from 'react';
import ModalBase from 'components/ModalBase/ModalBase';
import ButtonBase from 'components/ButtonBase/ButtonBase';
import { InitialFormState, useForm } from 'views/common/hooks/useForm';
import { ListSelectOption } from 'types/select-option.types';
import DatePickerBase from 'components/DateTimePicker/DatePickerBase';
import TimePickerBase from 'components/DateTimePicker/TimePickerBase';
import { useModal } from 'views/common/hooks/useModal';
import { ModalTypes } from 'views/common/enums/modal-types.enum';
import TextFieldBase from 'components/TextFieldBase/TextFieldBase';
import { useSelectSelectedTicket } from 'redux-store/features/tickets/tickets.hooks';
import { ScheduledDate, Ticket } from 'types/tickets.type';
import { TicketStatuses, TicketStatusLabels } from 'views/common/enums/ticket.enum';
import { updatedMemo } from 'views/Tickets/tickets.utils';
import './EndVisitModal.scss';
import { useTicketUpdate } from 'views/common/hooks/useTicketUpdate';
import { useMessages } from 'views/common/hooks/useMessage';

interface InitialModalFormState extends InitialFormState {
  visitDate: ListSelectOption | undefined;
  endHour: ListSelectOption | undefined;
  memo: ListSelectOption | undefined;
}

const initialEndVisitModalState: InitialModalFormState = {
  endHour: {
    key: 'endHour',
    value: new Date()
  },
  visitDate: {
    key: 'visitDate',
    value: new Date()
  },
  memo: {
    key: 'memo',
    value: ''
  }
};

const EndVisitModal: React.FC = () => {
  const { onChange, values, onSetError, isFormInvalid } = useForm<InitialModalFormState>(initialEndVisitModalState);
  const { isModalOpen, onModalClose } = useModal(ModalTypes.END_VISIT);
  const { messages } = useMessages('endVisit');
  const { handleTicketUpdate } = useTicketUpdate(messages);
  const selectedTicket = useSelectSelectedTicket();

  const isEndVisitFormValid = (values: InitialModalFormState): boolean => {
    const optionalValues = ['memo'] as (keyof InitialModalFormState)[];
    return !isFormInvalid(values, optionalValues);
  };

  const updateSelectedTicket = (currentTicket: Ticket, scheduledDate: ScheduledDate, memo: string) => {
    const updatedTicket: Ticket = {
      ...currentTicket,
      scheduledDate,
      ticketStatus: TicketStatuses.VISIT_FINISHED,
      ticketStatusLabel: TicketStatusLabels.POST,
      lastUpdatedDate: new Date().toISOString(),
      memo: updatedMemo(currentTicket, memo)
    };
    handleTicketUpdate(updatedTicket);
  };

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    onModalClose();
    const { endHour, visitDate, memo } = values;
    if (!!endHour && !!visitDate && isEndVisitFormValid(values) && selectedTicket?.scheduledDate) {
      const updatedScheduleDate = {
        ...selectedTicket.scheduledDate,
        startDate: visitDate.value as string,
        endTime: endHour.value as string
      };
      updateSelectedTicket(selectedTicket, updatedScheduleDate, memo?.value as string);
    }
  };

  const updateNoteField = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (!values.memo) return;
    onChange('memo', { key: values.memo?.key, value: e.target.value });
  };

  return (
    <ModalBase isModalOpen={isModalOpen} closeModal={onModalClose} headerText={'endVisit.endVisit'}>
      <form className="end-visit-modal visit-modal modal" onSubmit={(e) => onFormSubmit(e)}>
        <div className="end-visit-modal__pickers">
          <div>
            <DatePickerBase
              disablePast={false}
              handleChange={onChange}
              selectedDate={values.visitDate}
              pickerName={'visitDate'}
              labelText={'startVisit.date'}
              handleError={(error) => onSetError('visitDate', error)}
            />
          </div>
          <div>
            <TimePickerBase
              selectedTime={values.endHour}
              handleChange={onChange}
              pickerName={'endHour'}
              labelText={'endVisit.time'}
              handleError={(error) => onSetError('endHour', error)}
            />
          </div>
        </div>
        <div className="end-visit-modal__memo">
          <TextFieldBase
            multiline
            minRows="3"
            maxRows="5"
            labelText="endVisit.memo"
            value={values.memo?.value}
            onChange={updateNoteField}
          />
        </div>
        <div className="modal__btn-group">
          <ButtonBase onClick={onModalClose} content={'common.cancel'} size={'large'} type={'button'} />
          <ButtonBase
            disabled={!isEndVisitFormValid(values)}
            content={'common.continue'}
            variant={'contained'}
            disableElevation
            size={'large'}
            type={'submit'}
          />
        </div>
      </form>
    </ModalBase>
  );
};

export default EndVisitModal;
