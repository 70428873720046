export const textboxOptions = {
  options: ['inline', 'link', 'image'],
  inline: { options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'] },
  image: {
    alignmentEnabled: false,
    defaultSize: {
      width: 'auto',
      height: 'auto'
    }
  },
  link: { defaultTargetOption: '_blank' }
};
