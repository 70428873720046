import React from 'react';
import './CardBase.scss';
import clsx from 'clsx';

interface CardBaseProps {
  styles?: string;
}

const CardBase: React.FC<CardBaseProps> = ({ styles, children }) => {
  return <div className={clsx('card-base', styles)}>{children}</div>;
};

export default CardBase;
