import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from 'services/data/data-broker';
import { generateTicket } from 'utils/ticket-generator';
import { Ticket, TicketCreationData } from 'types/tickets.type';
import { setSelectedTicket, updateStoreTicket } from 'redux-store/features/tickets/tickets.slice';

export const fetchTickets = createAsyncThunk('tickets/fetchTickets', async () => {
  const api = await Api;
  try {
    const tickets = await api.TICKETS.readAll();
    return tickets;
  } catch (err) {
    console.log('tickets/fetchTickets error: ', err);
  }
});

export const createTicket = createAsyncThunk('tickets/createTicket', async (ticketCreationData: TicketCreationData) => {
  const api = await Api;
  try {
    const newTicket = generateTicket(ticketCreationData);
    const newTicketId = await api.TICKETS.create(newTicket);
    return { ...newTicket, id: newTicketId.toString() };
  } catch (err) {
    console.log('tickets/createTicket error: ', err);
  }
});

export const updateTicket = createAsyncThunk('tickets/updateTicket', async (ticket: Ticket) => {
  const api = await Api;
  try {
    const ticketId = await api.TICKETS.update(ticket);
    return { ...ticket, id: ticketId.toString() };
  } catch (err) {
    console.log('tickets/updateTicket error: ', err);
  }
});

export const updateAndSelectTicket = createAsyncThunk(
  'tickets/updateAndSelectTicket',
  async (ticket: Ticket, thunkApi) => {
    const api = await Api;
    try {
      const ticketId = await api.TICKETS.update(ticket);
      thunkApi.dispatch(updateStoreTicket(ticket));
      thunkApi.dispatch(setSelectedTicket({ id: ticketId.toString() }));
    } catch (err) {
      console.log('tickets/updateAndSelectTicket error: ', err);
    }
  }
);
