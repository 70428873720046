import React, { useEffect, useState } from 'react';
import './../checklist-scenario-modal.scss';
import TextBase from '../../../../components/TextBase/TextBase';
import { CardSelectOption } from '../../../../types/select-option.types';
import SelectCard from '../../../ChecklistScenario/ChecklistStepper/SelectStep/SelectCard';
import { uniqueId } from 'lodash';
import { Answer, AnswerStepProps } from '../utils';

interface SelectCarouselStepProps extends AnswerStepProps {
  options: CardSelectOption[];
}

const SelectCarouselStep: React.FC<SelectCarouselStepProps> = ({
  options,
  message,
  handleChangeStepAnswer,
  id,
  currentValue
}) => {
  const [selectedOption, setSelectedOption] = useState({ key: uniqueId(), value: '' });
  const handleChangeCard = (cardValue: string) => {
    setSelectedOption({ key: uniqueId(), value: cardValue });
    const selectedCard = options.find((option) => {
      return option.value === cardValue;
    });
    handleChangeStepAnswer(id, { question: message as string, answer: selectedCard as Answer });
  };

  useEffect(() => {
    if (currentValue !== undefined) {
      const option = options.find((option) => {
        return option.value === currentValue;
      });
      option && setSelectedOption({ key: option.key, value: option.value as string });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="select-carousel-step">
      <TextBase variant={'h5'}>{message}</TextBase>
      <SelectCard options={options} handleChangeCard={handleChangeCard} selectedOption={selectedOption.value} />
    </div>
  );
};

export default SelectCarouselStep;
