import React from 'react';
import { useLocation } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ButtonBase from 'components/ButtonBase/ButtonBase';
import { TicketTableRow } from 'types/tickets.type';
import { handleIsMenuActionDisabled, MenuItems, TicketActionsMenuItemsTypes } from './tickets.utils';
import { useTicketAction } from 'views/common/hooks/useTicketAction';
import { useAppDispatch } from 'redux-store/hooks';
import { setSelectedTicket } from 'redux-store/features/tickets/tickets.slice';
import { useSelectSelectedTicket } from 'redux-store/features/tickets/tickets.hooks';
import TextBase from 'components/TextBase/TextBase';
import { TicketStatuses } from 'views/common/enums/ticket.enum';

interface TicketActionsMenuProps {
  it: TicketTableRow;
}
export const TicketActionsMenu: React.FC<TicketActionsMenuProps> = ({ it }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { triggerSelectedAction } = useTicketAction();
  const location = useLocation();
  const isMenuOpen = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const ticket = useSelectSelectedTicket();
  const ticketStatus = it.statusDetails[1];
  const disableActions = ticketStatus === TicketStatuses.WAITING_FOR_APPROVAL;

  const handleClose = () => {
    setAnchorEl && setAnchorEl(null);
  };

  const handleSelectedAction = (itemType: TicketActionsMenuItemsTypes) => {
    triggerSelectedAction(itemType);
    handleClose();
  };

  const handleToggleShowActions = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (disableActions) return;
    event.stopPropagation();
    dispatch(setSelectedTicket({ id: it.ticketId.replace('#', '') }));
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <ButtonBase
        variant="text"
        onClick={(e) => handleToggleShowActions(e)}
        content={'table.actions'}
        disabled={disableActions}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {!!ticket &&
          MenuItems.filter((item) => {
            return item.displayRequirements.includes(ticket.ticketStatus);
          }).map((item) => {
            return (
              <MenuItem
                key={item.name}
                onClick={() => handleSelectedAction(item.type)}
                disabled={handleIsMenuActionDisabled(ticket, item.name, location.pathname)}
              >
                <TextBase content={item.name} variant="button" className="color-dark" />
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
};
