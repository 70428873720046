import React from 'react';
import { TableCell, TableRow, TableSortLabel } from '@mui/material';
import { TableDataItem, TableHeaderProps } from 'components/TableBase/table.types';
import { TextContentPath } from 'i18n/i18n-config';
import { OrderType } from 'components/TableBase/table-utils';
import TextBase from 'components/TextBase/TextBase';

const TableHeaderRow = <T extends TableDataItem>({
  tableHeaders,
  order,
  orderBy,
  onRequestSort
}: TableHeaderProps<T>) => {
  const createSortHandler = (property: string, enableSort: boolean | undefined) => (event: React.MouseEvent<unknown>) =>
    enableSort && onRequestSort(event, property);
  return (
    <TableRow>
      {tableHeaders.map((tableHeader) => (
        <TableCell className="custom-width" key={tableHeader.id as string} sortDirection={OrderType.ASCENDING}>
          <TableSortLabel
            hideSortIcon={!tableHeader.enableSort}
            active={orderBy === tableHeader.id}
            direction={orderBy === tableHeader.id ? order : OrderType.ASCENDING}
            onClick={createSortHandler(tableHeader.id, tableHeader.enableSort)}
          >
            <TextBase content={tableHeader.column as TextContentPath} variant={'caption'} />
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default TableHeaderRow;
