import React from 'react';
import './services-assignment-modal.scss';
import { Ticket } from 'types/tickets.type';
import PatientInfo from './PatientInfo';
import SelectCareGrade from './SelectCareGrade';
import Services from './Services';
import { Divider } from '@mui/material';
import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import { uniqueId } from 'lodash';
import { CareGrade } from 'views/Tickets/ServicesAssignmentModal/useServicesAssign';
import { AssignedService } from 'types/service.types';

interface InfoWrapperProps {
  ticket: Ticket | undefined;
  selectedCareGrade: CareGrade | undefined;
  handleSetCareGrade: ({ key, value }: CareGrade) => void;
  handleAddNewService: () => void;
  handleUpdateService: (updatedService: AssignedService, serviceIndex: number) => void;
  services: AssignedService[];
  handleRemoveService: (serviceIndex: number) => void;
}

const InfoWrapper: React.FC<InfoWrapperProps> = ({
  ticket,
  selectedCareGrade,
  handleSetCareGrade,
  handleUpdateService,
  handleAddNewService,
  services,
  handleRemoveService
}) => {
  return (
    <div className="info-wrapper">
      <PatientInfo ticket={ticket} />
      <Divider />
      <SelectCareGrade selectedCareGrade={selectedCareGrade} handleSetCareGrade={handleSetCareGrade} />
      <Divider />
      {services.map((service, serviceIndex) => {
        return (
          <div key={uniqueId()}>
            <Services
              handleUpdateService={handleUpdateService}
              assignedService={service}
              serviceIndex={serviceIndex}
              handleRemoveService={handleRemoveService}
            />{' '}
            <Divider />
          </div>
        );
      })}
      <ButtonBase
        content={'ticket.assignServicesModal.addAnotherService'}
        className="add-service-button"
        onClick={() => handleAddNewService()}
      />
    </div>
  );
};

export default InfoWrapper;
