import { Ticket } from 'types/tickets.type';
import { SupportGrades, NursingGrades } from 'views/common/enums/care-grade.enum';
import { TicketTypes, TicketStatuses, TicketStatusLabels } from 'views/common/enums/ticket.enum';
import { uniqueId } from 'lodash';
import { defaultCareCoordinatorsData } from 'services/data/indexedDB/mock/raw-data/care-coordinators';
import { defaultPatientsData } from 'services/data/indexedDB/mock/raw-data/patients';
import { defaultScenariosData } from 'services/data/indexedDB/mock/raw-data/scenarios';
import { defaultServiceProvidersData } from 'services/data/indexedDB/mock/raw-data/service-providers';
import { generateFakeIds } from 'services/data/indexedDB/mock/helpers/generateFakeIds';
import { ServiceTypes } from 'services/data/indexedDB/mock/raw-data/serivces';

const defaultCareCoordinatorsDataWithId = generateFakeIds(defaultCareCoordinatorsData);
const defaultPatientsDataWithId = generateFakeIds(defaultPatientsData);
const defaultScenariosDataWithId = generateFakeIds(defaultScenariosData);
const defaultServiceProvidersDataWithId = generateFakeIds(defaultServiceProvidersData);

export const defaultTicketsData: Ticket[] = [
  {
    id: '000000001',
    ticketType: TicketTypes.VISITING,
    ticketStatus: TicketStatuses.OPERATOR_ASSIGNED,
    ticketStatusLabel: TicketStatusLabels.PRE,
    ticketOwnerManager: 'Manager Manager',
    scheduledDate: undefined,
    timebox: '',
    careGrade: undefined,
    services: [],
    history: [],
    memo: [],
    patientAssigned: defaultPatientsDataWithId[0],
    ticketOwner: defaultCareCoordinatorsDataWithId[0],
    checklistScenario: defaultScenariosDataWithId[0],
    creationDate: new Date().toISOString(),
    lastUpdatedDate: new Date().toISOString(),
    checklistAnswers: undefined
  },
  {
    id: '000000002',
    ticketType: TicketTypes.VISITING,
    ticketStatus: TicketStatuses.VISIT_SCHEDULED,
    ticketStatusLabel: TicketStatusLabels.PRE,
    ticketOwnerManager: 'Sasaki Kaori',
    scheduledDate: {
      startDate: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      startTime: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      endTime: new Date('2022-05-30T12:00:00.000Z').toISOString()
    },
    timebox: '1h',
    careGrade: undefined,
    services: [],
    history: [],
    memo: [
      {
        id: '0',
        memo: 'On the way to the patient',
        createdBy: defaultCareCoordinatorsDataWithId[0],
        createdDate: new Date().toISOString()
      }
    ],
    patientAssigned: defaultPatientsDataWithId[0],
    ticketOwner: defaultCareCoordinatorsDataWithId[0],
    checklistScenario: defaultScenariosDataWithId[0],
    creationDate: new Date().toISOString(),
    lastUpdatedDate: new Date().toISOString(),
    checklistAnswers: undefined
  },
  {
    id: '000000003',
    ticketType: TicketTypes.VISITING,
    ticketStatus: TicketStatuses.VISIT_IN_PROGRESS,
    ticketStatusLabel: TicketStatusLabels.ON,
    ticketOwnerManager: 'Sasaki Kaori',
    scheduledDate: {
      startDate: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      startTime: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      endTime: new Date('2022-05-30T12:00:00.000Z').toISOString()
    },
    timebox: '1h',
    careGrade: undefined,
    services: [],
    history: [],
    memo: [
      {
        id: '0',
        memo: 'On the way to the patient',
        createdBy: defaultCareCoordinatorsDataWithId[0],
        createdDate: new Date().toISOString()
      }
    ],
    patientAssigned: defaultPatientsDataWithId[0],
    ticketOwner: defaultCareCoordinatorsDataWithId[0],
    checklistScenario: defaultScenariosDataWithId[0],
    creationDate: new Date().toISOString(),
    lastUpdatedDate: new Date().toISOString(),
    checklistAnswers: undefined
  },
  {
    id: '000000004',
    ticketType: TicketTypes.VISITING,
    ticketStatus: TicketStatuses.CLOSED,
    ticketStatusLabel: TicketStatusLabels.POST,
    ticketOwnerManager: 'Sasaki Kaori',
    scheduledDate: {
      startDate: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      startTime: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      endTime: new Date('2022-05-30T12:00:00.000Z').toISOString()
    },
    timebox: '1h',
    careGrade: SupportGrades.NSG1,
    services: [
      {
        id: 0,
        code: uniqueId(),
        serviceType: ServiceTypes.NURSING,
        name: 'service name 0',
        serviceProvider: defaultServiceProvidersDataWithId[1]
      }
    ],
    history: [],
    memo: [
      {
        id: '0',
        memo: 'On the way to the patient',
        createdBy: defaultCareCoordinatorsDataWithId[0],
        createdDate: new Date().toISOString()
      },
      {
        id: '1',
        memo: 'Finished visiting',
        createdBy: defaultCareCoordinatorsDataWithId[0],
        createdDate: new Date().toISOString()
      }
    ],
    patientAssigned: defaultPatientsDataWithId[0],
    ticketOwner: defaultCareCoordinatorsDataWithId[0],
    checklistScenario: defaultScenariosDataWithId[0],
    creationDate: new Date().toISOString(),
    lastUpdatedDate: new Date().toISOString(),
    checklistAnswers: undefined
  },
  {
    id: '000000005',
    ticketType: TicketTypes.VISITING,
    ticketStatus: TicketStatuses.NEED_REVISIT,
    ticketStatusLabel: TicketStatusLabels.PRE,
    ticketOwnerManager: 'Robert Lewandowski',
    scheduledDate: {
      startDate: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      startTime: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      endTime: new Date('2022-05-30T12:00:00.000Z').toISOString()
    },
    timebox: '1h',
    careGrade: undefined,
    services: [],
    history: [],
    memo: [
      {
        id: '0',
        memo: 'On the way to the patient',
        createdBy: defaultCareCoordinatorsDataWithId[0],
        createdDate: new Date().toISOString()
      }
    ],
    patientAssigned: defaultPatientsDataWithId[0],
    ticketOwner: defaultCareCoordinatorsDataWithId[0],
    checklistScenario: defaultScenariosDataWithId[0],
    creationDate: new Date().toISOString(),
    lastUpdatedDate: new Date().toISOString(),
    checklistAnswers: undefined
  },
  {
    id: '000000006',
    ticketType: TicketTypes.VISITING,
    ticketStatus: TicketStatuses.WAITING_FOR_APPROVAL,
    ticketStatusLabel: TicketStatusLabels.POST,
    ticketOwnerManager: 'Maryla Rodowicz',
    scheduledDate: {
      startDate: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      startTime: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      endTime: new Date('2022-05-30T12:00:00.000Z').toISOString()
    },
    timebox: '15min',
    careGrade: NursingGrades.NNCG2,
    services: [
      {
        id: 2,
        code: uniqueId(),
        serviceType: ServiceTypes.MEDICAL,
        name: 'service name 2',
        serviceProvider: defaultServiceProvidersDataWithId[0]
      },
      {
        id: 3,
        code: uniqueId(),
        serviceType: ServiceTypes.MEDICAL,
        name: 'service name 3',
        serviceProvider: defaultServiceProvidersDataWithId[0]
      }
    ],
    history: [],
    memo: [
      {
        id: '0',
        memo: 'Completed assignments',
        createdBy: defaultCareCoordinatorsDataWithId[0],
        createdDate: new Date().toISOString()
      }
    ],
    patientAssigned: defaultPatientsDataWithId[0],
    ticketOwner: defaultCareCoordinatorsDataWithId[0],
    checklistScenario: defaultScenariosDataWithId[0],
    creationDate: new Date().toISOString(),
    lastUpdatedDate: new Date().toISOString(),
    checklistAnswers: undefined
  },
  {
    id: '000000007',
    ticketType: TicketTypes.VISITING,
    ticketStatus: TicketStatuses.WAITING_FOR_APPROVAL,
    ticketStatusLabel: TicketStatusLabels.POST,
    ticketOwnerManager: 'Robert Lewandowski',
    scheduledDate: {
      startDate: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      startTime: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      endTime: new Date('2022-05-30T12:00:00.000Z').toISOString()
    },
    timebox: '15min',
    careGrade: NursingGrades.NNCG2,
    services: [
      {
        id: 4,
        code: uniqueId(),
        serviceType: ServiceTypes.NURSING,
        name: 'service name 4',
        serviceProvider: defaultServiceProvidersDataWithId[2]
      },
      {
        id: 5,
        code: uniqueId(),
        serviceType: ServiceTypes.NURSING,
        name: 'service name 5',
        serviceProvider: defaultServiceProvidersDataWithId[2]
      }
    ],
    history: [],
    memo: [
      {
        id: '0',
        memo: 'Added assignments',
        createdBy: defaultCareCoordinatorsDataWithId[1],
        createdDate: new Date().toISOString()
      }
    ],
    patientAssigned: defaultPatientsDataWithId[1],
    ticketOwner: defaultCareCoordinatorsDataWithId[1],
    checklistScenario: defaultScenariosDataWithId[1],
    creationDate: new Date().toISOString(),
    lastUpdatedDate: new Date('2022-03-23T12:00:00.000Z').toISOString(),
    checklistAnswers: undefined
  },
  {
    id: '000000008',
    ticketType: TicketTypes.VISITING,
    ticketStatus: TicketStatuses.WAITING_FOR_APPROVAL,
    ticketStatusLabel: TicketStatusLabels.POST,
    ticketOwnerManager: 'Maryla Rodowicz',
    scheduledDate: {
      startDate: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      startTime: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      endTime: new Date('2022-05-30T12:00:00.000Z').toISOString()
    },
    timebox: '30min',
    careGrade: NursingGrades.NNCG3,
    services: [
      {
        id: 0,
        code: uniqueId(),
        serviceType: ServiceTypes.NURSING,
        name: 'service name 0',
        serviceProvider: defaultServiceProvidersDataWithId[0]
      },
      {
        id: 1,
        code: uniqueId(),
        serviceType: ServiceTypes.NURSING,
        name: 'service name 1',
        serviceProvider: defaultServiceProvidersDataWithId[0]
      }
    ],
    history: [],
    memo: [
      {
        id: '0',
        memo: 'Assignments waiting for approval',
        createdBy: defaultCareCoordinatorsDataWithId[0],
        createdDate: new Date().toISOString()
      }
    ],
    patientAssigned: defaultPatientsDataWithId[0],
    ticketOwner: defaultCareCoordinatorsDataWithId[0],
    checklistScenario: defaultScenariosDataWithId[0],
    creationDate: new Date().toISOString(),
    lastUpdatedDate: new Date('2022-01-23T12:00:00.000Z').toISOString(),
    checklistAnswers: undefined
  },
  {
    id: '000000009',
    ticketType: TicketTypes.VISITING,
    ticketStatus: TicketStatuses.WAITING_FOR_APPROVAL,
    ticketStatusLabel: TicketStatusLabels.POST,
    ticketOwnerManager: 'Maryla Rodowicz',
    scheduledDate: {
      startDate: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      startTime: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      endTime: new Date('2022-05-30T12:00:00.000Z').toISOString()
    },
    timebox: '30min',
    careGrade: NursingGrades.NNCG3,
    services: [
      {
        id: 2,
        code: uniqueId(),
        serviceType: ServiceTypes.MEDICAL,
        name: 'service name 2',
        serviceProvider: undefined
      },
      {
        id: 3,
        code: uniqueId(),
        serviceType: ServiceTypes.MEDICAL,
        name: 'service name 3',
        serviceProvider: undefined
      }
    ],
    history: [],
    memo: [
      {
        id: '0',
        memo: 'Assignments waiting for approval',
        createdBy: defaultCareCoordinatorsDataWithId[0],
        createdDate: new Date().toISOString()
      }
    ],
    patientAssigned: defaultPatientsDataWithId[0],
    ticketOwner: defaultCareCoordinatorsDataWithId[0],
    checklistScenario: defaultScenariosDataWithId[0],
    creationDate: new Date().toISOString(),
    lastUpdatedDate: new Date('2022-06-01T12:00:00.000Z').toISOString(),
    checklistAnswers: undefined
  },
  {
    id: '000000010',
    ticketType: TicketTypes.VISITING,
    ticketStatus: TicketStatuses.WAITING_FOR_APPROVAL,
    ticketStatusLabel: TicketStatusLabels.POST,
    ticketOwnerManager: 'Maryla Rodowicz',
    scheduledDate: {
      startDate: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      startTime: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      endTime: new Date('2022-05-30T12:00:00.000Z').toISOString()
    },
    timebox: '30min',
    careGrade: NursingGrades.NNCG3,
    services: [
      {
        id: 0,
        code: uniqueId(),
        serviceType: ServiceTypes.NURSING,
        name: 'service name 0',
        serviceProvider: undefined
      },
      {
        id: 3,
        code: uniqueId(),
        serviceType: ServiceTypes.MEDICAL,
        name: 'service name 3',
        serviceProvider: undefined
      }
    ],
    history: [],
    memo: [
      {
        id: '0',
        memo: 'Assignments waiting for approval',
        createdBy: defaultCareCoordinatorsDataWithId[2],
        createdDate: new Date().toISOString()
      }
    ],
    patientAssigned: defaultPatientsDataWithId[0],
    ticketOwner: defaultCareCoordinatorsDataWithId[2],
    checklistScenario: defaultScenariosDataWithId[0],
    creationDate: new Date().toISOString(),
    lastUpdatedDate: new Date('2021-12-10T12:00:00.000Z').toISOString(),
    checklistAnswers: undefined
  },
  {
    id: '000000011',
    ticketType: TicketTypes.VISITING,
    ticketStatus: TicketStatuses.WAITING_FOR_APPROVAL,
    ticketStatusLabel: TicketStatusLabels.POST,
    ticketOwnerManager: 'Maryla Rodowicz',
    scheduledDate: {
      startDate: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      startTime: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      endTime: new Date('2022-05-30T12:00:00.000Z').toISOString()
    },
    timebox: '30min',
    careGrade: NursingGrades.NNCG3,
    services: [
      {
        id: 1,
        code: uniqueId(),
        serviceType: ServiceTypes.NURSING,
        name: 'service name 1',
        serviceProvider: undefined
      },
      {
        id: 3,
        code: uniqueId(),
        serviceType: ServiceTypes.MEDICAL,
        name: 'service name 3',
        serviceProvider: undefined
      }
    ],
    history: [],
    memo: [
      {
        id: '0',
        memo: 'Assignments waiting for approval',
        createdBy: defaultCareCoordinatorsDataWithId[3],
        createdDate: new Date().toISOString()
      }
    ],
    patientAssigned: defaultPatientsDataWithId[0],
    ticketOwner: defaultCareCoordinatorsDataWithId[3],
    checklistScenario: defaultScenariosDataWithId[0],
    creationDate: new Date().toISOString(),
    lastUpdatedDate: new Date('2022-02-08T12:00:00.000Z').toISOString(),
    checklistAnswers: undefined
  },
  {
    id: '000000012',
    ticketType: TicketTypes.VISITING,
    ticketStatus: TicketStatuses.WAITING_FOR_APPROVAL,
    ticketStatusLabel: TicketStatusLabels.POST,
    ticketOwnerManager: 'Maryla Rodowicz',
    scheduledDate: {
      startDate: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      startTime: new Date('2022-05-30T11:00:00.000Z').toISOString(),
      endTime: new Date('2022-05-30T12:00:00.000Z').toISOString()
    },
    timebox: '30min',
    careGrade: NursingGrades.NNCG3,
    services: [
      {
        id: 2,
        code: uniqueId(),
        serviceType: ServiceTypes.MEDICAL,
        name: 'service name 2',
        serviceProvider: undefined
      },
      {
        id: 3,
        code: uniqueId(),
        serviceType: ServiceTypes.MEDICAL,
        name: 'service name 3',
        serviceProvider: undefined
      }
    ],
    history: [],
    memo: [
      {
        id: '0',
        memo: 'Assignments waiting for approval',
        createdBy: defaultCareCoordinatorsDataWithId[2],
        createdDate: new Date().toISOString()
      }
    ],
    patientAssigned: defaultPatientsDataWithId[0],
    ticketOwner: defaultCareCoordinatorsDataWithId[2],
    checklistScenario: defaultScenariosDataWithId[0],
    creationDate: new Date().toISOString(),
    lastUpdatedDate: new Date('2022-04-20T12:00:00.000Z').toISOString(),
    checklistAnswers: undefined
  }
];
