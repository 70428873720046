import React from 'react';
import './VisitsReports.scss';
import { Ticket } from '../../../../types/tickets.type';
import TextBase from '../../../../components/TextBase/TextBase';
import { TicketStatuses } from '../../../common/enums/ticket.enum';
import VisitsCard from './VisitsCard/VisitsCard';

interface VisitsReportsProps {
  currentTicket: Ticket;
}

const VisitsReports: React.FC<VisitsReportsProps> = ({ currentTicket }) => {
  return (
    <div className="reports">
      <TextBase content={'ticket.details.visitAndReports'} variant="h6" className="reports-title" />
      {currentTicket.ticketStatus === TicketStatuses.OPERATOR_ASSIGNED ? (
        <TextBase content={'ticket.details.noReportsVisits'} variant="caption" className="color-dark-disabled" />
      ) : (
        <VisitsCard currentTicket={currentTicket} />
      )}
    </div>
  );
};

export default VisitsReports;
