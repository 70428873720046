import { ToStoreObject } from 'services/data/types';
import { ServiceProvider } from 'types/tickets.type';

export const defaultServiceProvidersData: ToStoreObject<ServiceProvider>[] = [
  {
    clinicId: 1,
    clinicName: 'clinic 1 name',
    clinicDetails: 'clinic 1 details',
    clinicAddress: 'clinic 1 address'
  },
  {
    clinicId: 2,
    clinicName: 'clinic 2 name',
    clinicDetails: 'clinic 2 details',
    clinicAddress: 'clinic 2 address'
  },
  {
    clinicId: 3,
    clinicName: 'clinic 3 name',
    clinicDetails: 'clinic 3 details',
    clinicAddress: 'clinic 3 address'
  }
];
