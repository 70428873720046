import { IDBPDatabase, openDB } from 'idb';
import { API, DataPaths } from 'services/data/types';
import {
  defaultScenariosData,
  defaultPatientsData,
  defaultTicketsData,
  defaultServicesData,
  defaultCareCoordinatorsData,
  defaultServiceProvidersData
} from './mock';

export interface InitDB {
  clearData?: boolean;
  insertInitial?: boolean;
}

type InitData = { [key in DataPaths]: any[] };

const initData: InitData = {
  SCENARIOS: defaultScenariosData,
  PATIENTS: defaultPatientsData,
  TICKETS: defaultTicketsData,
  SERVICES: defaultServicesData,
  CARE_COORDINATORS: defaultCareCoordinatorsData,
  SERVICE_PROVIDERS: defaultServiceProvidersData
};

const initDB = () =>
  openDB('next-tomorrow', undefined, {
    upgrade(db) {
      for (let store of Object.values(DataPaths)) {
        if (!db.objectStoreNames.contains(store)) {
          db.createObjectStore(store, { keyPath: 'id' });
        }
      }
    }
  });

const initDefaultData = async (db: IDBPDatabase<unknown>, api: API) => {
  const currentDB = await openDB('next-tomorrow');
  for (let store of Object.values(DataPaths)) {
    //TODO: Next 2 lines is current solution. They check if indexedDB is already initialized and has data
    const storeDataLength = await currentDB.count(store);
    if (storeDataLength === 0) {
      for (const item of initData[store]) {
        await api[store].create(item);
      }
    }
  }
};

export const createIndexedApi = async () => {
  const db = await initDB();
  const api = Object.values(DataPaths).reduce((acc, store) => {
    acc[store] = {
      create: async (item) => {
        const tx = db.transaction(store, 'readwrite');
        const keys = await db.getAllKeys(store);
        const id = keys.length > 0 ? Math.max(...(keys as number[])) + 1 : 1;
        const res = await db.add(store, { ...item, id: id });
        await tx.done;
        return res;
      },
      read: async (itemId) => {
        const tx = db.transaction(store, 'readonly');
        const res = await db.get(store, Number(itemId));
        res.id = res.id.toString();
        await tx.done;
        return res;
      },
      update: async (item) => {
        const tx = db.transaction(store, 'readwrite');
        const toUpdate = { ...item, id: Number(item.id) };
        const res = await db.put(store, JSON.parse(JSON.stringify(toUpdate)));
        await tx.done;
        return res;
      },
      delete: async (itemId) => {
        const tx = db.transaction(store, 'readwrite');
        await db.delete(store, Number(itemId));
        await tx.done;
      },
      readAll: async () => {
        const tx = db.transaction(store, 'readonly');
        const res = (await db.getAll(store)).map((item) => ({ ...item, id: item.id.toString() }));
        await tx.done;
        return res;
      }
    };
    return acc;
  }, {} as API);
  await initDefaultData(db, api);
  return api;
};
