import { isIndexedDbUsed } from 'context-store/utils';
import { createApi } from 'services/data/backend/backend.service';
import { createIndexedApi } from 'services/data/indexedDB/idexeddb.service';
import { API } from 'services/data/types';

let initialized = false;
let api: API | undefined;

const initializeApi = (createdApi: API) => {
  api = createdApi;
  initialized = true;
  return api;
};

export const createDataApi = async (): Promise<API> => {
  if (!initialized) {
    try {
      if (isIndexedDbUsed()) {
        return initializeApi(await createIndexedApi());
      } else {
        return initializeApi(await createApi());
      }
    } catch (err) {
      throw new Error(`API Creation issue: ${err}`);
    }
  }
  return api as API;
};

const Api = createDataApi();
export default Api;
