import React from 'react';
import { List } from '@mui/material';
import { MenuItem } from '../../utils/menu-utils';
import { useListItemOpenState } from './useListItemOpenedState';
import ListItemBase from './ListItemBase/ListItemBase';

interface ListProps {
  menuItems: MenuItem[];
}

const ListBase: React.FC<ListProps> = ({ menuItems }) => {
  const [checkItemState, toggleItem] = useListItemOpenState();

  return (
    <List>
      {menuItems.map((item) => (
        <React.Fragment key={item.title}>
          <ListItemBase menuItem={item} toggleItem={toggleItem} checkItemState={checkItemState} />
        </React.Fragment>
      ))}
    </List>
  );
};

export default ListBase;
