import React from 'react';
import ModalBase from 'components/ModalBase/ModalBase';
import ButtonBase from 'components/ButtonBase/ButtonBase';
import TextBase from 'components/TextBase/TextBase';
import { useModal } from 'views/common/hooks/useModal';
import { ModalTypes } from 'views/common/enums/modal-types.enum';
import './ApproveReportModal.scss';
import { useTicketUpdate } from 'views/common/hooks/useTicketUpdate';
import { useSelectSelectedTicket } from 'redux-store/features/tickets/tickets.hooks';
import { Ticket } from 'types/tickets.type';
import { TicketStatuses } from 'views/common/enums/ticket.enum';
import CheckIcon from 'assets/styled-icons/check/CheckIcon';
import { useMessages } from 'views/common/hooks/useMessage';

const closedTicket = (ticket: Ticket): Ticket => {
  return {
    ...ticket,
    ticketStatus: TicketStatuses.CLOSED,
    lastUpdatedDate: new Date().toISOString()
  };
};

const ApproveReportModal: React.FC = () => {
  const { isModalOpen, onModalClose } = useModal(ModalTypes.APPROVE_SERVICES);
  const { messages } = useMessages('approveReport');
  const selectedTicket = useSelectSelectedTicket();
  const { handleTicketUpdate } = useTicketUpdate(messages);

  const onReportApprove = () => {
    onModalClose();
    if (!selectedTicket) return;
    handleTicketUpdate(closedTicket(selectedTicket));
  };

  return (
    <ModalBase isModalOpen={isModalOpen} closeModal={onModalClose} customHeader={true}>
      <div className="approve-report-modal modal">
        <CheckIcon />
        <TextBase variant={'h4'} content={'inbox.approveReportTitle'} className="color-dark" />
        <TextBase variant={'subtitle1'} content={'inbox.approveReportInfo'} className="color-dark-secondary" />
        <div className="modal__btn-group column-align">
          <ButtonBase
            content={'common.confirm'}
            size={'large'}
            type={'button'}
            variant={'contained'}
            onClick={onReportApprove}
          />
          <ButtonBase
            content={'common.cancel'}
            disableElevation
            size={'large'}
            type={'button'}
            onClick={onModalClose}
          />
        </div>
      </div>
    </ModalBase>
  );
};

export default ApproveReportModal;
