import { useState } from 'react';

export const useDrawer = (initialState = false) => {
  const [isDrawerOpen, toggleDrawer] = useState(initialState);

  const onDrawerToggle = (drawerState: boolean) => {
    toggleDrawer(drawerState);
  };

  return { onDrawerToggle, isDrawerOpen };
};
