import React from 'react';
import TextBase from 'components/TextBase/TextBase';
import { TextContentPath } from 'i18n/i18n-config';

interface ErrorMessageProps {
  errorMessage?: TextContentPath;
  errorFreeText?: string;
  ticketId?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorMessage, errorFreeText, ticketId }) => {
  return (
    <>
      <TextBase content={errorMessage} variant="subtitle2">
        {errorFreeText}
      </TextBase>
      {!!ticketId && <TextBase variant="subtitle2">{`#${ticketId}`}</TextBase>}
    </>
  );
};

export default ErrorMessage;
