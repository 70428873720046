import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Collapse, ListItemButton } from '@mui/material';
import './ListItemBase.scss';
import ListBase from '../ListBase';
import { MenuItem } from 'utils/menu-utils';
import { MemoizedIconComponent } from 'components/Icon/Icon';
import ListItemLabel from './ListItemLabel/ListItemLabel';
import BadgeBase from 'components/BadgeBase/BadgeBase';
import { AppPaths } from 'utils/app-routing';
import { useSelectWaitingForApprovalTicketsAmount } from 'redux-store/features/tickets/tickets.hooks';
import { useAppDispatch } from 'redux-store/hooks';
import { fetchTickets } from 'redux-store/features/tickets/tickets.actions';

interface ListItemProps {
  menuItem: MenuItem;
  toggleItem: (id: string) => void;
  checkItemState: (id: string) => boolean;
}

const isSelectedTab = (currentPath: string, pathName: string): boolean => pathName.slice(1) === currentPath;

const showInboxItemsAmount = (currentPath: string): boolean => currentPath === AppPaths.INBOX;

const ListItemBase: React.FC<ListItemProps> = ({ menuItem, toggleItem, checkItemState }) => {
  const toggleListItem = (item: MenuItem, e: React.SyntheticEvent): void => {
    if (!!item.children?.length || !item.isEnabled) e.preventDefault();
    toggleItem(item.title);
  };
  const location = useLocation();
  const inboxItemsAmount = useSelectWaitingForApprovalTicketsAmount();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchTickets());
    if (location.pathname.includes(menuItem.path)) toggleItem(menuItem.title);
  }, [menuItem]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment key={menuItem.path}>
      <ListItemButton
        component={Link}
        to={menuItem.path}
        className={'list-item-button'}
        selected={isSelectedTab(menuItem.path, location.pathname)}
        onClick={(e: React.SyntheticEvent) => toggleListItem(menuItem, e)}
      >
        <ListItemLabel menuItem={menuItem} isSelected={isSelectedTab(menuItem.path, location.pathname)} />
        {showInboxItemsAmount(menuItem.path) && <BadgeBase badgeContent={inboxItemsAmount} />}
        {!!menuItem.children?.length && (
          <MemoizedIconComponent name={checkItemState(menuItem.title) ? 'ChevronUp' : 'ChevronDown'} />
        )}
      </ListItemButton>
      {!!menuItem.children?.length && (
        <Collapse in={checkItemState(menuItem.title)} timeout="auto" unmountOnExit>
          <ListBase menuItems={menuItem.children} />
        </Collapse>
      )}
    </React.Fragment>
  );
};

export default ListItemBase;
