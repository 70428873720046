import React, { useEffect, useState } from 'react';
import { Box, Paper, Step, Stepper } from '@mui/material';
import ReactPDF from '@react-pdf/renderer';
import { MemoizedStepComponent } from './StepComponent';
import ButtonBase from 'components/ButtonBase/ButtonBase';
import TextBase from 'components/TextBase/TextBase';
import { Node } from 'types/scenario-management.types';
import { getReportData, SummaryData } from '../utils';
import PDFDocument from '../PDFDocument/PDFDocument';
import { ShapesNames } from 'shapes/utils';
import { useSelectSelectedScenario } from 'redux-store/features/scenarios/scenarios.hooks';

const ChecklistStepper = () => {
  const scenario = useSelectSelectedScenario();
  const [steps, setSteps] = useState<Node<ShapesNames>[]>([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [summaryData, setSummaryData] = useState<SummaryData[]>([]);

  useEffect(() => {
    if (scenario?.checklistNodes) {
      setSteps(scenario?.checklistNodes);
    }
  }, [scenario?.checklistNodes]);

  const handleNext = (userAnswers: SummaryData) => {
    const answers = [...summaryData];
    answers[activeStep] = userAnswers;
    setSummaryData(answers);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setSummaryData([]);
  };

  const handlePreviewClick = () => {
    openReportOnNewUrl();
  };

  const openReportOnNewUrl = async () => {
    const nodes = getReportData(summaryData);
    const blob = await ReactPDF.pdf(<PDFDocument nodes={nodes} />).toBlob();
    const url = URL.createObjectURL(blob);
    if (url && url.length > 0) {
      window.open(url);
    }
  };

  return (
    <div className="stepper">
      <Box sx={{ maxWidth: 400, mt: 3 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={`step-${index}`}>
              <MemoizedStepComponent
                handleBack={handleBack}
                handleNext={handleNext}
                isFirstStep={index === 0}
                stepData={step}
                isLastStep={index === steps.length - 1}
                userAnswers={summaryData[index]?.answers ?? ['']}
              />
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <TextBase content={'checklistScenario.clickToSeePreview'} />
            <ButtonBase sx={{ mt: 1, mr: 1 }} content={'checklistScenario.preview'} onClick={handlePreviewClick} />
            <ButtonBase onClick={handleReset} sx={{ mt: 1, mr: 1 }} content={'checklistScenario.reset'} />
          </Paper>
        )}
      </Box>
    </div>
  );
};

export default ChecklistStepper;
