import React from 'react';
import './services-assignment-modal.scss';
import TextBase from '../../../components/TextBase/TextBase';
import SelectBase from '../../../components/SelectBase/SelectBase';
import { useTranslation } from 'react-i18next';
import { MemoizedIconComponent } from '../../../components/Icon/Icon';
import { AssignedService } from 'types/service.types';
import { useServices } from 'redux-store/features/services/services.hooks';
import { useServiceProviders } from 'redux-store/features/service-providers/service-providers.hooks';

interface ServicesProps {
  handleUpdateService: (updatedService: AssignedService, serviceIndex: number) => void;
  assignedService: AssignedService;
  serviceIndex: number;
  handleRemoveService: (serviceIndex: number) => void;
}

const useServicesOptions = () => {
  const servicesList = useServices();
  const servicesOptions = servicesList.map((item) => ({ key: item.id.toString(), value: item.name }));
  return { servicesList, servicesOptions };
};

const useServiceProvidersOptions = () => {
  const serviceProvidersList = useServiceProviders();
  const serviceProvidersOptions = serviceProvidersList.map((item) => {
    return { key: item.clinicId.toString(), value: item.clinicName };
  });
  return { serviceProvidersList, serviceProvidersOptions };
};

const Services: React.FC<ServicesProps> = ({
  assignedService,
  handleUpdateService,
  serviceIndex,
  handleRemoveService
}) => {
  const { t } = useTranslation();
  const { servicesList, servicesOptions } = useServicesOptions();
  const { serviceProvidersList, serviceProvidersOptions } = useServiceProvidersOptions();

  const handleChangeService = (newOption: string) => {
    const selectedService = servicesList.find((service) => service.name === newOption);
    selectedService &&
      handleUpdateService(
        {
          ...selectedService,
          serviceProvider: assignedService.serviceProvider
        },
        serviceIndex
      );
  };

  const handleChangeServiceProvider = (newOption: string) => {
    const selectedServiceProvider = serviceProvidersList.find(
      (serviceProvider) => serviceProvider.clinicName === newOption
    );
    selectedServiceProvider &&
      handleUpdateService(
        {
          ...assignedService,
          serviceProvider: selectedServiceProvider
        },
        serviceIndex
      );
  };
  return (
    <div className="service-item">
      <div className="service-item__header">
        <TextBase variant={'h6'}>{`${t('ticket.assignServicesModal.selectService')} ${serviceIndex + 1}`}</TextBase>
        <MemoizedIconComponent name={'Trash'} onClick={() => handleRemoveService(serviceIndex)} />
      </div>
      <div className="select-service">
        <TextBase variant={'body1'} content={'ticket.assignServicesModal.service'} />
        <SelectBase
          options={servicesOptions}
          selectedOption={{ key: assignedService.id.toString(), value: assignedService.name }}
          placeholder={t('ticket.assignServicesModal.placeholderService')}
          onChange={(e) => handleChangeService(e.target.value as string)}
        />
        <TextBase variant={'body1'} content={'ticket.assignServicesModal.serviceProvider'} />
        <SelectBase
          options={serviceProvidersOptions}
          selectedOption={{
            key: assignedService.serviceProvider?.clinicId.toString() as string,
            value: assignedService.serviceProvider?.clinicName as string
          }}
          placeholder={t('ticket.assignServicesModal.placeholderServiceProvider')}
          onChange={(e) => handleChangeServiceProvider(e.target.value as string)}
        />
      </div>
    </div>
  );
};

export default Services;
