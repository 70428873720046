import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { uniqueId } from 'lodash';
import DateAdapter from './DateAdapter';
import { TextContentPath } from '../../i18n/i18n-config';
import LabelBase from '../LabelBase/LabelBase';
import { ListSelectOption } from '../../types/select-option.types';
import { InitialFormState } from '../../views/common/hooks/useForm';
import { MemoizedIconComponent } from '../Icon/Icon';
import { DateValidationError } from '@mui/x-date-pickers/internals';

interface DatePickerBaseProps<T extends InitialFormState> {
  selectedDate: ListSelectOption | undefined;
  handleChange: (eventTargetName: keyof T, selectedValue: ListSelectOption | undefined) => void;
  handleError?: (reason: DateValidationError | undefined) => void;
  pickerName?: string;
  labelText?: TextContentPath;
  fullWidth?: boolean;
  disablePast?: boolean;
  required?: boolean;
}

const DatePickerBase: React.FC<DatePickerBaseProps<InitialFormState>> = ({
  selectedDate,
  handleChange,
  handleError,
  pickerName,
  labelText,
  fullWidth = true,
  disablePast = true,
  required = true
}) => {
  return (
    <DateAdapter>
      <LabelBase label={labelText} />
      <DatePicker
        onError={(reason) => handleError && handleError(reason ?? undefined)}
        inputFormat={'dd/MM/yyyy'}
        views={['day']}
        value={selectedDate?.value ?? null}
        disablePast={disablePast}
        onChange={(value: Date | null) =>
          handleChange(pickerName as keyof InitialFormState, {
            key: selectedDate?.key ?? uniqueId(),
            value: value ?? undefined
          })
        }
        components={{ OpenPickerIcon: () => <MemoizedIconComponent name="Calendar" /> }}
        renderInput={(params) => (
          <TextField
            className="text-field-base"
            required={required}
            {...params}
            fullWidth={fullWidth}
            inputProps={{ ...params.inputProps, placeholder: 'DD/MM/YYYY' }}
          />
        )}
      />
    </DateAdapter>
  );
};

export default DatePickerBase;
