import { ShapesNames } from 'shapes/utils';
import { RawDraftContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

export interface SummaryData {
  question: RawDraftContentState | null;
  answers: Array<string>;
  shouldBeInSummary: boolean;
}

export const shouldBeNodeInSummary = (type: ShapesNames): boolean => {
  const nodesAllowedToBeInSummary = [ShapesNames.INPUT_SHAPE, ShapesNames.SELECT_SHAPE];
  return nodesAllowedToBeInSummary.includes(type);
};

export const getConvertedContent = (nodeContent: RawDraftContentState | null) => {
  if (nodeContent) {
    return draftToHtml(nodeContent);
  }
  return '';
};

export const getReportData = (data: SummaryData[]) => {
  return data.filter((node) => node.shouldBeInSummary);
};

export const getImageQuestionData = (question: RawDraftContentState, index: number) => {
  const convertedQuestion = getConvertedContent(question);
  const parsedDocument = new DOMParser().parseFromString(convertedQuestion, 'text/html');
  const image = parsedDocument.body.children[index];
  const src = image.getAttribute('src') ?? '';
  const imgStyles = image.getAttribute('style')?.split(';');
  const transformedStyles = imgStyles?.reduce((prev, current) => {
    const [styleName, value] = current.split(':');
    prev[styleName] = value.trim();
    return prev;
  }, {} as { [key: string]: string });

  return { src, styles: transformedStyles };
};
