import React from 'react';
import { Divider } from '@mui/material';
import './Menu.scss';
import { generalMenuItems, managerMenuItems } from '../../../utils/menu-utils';
import ListBase from '../../ListBase/ListBase';

const Menu = () => {
  return (
    <div className="menu">
      <ListBase menuItems={generalMenuItems} />
      <Divider variant="middle" />
      <ListBase menuItems={managerMenuItems} />
    </div>
  );
};

export default Menu;
