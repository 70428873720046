import { Node } from '../../../../types/scenario-management.types';
import { ShapesNames } from '../../../../shapes/utils';
import SelectListStep from './SelectListStep';
import SelectCarouselStep from './SelectCarouselStep';
import StartStep from './StartStep';
import MessageStep from './MessageStep';
import EndStep from './EndStep';
import InputStep from './InputStep';
import { StepAnswer } from '../utils';

const displaySelectNode = (
  node: Node<ShapesNames.SELECT_SHAPE>,
  message: string | undefined,
  handleChangeStepAnswer: (id: string, answer: StepAnswer) => void,
  currentValue: string | undefined
) => {
  if (node.data.list && node.data.list.length > 0) {
    return (
      <SelectListStep
        title={node.data.name}
        options={node.data.list}
        message={message}
        id={node.data.contents?.blocks[0].key as string}
        handleChangeStepAnswer={handleChangeStepAnswer}
        currentValue={currentValue}
      />
    );
  } else if (node.data.card) {
    return (
      <SelectCarouselStep
        title={node.data.name}
        options={node.data.card}
        message={message}
        id={node.data.contents?.blocks[0].key as string}
        handleChangeStepAnswer={handleChangeStepAnswer}
        currentValue={currentValue}
      />
    );
  }
};

type UseDisplayStep = {
  node: Node<ShapesNames>;
  handleNextStep: () => void;
  handleChangeStepAnswer: (id: string, answer: StepAnswer) => void;
  handleCompleteScenario: () => void;
  handleResetChecklist: () => void;
  ChecklistForm: Record<string, StepAnswer>;
};

export const useDisplayStep = ({
  node,
  handleNextStep,
  handleChangeStepAnswer,
  handleCompleteScenario,
  handleResetChecklist,
  ChecklistForm
}: UseDisplayStep) => {
  const id = node.data.contents?.blocks[0].key as string;
  const currentValue = ChecklistForm[id] && ChecklistForm[id].answer?.value;
  const message = node.data.contents?.blocks[0].text;
  switch (node.type) {
    case ShapesNames.START_SHAPE:
      return <StartStep handleNextStep={handleNextStep} message={message} />;
    case ShapesNames.MESSAGE_SHAPE:
      return <MessageStep title={node.data.name} message={message} />;
    case ShapesNames.END_SHAPE:
      return (
        <EndStep
          message={message}
          handleCompleteScenario={handleCompleteScenario}
          handleResetChecklist={handleResetChecklist}
        />
      );
    case ShapesNames.INPUT_SHAPE:
      return (
        <InputStep
          title={node.data.name}
          message={message}
          id={id}
          handleChangeStepAnswer={handleChangeStepAnswer}
          currentValue={currentValue}
        />
      );
    case ShapesNames.SELECT_SHAPE:
      return displaySelectNode(node as Node<ShapesNames.SELECT_SHAPE>, message, handleChangeStepAnswer, currentValue);
  }
};
