import { dia } from '@clientio/rappid';
import React, { useEffect, useState } from 'react';
import { Button, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { textboxOptions } from './textbox-options';
import './genericShapeDetails.scss';
import { ShapesNames } from 'shapes/utils';
import SelectDetails from '../SelectDetails/SelectDetails';
import { CardSelectOption, ListSelectOption, SelectType } from 'types/select-option.types';
import { openPopup, PopupType } from 'utils/popup-utils';

interface GenericShapeDetailsProps {
  element: dia.Element;
  closeInspector: () => void;
}

const GenericShapeDetails: React.FC<GenericShapeDetailsProps> = ({ element, closeInspector }) => {
  const [name, setName] = useState('');
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [nodeType, setNodeType] = useState<null | string>(null);
  const [listOptions, setListOptions] = useState<ListSelectOption[]>([]);
  const [cardOptions, setCardOptions] = useState<CardSelectOption[]>([]);
  const [selectType, setSelectType] = useState<string>('');

  useEffect(() => {
    const nodeType = element.attributes.type;
    setName(element.prop('data/name') ?? element.prop('defaultName'));
    setNodeType(nodeType);
    const elementContents = element.prop('data/contents');

    if (elementContents) {
      const convertedData = convertFromRaw(elementContents);
      const editorContent = EditorState.createWithContent(convertedData);
      setEditorState(editorContent);
    } else {
      setEditorState(() => EditorState.createEmpty());
    }
    if (nodeType === ShapesNames.SELECT_SHAPE) {
      setListOptions([...element.prop('data/list')]);
      setCardOptions([...element.prop('data/card')]);
      handleCheckSelectType();
    }
  }, [element]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleCheckSelectType = () => {
    if (element.prop('data/card').length > 0) {
      setSelectType(SelectType.CARD);
    } else {
      setSelectType(SelectType.LIST);
    }
  };

  const handleSaveClick = () => {
    const contents = convertToRaw(editorState.getCurrentContent());
    element.attr('label/textWrap/text', name);
    if (nodeType === ShapesNames.SELECT_SHAPE) {
      element.prop(
        'data',
        {
          name,
          contents,
          type: selectType,
          list: listOptions,
          card: cardOptions
        },
        { rewrite: true }
      );
    } else {
      element.prop({
        data: {
          name,
          contents: convertToRaw(editorState.getCurrentContent())
        }
      });
    }

    openPopup(PopupType.NODE_CHANGES_SAVED);
  };

  return (
    <>
      <div className="row-flex">
        <div className="title">{element.prop('defaultName')}</div>
        <IconButton aria-label="delete" onClick={closeInspector}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="properties-container">
        <div className="field name-field">
          <span className="field-label">Name</span>
          <TextField
            hiddenLabel
            variant="outlined"
            margin="dense"
            size="small"
            fullWidth
            value={name}
            onChange={handleChange}
          />
        </div>
        <div className="field content-field">
          <span className="field-label">Contents</span>
          <Editor
            editorState={editorState}
            wrapperClassName="editor-wrapper"
            editorClassName="editor-textbox"
            toolbarClassName="textbox-options"
            onEditorStateChange={setEditorState}
            toolbar={textboxOptions}
          />
        </div>
        {nodeType === ShapesNames.SELECT_SHAPE && (
          <SelectDetails
            listOptions={listOptions}
            setListOptions={setListOptions}
            cardOptions={cardOptions}
            setCardOptions={setCardOptions}
            selectType={selectType}
            setSelectType={setSelectType}
          />
        )}
      </div>
      <Button className="save-btn" variant="contained" onClick={handleSaveClick}>
        Save
      </Button>
    </>
  );
};

export default GenericShapeDetails;
