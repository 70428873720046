import { shapes } from '@clientio/rappid';
import { SelectShape } from './selectShape/select.shape';
import { StartShape } from './startShape/start.shape';
import { EndShape } from './endShape/end.shape';
import { SelectOptionShape } from './selectOptionShape/selectOption.shape';
import { MessageShape } from './messageShape/message.shape';
import { InputShape } from './inputShape/input.shape';

Object.assign(shapes, {
  app: {
    StartShape,
    SelectShape,
    EndShape,
    InputShape,
    MessageShape,
    SelectOptionShape
  }
});

export { SelectShape, StartShape, EndShape, SelectOptionShape, InputShape, MessageShape };
