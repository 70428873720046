import React from 'react';
import './Layout.scss';
import Navigation from '../Navigation/Navigation';
import ToolbarBase from '../ToolbarBase/ToolbarBase';

const Layout: React.FC = ({ children }) => {
  return (
    <div className="layout">
      <Navigation />
      <div className="layout__content">
        <ToolbarBase />
        <div className="layout__content-body">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
