import React, { useMemo } from 'react';
import { TableBody } from '@mui/material';
import { get } from 'lodash';
import { getFilteredTableItems, updatedTableData } from '../table-utils';
import TableBodyRow from './TableBodyRow/TableBodyRow';
import { TableDataItem, TableRowsProps } from '../table.types';

const TableBodyRows = <T extends TableDataItem>({
  tableData,
  order,
  orderBy,
  page,
  rowsPerPage,
  searchBy,
  customRenderers,
  onRowClickFunction,
  sortWildcard
}: TableRowsProps<T>) => {
  const wildcardItems = useMemo(() => {
    return tableData.filter((item) => get(item, orderBy) === sortWildcard);
  }, [sortWildcard, tableData, orderBy]);

  const otherItems = useMemo(() => {
    return tableData.filter((item) => get(item, orderBy) !== sortWildcard);
  }, [sortWildcard, tableData, orderBy]);

  const dataSortedByWildcard = useMemo(() => {
    return [
      ...getFilteredTableItems(wildcardItems, searchBy),
      ...updatedTableData(otherItems, order, orderBy, searchBy)
    ];
  }, [order, orderBy, otherItems, searchBy, wildcardItems]);

  return (
    <TableBody className="table__body">
      {dataSortedByWildcard.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((tableItem) => {
        return (
          <TableBodyRow
            tableItem={tableItem}
            key={tableItem.id as string}
            customRenderers={customRenderers}
            onRowClickFunction={onRowClickFunction}
          />
        );
      })}
    </TableBody>
  );
};

export default TableBodyRows;
