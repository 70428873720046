import { useSelector } from 'react-redux';
import {
  selectPostVisitTickets,
  selectPreVisitTickets,
  selectSelectedTicket,
  selectTicketRows,
  selectTickets,
  selectWaitingForApprovalTickets,
  selectWaitingForApprovalTicketsAmount
} from './tickets.selectors';

export const useSelectTickets = () => useSelector(selectTickets);

export const useSelectSelectedTicket = () => useSelector(selectSelectedTicket);

export const useSelectTicketRows = () => useSelector(selectTicketRows);

export const useSelectPreVisitTickets = () => useSelector(selectPreVisitTickets);

export const useSelectPostVisitTickets = () => useSelector(selectPostVisitTickets);

export const useSelectWaitingForApprovalTickets = () => useSelector(selectWaitingForApprovalTickets);

export const useSelectWaitingForApprovalTicketsAmount = () => useSelector(selectWaitingForApprovalTicketsAmount);
