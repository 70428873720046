import React from 'react';
import { Autocomplete } from '@mui/material';
import { Icon } from 'react-feather';
import { TextContentPath } from '../../i18n/i18n-config';
import './AutocompleteBase.scss';
import TextFieldBase from '../TextFieldBase/TextFieldBase';
import { MemoizedIconComponent } from '../Icon/Icon';
import { ListSelectOption } from '../../types/select-option.types';
import LabelBase from '../LabelBase/LabelBase';
import { InitialFormState } from '../../views/common/hooks/useForm';

export interface AutocompleteBaseProps<T extends InitialFormState> {
  options: ListSelectOption[];
  selectedOption: ListSelectOption | undefined;
  handleChange: (eventTargetName: keyof T, selectedValue: ListSelectOption | undefined) => void;
  autocompleteName?: string;
  label?: TextContentPath;
  customChevronComponent?: Icon;
  autocompletePlaceholder?: TextContentPath;
  fullWidth?: boolean;
}

const AutocompleteBase: React.FC<AutocompleteBaseProps<InitialFormState>> = ({
  options,
  selectedOption,
  handleChange,
  autocompleteName = '',
  label,
  customChevronComponent = <MemoizedIconComponent name={'ChevronDown'} />,
  autocompletePlaceholder,
  fullWidth
}) => {
  return (
    <div>
      <LabelBase label={label} />
      <Autocomplete
        fullWidth={fullWidth}
        className="select-base"
        options={options}
        popupIcon={customChevronComponent}
        value={selectedOption ?? null}
        getOptionLabel={(option) => option.value as string}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={(e, autocompleteValue) =>
          handleChange(autocompleteName as keyof InitialFormState, autocompleteValue ?? undefined)
        }
        renderInput={(params) => (
          <TextFieldBase name={autocompleteName} placeholderText={autocompletePlaceholder} {...params} />
        )}
      />
    </div>
  );
};
export default AutocompleteBase;
