import { util, shapes } from '@clientio/rappid';
import { SelectAttributes } from './select.attributes';
import { SelectOptionShape } from '../selectOptionShape/selectOption.shape';
import { SelectOption } from 'types/select-option.types';
import { defaultShapeSize } from '../utils';

export class SelectShape extends shapes.standard.Rectangle {
  defaults(): object {
    return util.defaultsDeep(SelectAttributes, shapes.standard.Rectangle.prototype.defaults);
  }

  public initialize(attributes?: any, options?: any): void {
    super.initialize(attributes, options);
    this.on('change:position', (shape, attr, options) => {
      if (options.treeLayout || options.commandManager) {
        const { x, y } = this.getBBox();
        this.getEmbeddedCells().forEach((cell, index) => {
          const { height } = cell.getBBox();
          cell.set('position', { x, y: y + height + height * index }, { ignoreCommandManager: true });
        });
      }
    });
  }

  updatePositions(): void {
    const { x, y } = this.getBBox();
    this.getEmbeddedCells().forEach((cell, index) => {
      const { height } = cell.getBBox();
      cell.set('position', { x, y: y + height + height * index }, { ignoreCommandManager: true });
    });
  }

  updateOptions(): void {
    const selectData = this.prop('data');
    this.graph.removeCells(this.getEmbeddedCells(), { ignoreCommandManager: true });
    this.resize(defaultShapeSize.width, defaultShapeSize.height, { ignoreCommandManager: true });
    if (!selectData) return;

    const selectType = selectData.type;
    const selectOptions = selectData[selectType];
    if (!selectOptions) return;

    selectOptions.forEach((option: SelectOption) => {
      this.addOption(option, selectType);
    });
  }

  addOption(option: SelectOption, type: string): void {
    const label = option.key;
    const selectBbox = this.getBBox();
    const attrs = { attrs: { label: { textWrap: { text: label } } }, data: { ...option, type } };
    const { x, y } = selectBbox.bottomLeft();
    const optionShape = new SelectOptionShape(attrs, { ignoreCommandManager: true });

    optionShape.parent(this.id);
    optionShape.position(x, y, { ignoreCommandManager: true });
    const optionHeight = optionShape.size().height;

    this.graph.addCell(optionShape, { ignoreCommandManager: true });
    this.embed(optionShape, { ignoreCommandManager: true });
    this.resize(selectBbox.width, selectBbox.height + optionHeight, { ignoreCommandManager: true });
  }
}
