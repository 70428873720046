import React from 'react';
import Drawer from '@mui/material/Drawer';
import './DrawerBase.scss';
import { MemoizedIconComponent } from '../Icon/Icon';

type Anchor = 'left' | 'right' | 'top' | 'bottom';
interface DrawerBaseProps {
  anchor: Anchor;
  isDrawerOpen: boolean;
  handleToggleDrawer: () => void;
}

const DrawerBase: React.FC<DrawerBaseProps> = ({ children, anchor, isDrawerOpen, handleToggleDrawer }) => {
  return (
    <Drawer className="drawer" anchor={anchor} open={isDrawerOpen}>
      <div className="drawer__close-btn">
        <MemoizedIconComponent name={'X'} onClick={handleToggleDrawer} aria-label="close-modal" />
      </div>
      {children}
    </Drawer>
  );
};

export default DrawerBase;
