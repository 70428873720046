import React, { useEffect, useState } from 'react';
import './../checklist-scenario-modal.scss';
import TextBase from '../../../../components/TextBase/TextBase';
import SelectBase from '../../../../components/SelectBase/SelectBase';
import { ListSelectOption } from '../../../../types/select-option.types';
import { SelectChangeEvent } from '@mui/material';
import { AnswerStepProps } from '../utils';

interface SelectListStepProps extends AnswerStepProps {
  options: ListSelectOption[];
}

const SelectListStep: React.FC<SelectListStepProps> = ({
  options,
  message,
  handleChangeStepAnswer,
  id,
  currentValue
}) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const handleSelectChange = (e: SelectChangeEvent<unknown>) => {
    const option = { key: e.target.value as string, value: e.target.value as string };
    setSelectedOption(option);
    handleChangeStepAnswer(id, { question: message as string, answer: option });
  };

  useEffect(() => {
    if (currentValue !== undefined) {
      const option = options.find((option) => {
        return option.value === currentValue;
      });
      option && setSelectedOption(option);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="select-list-step">
      <TextBase variant={'h5'}>{message}</TextBase>
      <SelectBase options={options} selectedOption={selectedOption} onChange={handleSelectChange} />
    </div>
  );
};

export default SelectListStep;
