import React from 'react';
import './services-assignment-modal.scss';
import TextBase from '../../../components/TextBase/TextBase';
import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import { Divider } from '@mui/material';

interface HeaderProps {
  handleCloseModal: () => void;
  handleSaveModal: () => void;
  ticketId: string;
  saveButtonDisabled: boolean;
}

const Header: React.FC<HeaderProps> = ({ handleCloseModal, handleSaveModal, ticketId, saveButtonDisabled }) => {
  return (
    <div className="modal-header">
      <div className="title-wrapper">
        <TextBase variant={'h5'} fontSize={20} fontWeight={600} content={'checklistScenario.checklistScenarioTicket'}>
          {ticketId}
        </TextBase>
        <div>
          <ButtonBase
            content={'common.cancel'}
            variant={'outlined'}
            onClick={handleCloseModal}
            sx={{ marginRight: '10px' }}
          />
          <ButtonBase
            content={'common.save'}
            variant={'contained'}
            onClick={handleSaveModal}
            disabled={saveButtonDisabled}
          />
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default Header;
