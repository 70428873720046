import React from 'react';
import './../checklist-scenario-modal.scss';
import TextBase from '../../../../components/TextBase/TextBase';
import { Divider } from '@mui/material';

interface MessageStepProps {
  title: string;
  message: string | undefined;
}
// TODO: need to add message data in scenarios
const MessageStep: React.FC<MessageStepProps> = ({ title, message }) => {
  return (
    <div className="message-step">
      <TextBase variant={'h5'}>{title}</TextBase>
      <Divider />
      <TextBase variant={'body1'}>{message}</TextBase>
    </div>
  );
};

export default MessageStep;
