import React, { useEffect } from 'react';
import TableBase from 'components/TableBase/TableBase';
import { useSelectWaitingForApprovalTickets } from 'redux-store/features/tickets/tickets.hooks';
import { inboxListHeaders } from './inbox.utils';
import TicketStatusLabel from 'views/common/components/TicketStatusLabel/TicketStatusLabel';
import CareCoordinatorCell from 'views/common/components/CareCoordinatorCell/CareCoordinatorCell';
import { useDrawer } from 'views/common/hooks/useDrawer';
import { setSelectedTicket } from 'redux-store/features/tickets/tickets.slice';
import { useAppDispatch } from 'redux-store/hooks';
import TicketDrawer from 'views/Tickets/TicketDrawer/TicketDrawer';
import { InboxTableRow } from 'types/inbox.type';
import { formatLastUpdated } from 'utils/date-format-utils';
import ApproveReportModal from 'views/common/components/modals/ApproveReportModal/ApproveReportModal';
import DisapproveReportModal from 'views/common/components/modals/DisapproveReportModal/DisapproveReportModal';
import { fetchTickets } from 'redux-store/features/tickets/tickets.actions';

const Inbox = () => {
  const ticketsList = useSelectWaitingForApprovalTickets();
  const dispatch = useAppDispatch();
  const { onDrawerToggle, isDrawerOpen } = useDrawer();

  useEffect(() => {
    dispatch(fetchTickets());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDrawerClose = () => onDrawerToggle(false);

  const openTicketDrawer = (id: string | number) => {
    dispatch(setSelectedTicket({ id: id.toString() }));
    onDrawerToggle(true);
  };

  return (
    <div className="inbox">
      <TableBase<InboxTableRow>
        tableHeaders={inboxListHeaders}
        tableData={ticketsList}
        sortBy={'lastUpdatedDate'}
        onRowClickFunction={openTicketDrawer}
        customRenderers={{
          ticketId: (it) => <span className="color-dark-disabled">{it.ticketId}</span>,
          statusDetails: (it) => (
            <TicketStatusLabel visitLabel={it.statusDetails[0]} visitStatus={it.statusDetails[1]} />
          ),
          careCoordinator: (it) => (
            <CareCoordinatorCell careCoordinator={it.careCoordinator} colorClassName={'color-dark'} />
          ),
          lastUpdatedDate: (it) => (
            <span className="color-dark-disabled">{formatLastUpdated(new Date(it.lastUpdatedDate))}</span>
          )
        }}
      />
      <TicketDrawer isDrawerOpen={isDrawerOpen} handleToggleDrawer={handleDrawerClose} />
      <ApproveReportModal />
      <DisapproveReportModal />
    </div>
  );
};

export default Inbox;
