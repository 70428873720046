import React from 'react';
import './selectDetails.scss';
import { Button } from '@mui/material';
import { uniqueId } from 'lodash';
import CardOption from './CardOption';
import { CardSelectOption } from 'types/select-option.types';

interface CardOptionsComponentProps {
  cardOptions: CardSelectOption[];
  handleChangeCardTextField: Function;
  handleRemoveCardItem: Function;
  handleAddCardOption: Function;
}

const CardOptionsWrapper: React.FC<CardOptionsComponentProps> = ({
  cardOptions,
  handleChangeCardTextField,
  handleRemoveCardItem,
  handleAddCardOption
}) => {
  return (
    <div>
      <h3>Card Options: </h3>
      <ul>
        {cardOptions.map((item, index) => {
          return (
            <CardOption
              key={uniqueId()}
              cardItem={item}
              handleChangeCardTextField={handleChangeCardTextField}
              handleRemoveCardItem={handleRemoveCardItem}
              index={index}
            />
          );
        })}
      </ul>
      <Button variant="outlined" size="small" onClick={() => handleAddCardOption()}>
        Add Card
      </Button>
    </div>
  );
};

export default CardOptionsWrapper;
