import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import TableBase from 'components/TableBase/TableBase';
import PatientDrawer from './PatientDrawer';
import { useDrawer } from 'views/common/hooks/useDrawer';
import { useSelectPatients } from 'redux-store/features/patients/patients.hooks';
import { PatientWithId } from 'types/patient.types';
import { TableHeader } from 'components/TableBase/table.types';

const defaultSortBy = 'assignedCoordinator';

export const patientsListHeader: TableHeader[] = [
  {
    column: 'table.column.name',
    id: 'name',
    enableSort: true
  },
  {
    column: 'table.column.gender',
    id: 'gender',
    enableSort: true
  },
  {
    column: 'table.column.birthdate',
    id: 'birthdate',
    enableSort: true
  },
  {
    column: 'table.column.address',
    id: 'address',
    enableSort: true
  },
  {
    column: 'table.column.contact',
    id: 'contact',
    enableSort: true
  },
  {
    column: 'table.column.careGrade',
    id: 'careGrade',
    enableSort: true
  },
  {
    column: 'table.column.assignedCoordinator',
    id: 'assignedCoordinator',
    enableSort: true
  }
];

const PatientsList = () => {
  const { t } = useTranslation();
  const patientsList = useSelectPatients();
  //const arrayPatientsList = Array.from(patientsList, ([id, value]) => ({ ...value, id: parseInt(id) }));
  const { onDrawerToggle, isDrawerOpen } = useDrawer();
  const [currentPatient, setCurrentPatient] = useState<PatientWithId | null>(null);

  const handleToggleDrawer = () => onDrawerToggle(!isDrawerOpen);

  const handleGetCurrentData = (id: number | string) => {
    const patient = patientsList.find((currentPatient) => currentPatient.id === id.toString());
    if (patient) {
      setCurrentPatient(patient);
      onDrawerToggle(true);
    }
  };

  return (
    <>
      <TableBase<PatientWithId>
        tableHeaders={patientsListHeader}
        sortBy={defaultSortBy}
        tableData={patientsList}
        addButtonText={'table.addPatient'}
        onRowClickFunction={handleGetCurrentData}
        customRenderers={{
          birthdate: (it) => <span>{format(new Date(it.birthdate), 'dd/MM/yyyy')}</span>
        }}
        sortWildcard={t('patientDetails.notAssigned')}
      />
      <PatientDrawer
        handleToggleDrawer={handleToggleDrawer}
        isDrawerOpen={isDrawerOpen}
        currentPatient={currentPatient}
      />
    </>
  );
};

export default PatientsList;
