import React from 'react';
import './services-assignment-modal.scss';
import { Ticket } from '../../../types/tickets.type';
import TextBase from '../../../components/TextBase/TextBase';
import fileIcon from '../../../assets/file/file.svg';
import fileLargeIcon from '../../../assets/file/file-large.svg';

import { Button } from '@mui/material';
interface ReportWrapperProps {
  ticket: Ticket | undefined;
}

const ReportWrapper: React.FC<ReportWrapperProps> = () => {
  return (
    <div className="report-wrapper">
      <div className="report-header">
        <div className="icon-button">
          <Button variant="text" startIcon={<img alt="fileicon" src={fileIcon} />}>
            <TextBase className="icon-button__text" content={'ticket.assignServicesModal.visitReport'} />
          </Button>
        </div>
        <div className="date-wrapper">
          <TextBase>15/05/2022</TextBase>
          <div className="dot" />
          <TextBase>11:00AM - 11:45AM</TextBase>
        </div>
      </div>
      <div className="report-preview">
        <div className="image-wrapper">
          <img src={fileLargeIcon} alt="fileicon" />
          <TextBase variant={'h5'}>Report Preview</TextBase>
        </div>
      </div>
    </div>
  );
};

export default ReportWrapper;
