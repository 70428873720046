import React from 'react';
import { Page, View, Document, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { getFormattedDate } from 'utils/generic-utils';
import PDFDocumentNode from './PDFDocumentNode';
import { SummaryData } from '../utils';
import font from 'assets/fonts/ArialUnicodeMS.ttf';
import TextBase from 'components/TextBase/TextBase';

Font.register({ family: 'Arial Uni', src: font, format: 'truetype' });

export const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 30,
    paddingTop: 10,
    paddingBottom: 30,
    backgroundColor: '#ffffff',
    fontFamily: 'Arial Uni'
  },
  section: {
    flexGrow: 1
  },
  fixedDate: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'right',
    color: 'black'
  },
  header: {
    width: '100%',
    fontSize: 16,
    paddingBottom: 10,
    borderBottom: '1px solid grey',
    marginBottom: 30
  }
});

const PDFDocument: React.FC<{ nodes: SummaryData[] }> = ({ nodes }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.fixedDate} fixed>
          <TextBase content={'summaryReport.documentGenerated'} />
          {getFormattedDate(new Date())}
        </Text>
        <Text style={styles.header}>
          <TextBase content={'summaryReport.header'} />
        </Text>
        <View style={styles.section}>
          {nodes.map((node, index) => (
            <PDFDocumentNode index={index} node={node} key={`node-${index}`} />
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default PDFDocument;
