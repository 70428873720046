import { useEffect } from 'react';
import { fetchCareCoordinators } from 'redux-store/features/care-coordinator/care-coordinator.actions';
import { fetchPatients } from 'redux-store/features/patients/patients.actions';
import { fetchScenarios } from 'redux-store/features/scenarios/scenarios.actions';
import { fetchServiceProviders } from 'redux-store/features/service-providers/service-providers.actions';
import { fetchServices } from 'redux-store/features/services/services.actions';
import { fetchTickets } from 'redux-store/features/tickets/tickets.actions';
import { useAppDispatch } from 'redux-store/hooks';
import { API, DataPaths } from 'services/data/types';

type DataReducerMappings = { [key in DataPaths]: Function };
const dataReducerMappings: DataReducerMappings = {
  SCENARIOS: fetchScenarios,
  CARE_COORDINATORS: fetchCareCoordinators,
  SERVICES: fetchServices,
  TICKETS: fetchTickets,
  SERVICE_PROVIDERS: fetchServiceProviders,
  PATIENTS: fetchPatients
};

export const useAppInit = (api?: API) => {
  const dispatch = useAppDispatch();
  //init scenarios
  useEffect(() => {
    if (api) {
      for (let key in dataReducerMappings) {
        dispatch(dataReducerMappings[key as DataPaths]());
      }
    }
  }, [api]); // eslint-disable-line react-hooks/exhaustive-deps

  return api;
};
