import React from 'react';
import { Modal } from '@mui/material';
import './ModalBase.scss';
import { MemoizedIconComponent } from '../Icon/Icon';
import TextBase from '../TextBase/TextBase';
import { TextContentPath } from '../../i18n/i18n-config';
import clsx from 'clsx';

interface ModalBaseProps {
  isModalOpen: boolean;
  closeModal: Function;
  headerText?: TextContentPath;
  isFullScreen?: boolean;
  customHeader?: boolean;
}

const ModalBase: React.FC<ModalBaseProps> = ({
  isModalOpen,
  children,
  closeModal,
  headerText,
  isFullScreen,
  customHeader
}) => {
  return (
    <Modal open={isModalOpen}>
      <div className={clsx('base-modal', isFullScreen && 'modal-full-screen')}>
        {!customHeader && (
          <div className="base-modal__header">
            <TextBase content={headerText} variant={'h3'} />
            <MemoizedIconComponent name={'X'} onClick={() => closeModal()} aria-label="close-modal" />
          </div>
        )}
        {children}
      </div>
    </Modal>
  );
};

export default ModalBase;
