import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from 'services/data/data-broker';
import { PatientWithId } from 'types/patient.types';

export const fetchPatients = createAsyncThunk('patients/fetchPatients', async () => {
  const api = await Api;
  try {
    const tickets = (await api.PATIENTS.readAll()) as PatientWithId[];
    return tickets;
  } catch (err) {
    console.error('patients/fetchPatients error: ', err);
  }
});

export const updatePatient = createAsyncThunk('patients/updatePatient', async (patient: PatientWithId, thunkAPI) => {
  const api = await Api;
  try {
    const patientId = await api.PATIENTS.update(patient);
    thunkAPI.dispatch(updatePatient({ ...patient, id: patientId.toString() }));
  } catch (err) {
    console.error('patients/updatePatient error: ', err);
  }
});
