import { Ticket, TicketCreationData } from '../types/tickets.type';
import { TicketStatuses, TicketStatusLabels, TicketTypes } from '../views/common/enums/ticket.enum';

export const generateTicket = (ticketCreationData: TicketCreationData): Ticket => {
  const { ticketOwner, checklistScenario, patientAssigned } = ticketCreationData;
  return {
    creationDate: new Date().toISOString(),
    id: '000000000',
    ticketType: TicketTypes.VISITING,
    ticketStatus: TicketStatuses.OPERATOR_ASSIGNED,
    ticketStatusLabel: TicketStatusLabels.PRE,
    ticketOwnerManager: 'Manager Manager',
    scheduledDate: undefined,
    timebox: '',
    careGrade: undefined,
    services: [],
    history: [],
    memo: [],
    patientAssigned,
    ticketOwner,
    checklistScenario,
    lastUpdatedDate: new Date().toISOString(),
    checklistAnswers: undefined
  };
};
