import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CareCoordinator } from 'types/care-coordinator.types';
import { fetchCareCoordinators } from './care-coordinator.actions';

export interface CareCoordinatorState {
  careCoordinators: CareCoordinator[];
  loading: boolean;
}

const initialState: CareCoordinatorState = {
  careCoordinators: [],
  loading: false
};

const careCoordinatorSlice = createSlice({
  name: 'careCoordinator',
  initialState,
  reducers: {
    setCareCoordinators: (state, action: PayloadAction<CareCoordinator[]>) => {
      state.careCoordinators = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCareCoordinators.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchCareCoordinators.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload) {
        state.careCoordinators = payload;
      }
    });
    builder.addCase(fetchCareCoordinators.rejected, (state) => {
      state.loading = false;
    });
  }
});

const { reducer: careCoordinatorReducer } = careCoordinatorSlice;

export default careCoordinatorReducer;
export const { setCareCoordinators } = careCoordinatorSlice.actions;
