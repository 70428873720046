// Other //
export const isIndexedDbUsed = (): boolean => {
  return process.env.REACT_APP_USE_INDEXED_DB === 'true';
};

export enum FetchingDataStatus {
  INITIAL = 'initial',
  PENDING = 'pending',
  ERROR = 'error',
  SUCCESS = 'success'
}
