import React from 'react';
import { Box, StepContent, StepLabel } from '@mui/material';
import { getConvertedContent, shouldBeNodeInSummary } from '../utils';
import ButtonBase from 'components/ButtonBase/ButtonBase';
import DisplayHTML from 'components/DisplayHTML/DisplayHTML';
import { StepContentProps } from './StepComponent';
import { ShapesNames } from '../../../shapes/utils';

const StepInnerContent: React.FC<StepContentProps<ShapesNames>> = ({
  handleBack,
  isFirstStep,
  handleNext,
  stepData,
  isLastStep,
  children,
  userAnswers
}) => {
  const handleNextClick = () => {
    const nodeData = {
      question: stepData.data.contents,
      answers: userAnswers,
      shouldBeInSummary: shouldBeNodeInSummary(stepData.type)
    };
    handleNext(nodeData);
  };

  return (
    <>
      <StepLabel>
        <DisplayHTML content={getConvertedContent(stepData.data.contents)} />
      </StepLabel>
      <StepContent>
        {children}
        <Box sx={{ mb: 2 }}>
          <div>
            <ButtonBase
              content={'checklistScenario.back'}
              disabled={isFirstStep}
              onClick={handleBack}
              sx={{ mt: 1, mr: 1 }}
            />
            <ButtonBase
              content={`checklistScenario.${isLastStep ? 'finish' : 'next'}`}
              variant="contained"
              onClick={handleNextClick}
              sx={{ mt: 1, mr: 1 }}
            />
          </div>
        </Box>
      </StepContent>
    </>
  );
};

export default StepInnerContent;
