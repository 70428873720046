import React, { useEffect, useRef } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import TextBase from 'components/TextBase/TextBase';
import Inspector from 'components/Inspector/Inspector';
import { rappid } from 'services/rappid.service';
import { useSelectSelectedScenario } from 'redux-store/features/scenarios/scenarios.hooks';

const ScenarioBuilderDiagram = () => {
  const scenario = useSelectSelectedScenario();
  const stencil = useRef(null);
  const paper = useRef(null);
  const navigator = useRef(null);
  const tools = useRef(null);

  useEffect(() => {
    if (rappid && paper.current && stencil.current && navigator.current && tools.current && scenario) {
      rappid.initialize(paper.current, stencil.current, navigator.current, tools.current);
      const cells = scenario.scenario;
      rappid.loadScenario(cells);

      return () => {
        rappid?.destroyDiagram();
      };
    }
  }, [scenario]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="scenario-builder">
      <div ref={tools} className="tools-container" />
      <div ref={stencil} className="stencil-container">
        <div className="stencil-header">
          <MenuIcon />
          <TextBase className="stencil-title" content="scenarioBuilder.stencilTitle" />
        </div>
      </div>
      <div ref={paper} className="paper-container" />
      <div ref={navigator} className="navigator-container" />
      <Inspector />
    </div>
  );
};

export default ScenarioBuilderDiagram;
