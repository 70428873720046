import StepInnerContent from './StepInnerContent';
import React, { useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import SelectCard from './SelectStep/SelectCard';
import { CardSelectOption, ListSelectOption, SelectType } from 'types/select-option.types';
import { StepContentProps } from './StepComponent';
import { ShapesNames } from '../../../shapes/utils';
import SelectBase from '../../../components/SelectBase/SelectBase';
import { uniqueId } from 'lodash';

const SelectStep: React.FC<StepContentProps<ShapesNames.SELECT_SHAPE>> = ({ userAnswers, stepData, ...props }) => {
  const [selectedOption, setSelectedOption] = useState({ key: uniqueId(), value: '' });
  const [selectListOptions, setSelectListOptions] = useState<ListSelectOption[]>([]);
  const [selectCardOptions, setSelectCardOptions] = useState<CardSelectOption[]>([]);

  useEffect(() => {
    let answer = userAnswers[0];
    if (userAnswers[0] === '' && stepData.data.type === SelectType.CARD && selectCardOptions.length) {
      answer = selectCardOptions[0].value as string;
    }
    setSelectedOption({ key: uniqueId(), value: answer });
  }, [userAnswers]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeList = (event: SelectChangeEvent<unknown>) => {
    setSelectedOption({ key: uniqueId(), value: event.target.value as string });
  };

  const handleChangeCard = (cardValue: string) => {
    setSelectedOption({ key: uniqueId(), value: cardValue });
  };

  useEffect(() => {
    if (stepData.data.card && stepData.data.card.length > 0) {
      setSelectCardOptions(stepData.data.card);
    } else if (stepData.data.list && stepData.data.list.length > 0) {
      setSelectListOptions(stepData.data.list);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StepInnerContent {...props} stepData={stepData} userAnswers={[selectedOption.value]}>
      {stepData.data.type === SelectType.LIST ? (
        <SelectBase
          fullWidth
          options={selectListOptions}
          onChange={(e) => handleChangeList(e)}
          selectedOption={selectedOption}
          label={'scenarioManagement.options'}
        />
      ) : (
        <SelectCard
          options={selectCardOptions}
          handleChangeCard={handleChangeCard}
          selectedOption={selectedOption.value}
        />
      )}
    </StepInnerContent>
  );
};
export default SelectStep;
