import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { createTicket, fetchTickets, updateTicket } from './tickets.actions';
import { Ticket } from 'types/tickets.type';

export const ticketsAdapter = createEntityAdapter<Ticket>({
  selectId: (ticket) => ticket.id,
  sortComparer: (a, b) => a.id.localeCompare(b.id)
});

const initialState: EntityState<Ticket> & { selectedTicket?: Ticket; loading: boolean } =
  ticketsAdapter.getInitialState({
    selectedTicket: undefined,
    loading: false
  });

type TicketState = EntityState<Ticket> & { selectedTicket?: Ticket };

const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    setTickets: (state, action: PayloadAction<Ticket[]>) => {
      ticketsAdapter.setAll(state as TicketState, action.payload);
    },
    setSelectedTicket: (state, action: PayloadAction<Pick<Ticket, 'id'>>) => {
      state.selectedTicket = ticketsAdapter.getSelectors().selectById(state as EntityState<Ticket>, action.payload.id);
    },
    updateStoreTicket: (state, action: PayloadAction<Ticket>) => {
      ticketsAdapter.updateOne(state as TicketState, { id: action.payload.id, changes: { ...action.payload } });
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTickets.fulfilled, (state, { payload }) => {
      if (payload) {
        ticketsAdapter.setAll(state as TicketState, payload);
      }
    });
    builder.addCase(createTicket.fulfilled, (state, { payload }) => {
      if (payload) {
        ticketsAdapter.setOne(state as TicketState, payload);
      }
    });
    builder.addCase(updateTicket.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload) {
        ticketsAdapter.updateOne(state as TicketState, { id: payload.id, changes: { ...payload } });
      }
    });
    // eslint-disable-next-line no-empty-pattern
    builder.addCase(updateTicket.rejected, (state, {}) => {
      state.loading = false;
    });
  }
});

export const { setSelectedTicket, setTickets, updateStoreTicket } = ticketsSlice.actions;
const { reducer: ticketsReducer } = ticketsSlice;

export default ticketsReducer;
