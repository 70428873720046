import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import ScenarioBuilder from './views/ScenarioBuilder/ScenarioBuilder';
import ChecklistScenario from './views/ChecklistScenario/ChecklistScenario';
import './App.scss';
import ScenarioManagement from './views/ScenarioManagement/ScenariosManagement';
import Providers from './context-store/Providers';
import { AppPaths } from './utils/app-routing';
import Layout from './components/Layout/Layout';
import PatientsList from './views/DataManagement/Patients/PatientsList';
import ServicesList from './views/DataManagement/Services/ServicesList';
import TicketsList from './views/Tickets/TicketsList';
import SnackbarBase from './components/SnackbarBase/SnackbarBase';
import Inbox from './views/Inbox/Inbox';

function App() {
  return (
    <Providers>
      <div className="app">
        <BrowserRouter>
          <Layout>
            <Routes>
              <Route path={AppPaths.TICKETS} element={<TicketsList />} />
              <Route path={AppPaths.INBOX} element={<Inbox />} />
              <Route path={AppPaths.SCENARIO_BUILDER} element={<ScenarioBuilder />} />
              <Route path={AppPaths.CHECKLIST_SCENARIO} element={<ChecklistScenario />} />
              <Route path={AppPaths.ALL_PATHS} element={<Navigate to={AppPaths.SCENARIO_MANAGEMENT} />} />
              <Route path={AppPaths.SCENARIO_MANAGEMENT} element={<ScenarioManagement />} />
              <Route path={AppPaths.DATA_MANAGEMENT_PATIENTS} element={<PatientsList />} />
              <Route path={AppPaths.DATA_MANAGEMENT_SERVICES} element={<ServicesList />} />
            </Routes>
            <SnackbarBase />
          </Layout>
        </BrowserRouter>
      </div>
    </Providers>
  );
}

export default App;
