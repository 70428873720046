import { TicketActionsMenuItemsTypes } from '../../Tickets/tickets.utils';
import { useModal } from './useModal';
import { ModalTypes } from '../enums/modal-types.enum';

export const useTicketAction = () => {
  const { onModalOpen: OnModalOpenScheduleVisitModal } = useModal(ModalTypes.SCHEDULE_VISIT);
  const { onModalOpen: OnModalOpenStartVisitModal } = useModal(ModalTypes.START_VISIT);
  const { onModalOpen: OnModalOpenEndVisitModal } = useModal(ModalTypes.END_VISIT);
  const { onModalOpen: onModalOpenChecklistScenario } = useModal(ModalTypes.CHECKLIST_SCENARIO);
  const { onModalOpen: onModalOpenServicesAssignment } = useModal(ModalTypes.SERVICES_ASSIGNMENT);
  const { onModalOpen: onModalOpenApproveServices } = useModal(ModalTypes.APPROVE_SERVICES);
  const { onModalOpen: OnModalOpenRescheduleVisitModal } = useModal(ModalTypes.RESCHEDULE_VISIT);
  const { onModalOpen: onModalOpenNeedRevisit } = useModal(ModalTypes.NEED_REVISIT);
  const { onModalOpen: onModalOpenDisapproveServices } = useModal(ModalTypes.DISAPPROVE_SERVICES);

  const triggerSelectedAction = (actionType: TicketActionsMenuItemsTypes) => {
    return menuItemsCallbacks[actionType]();
  };

  const menuItemsCallbacks = {
    [TicketActionsMenuItemsTypes.CHECKLIST_SCENARIO]: () => {
      onModalOpenChecklistScenario();
    },
    [TicketActionsMenuItemsTypes.END_VISIT]: () => {
      OnModalOpenEndVisitModal();
    },
    [TicketActionsMenuItemsTypes.START_VISIT]: () => {
      OnModalOpenStartVisitModal();
    },
    [TicketActionsMenuItemsTypes.RESCHEDULE_VISIT]: () => {
      OnModalOpenRescheduleVisitModal();
    },
    [TicketActionsMenuItemsTypes.SCHEDULE_VISIT]: () => {
      OnModalOpenScheduleVisitModal();
    },
    [TicketActionsMenuItemsTypes.ASSIGN_SERVICES]: () => {
      onModalOpenServicesAssignment();
    },
    [TicketActionsMenuItemsTypes.EDIT_ASSIGNED_SERVICES]: () => {},
    [TicketActionsMenuItemsTypes.APPROVE_SERVICES]: () => {
      onModalOpenApproveServices();
    },
    [TicketActionsMenuItemsTypes.DISAPPROVE_SERVICES]: () => {
      onModalOpenDisapproveServices();
    },
    [TicketActionsMenuItemsTypes.NEED_REVISIT]: () => {
      onModalOpenNeedRevisit();
    }
  };

  return { triggerSelectedAction };
};
