import React from 'react';
import clsx from 'clsx';
import { format } from 'date-fns';
import './DateTimeSection.scss';
import TextBase from '../../../../../../components/TextBase/TextBase';
import { Ticket } from '../../../../../../types/tickets.type';

interface DateTimeSectionProps {
  currentTicket: Ticket;
  darkPrimaryColor?: boolean;
}

const formatDate = (date: Date | undefined) => {
  if (date) {
    return format(date, 'dd/LL/yyyy');
  }
};

const formatTime = (date: Date | undefined) => {
  if (date) {
    return format(date, 'hh:mmb');
  }
};

const DateTimeSection: React.FC<DateTimeSectionProps> = ({ currentTicket, darkPrimaryColor = false }) => {
  return (
    <div className={clsx('date-time', darkPrimaryColor && 'color-dark')}>
      <TextBase variant="body2">
        {currentTicket.scheduledDate?.startDate && formatDate(new Date(currentTicket.scheduledDate?.startDate))}
      </TextBase>
      <div className="dot" />
      <TextBase variant="body2">
        {currentTicket.scheduledDate?.startTime && formatTime(new Date(currentTicket.scheduledDate?.startTime))}
      </TextBase>
      <TextBase variant="body2" className="date-time__divider">
        -
      </TextBase>
      <TextBase variant="body2">
        {currentTicket.scheduledDate?.endTime && formatTime(new Date(currentTicket.scheduledDate?.endTime))}
      </TextBase>
    </div>
  );
};

export default DateTimeSection;
