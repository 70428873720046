import React from 'react';
import clsx from 'clsx';
import './MemosList.scss';
import { Ticket } from 'types/tickets.type';
import TextBase from 'components/TextBase/TextBase';
import ButtonBase from 'components/ButtonBase/ButtonBase';
import { formatLastUpdated } from 'utils/date-format-utils';

interface MemosListProps {
  currentTicket: Ticket;
}

const MemosList: React.FC<MemosListProps> = ({ currentTicket }) => {
  return (
    <>
      {currentTicket.memo?.map((memo) => (
        <div className={clsx('memos__list', currentTicket.memo.length > 1 && 'multiple-memos')} key={memo.id}>
          <img src={memo.createdBy.imagePath} alt="profile pic" className="avatar" />
          <div className="memos__list-item">
            <div className="memos__list-item-header">
              <TextBase variant="subtitle2" fontWeight="600" className="color-dark">
                {`${memo.createdBy.name} ${memo.createdBy.surname}`}
              </TextBase>
              <TextBase variant="subtitle2" className="color-dark-disabled">
                {formatLastUpdated(new Date(memo.createdDate))}
              </TextBase>
            </div>
            <TextBase variant="body2" className="color-dark">
              {memo.memo}
            </TextBase>
            <div className="memos__list-item-buttons">
              <ButtonBase content={'common.edit'} />
              <div className="dot" />
              <ButtonBase content={'common.delete'} />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default MemosList;
