import { ui } from '@clientio/rappid';
import { ShapesNames } from '../../shapes/utils';

const haloRemove: ui.Halo.Handle = {
  name: 'remove',
  position: ui.Halo.HandlePosition.NW,
  events: { pointerdown: 'removeElement' },
  attrs: {
    '.handle': {
      'data-tooltip-class-name': 'small',
      'data-tooltip': 'Remove node',
      'data-tooltip-position': 'right'
    }
  }
};

const haloLink: ui.Halo.Handle = {
  name: 'link',
  position: ui.Halo.HandlePosition.E,
  events: { pointerdown: 'startLinking', pointermove: 'doLink', pointerup: 'stopLinking' },
  attrs: {
    '.handle': {
      'data-tooltip-class-name': 'small',
      'data-tooltip': 'Click and drag to connect the object',
      'data-tooltip-position': 'left'
    }
  }
};

const haloUnlink: ui.Halo.Handle = {
  name: 'unlink',
  position: ui.Halo.HandlePosition.SW,
  events: { pointerdown: 'unlinkElement' },
  attrs: {
    '.handle': {
      'data-tooltip-class-name': 'small',
      'data-tooltip': 'Click to break all connections to other objects',
      'data-tooltip-position': 'right'
    }
  }
};

const defaultShapeHaloConfig: ui.Halo.Handle[] = [haloUnlink, haloLink, haloRemove];

const endShapeHaloConfig: ui.Halo.Handle[] = [haloRemove];

const linkedStartShapeHaloConfig: ui.Halo.Handle[] = [haloUnlink];

const startShapeHaloConfig: ui.Halo.Handle[] = [haloLink];

const linkedShapeHaloConfig: ui.Halo.Handle[] = [haloUnlink, haloRemove];

export const getShapeHaloConfig = (cellType: string, isCellLinked: boolean): ui.Halo.Handle[] => {
  if (cellType === ShapesNames.START_SHAPE && isCellLinked) {
    return linkedStartShapeHaloConfig;
  } else if (cellType === ShapesNames.START_SHAPE) {
    return startShapeHaloConfig;
  } else if (cellType === ShapesNames.END_SHAPE) {
    return endShapeHaloConfig;
  } else if (isCellLinked) {
    return linkedShapeHaloConfig;
  } else {
    return defaultShapeHaloConfig;
  }
};
