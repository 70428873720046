import React from 'react';
import './Navigation.scss';
import Menu from './Menu/Menu';
import logo from '../../assets/logo/logo.svg';

const Navigation = () => {
  return (
    <div className="navigation">
      <div className="navigation__image">
        <img src={logo} alt="logo" />
      </div>
      <Menu />
    </div>
  );
};

export default Navigation;
