import React from 'react';
import './../checklist-scenario-modal.scss';
import { Divider } from '@mui/material';
import ButtonBase from 'components/ButtonBase/ButtonBase';
import { Node } from 'types/scenario-management.types';
import { ShapesNames } from 'shapes/utils';
import { useDisplayStep } from './useDisplayStep';
import { StepAnswer } from '../utils';

interface StepsWrapperProps {
  currentStep: number;
  maxSteps: number;
  handlePrevStep: () => void;
  handleNextStep: () => void;
  checklistNodes: Node<ShapesNames>[];
  handleChangeStepAnswer: (id: string, answer: StepAnswer) => void;
  handleCompleteScenario: () => void;
  handleResetChecklist: () => void;
  ChecklistForm: Record<string, StepAnswer>;
}

const StepsWrapper: React.FC<StepsWrapperProps> = ({
  currentStep,
  handleNextStep,
  handlePrevStep,
  maxSteps,
  checklistNodes,
  handleChangeStepAnswer,
  handleCompleteScenario,
  handleResetChecklist,
  ChecklistForm
}) => {
  const step = useDisplayStep({
    node: checklistNodes[currentStep],
    handleNextStep,
    handleChangeStepAnswer,
    handleCompleteScenario,
    handleResetChecklist,
    ChecklistForm
  });
  return (
    <div className="steps-wrapper">
      {step}
      {currentStep !== 0 && (
        <div className="footer">
          <Divider />
          <div className="buttons-wrapper">
            <ButtonBase content={'checklistScenario.back'} onClick={handlePrevStep} variant={'outlined'} />
            <ButtonBase
              className="button-next"
              content={'checklistScenario.nextQuestion'}
              onClick={handleNextStep}
              variant={'contained'}
              disabled={currentStep === maxSteps}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default StepsWrapper;
