import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import './selectDetails.scss';
import { IconButton, TextField, Card } from '@mui/material';
import { ListSelectOption } from 'types/select-option.types';

interface ListOptionsComponentProps {
  listItem: ListSelectOption;
  handleChangeListOptionTextField: Function;
  handleRemoveListItem: Function;
  index: number;
}

const ListOption: React.FC<ListOptionsComponentProps> = ({
  listItem,
  handleChangeListOptionTextField,
  handleRemoveListItem,
  index
}) => {
  return (
    <Card>
      <TextField
        id="outlined-helperText"
        label="key"
        autoComplete="off"
        defaultValue={listItem.key}
        size="small"
        onChange={(e) => handleChangeListOptionTextField('key', index, e.currentTarget.value)}
      />
      <TextField
        id="outlined-helperText"
        label="value"
        autoComplete="off"
        defaultValue={listItem.value}
        size="small"
        onChange={(e) => handleChangeListOptionTextField('value', index, e.currentTarget.value)}
      />
      <IconButton aria-label="delete" onClick={() => handleRemoveListItem(index)}>
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Card>
  );
};

export default ListOption;
