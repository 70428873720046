import React from 'react';
import { TextContentPath } from 'i18n/i18n-config';
import { MessageTypes } from 'views/common/enums/message-types.enum';
import SuccessMessage from 'components/SnackbarBase/MessageContainer/SuccessMessage';
import ErrorMessage from 'components/SnackbarBase/MessageContainer/ErrorMessage';

interface SnackbarMessageProps {
  messageType: MessageTypes;
  messageContent?: TextContentPath;
  messageFreeText?: string;
  ticketId?: string;
}

export const useSnackbarMessage = (messageProps: SnackbarMessageProps) => {
  const { messageType, messageContent, messageFreeText, ticketId } = messageProps;
  switch (messageType) {
    case MessageTypes.SUCCESS_MSG:
      return <SuccessMessage successMessage={messageContent} successFreeText={messageFreeText} ticketId={ticketId} />;
    case MessageTypes.ERROR_MSG:
      return <ErrorMessage errorMessage={messageContent} errorFreeText={messageFreeText} ticketId={ticketId} />;
  }
};
