import { ShapesNames } from '../../../shapes/utils';
import { Node } from '../../../types/scenario-management.types';
import { useForm } from '../../common/hooks/useForm';
import { useEffect } from 'react';

export interface StepProps {
  title: string;
  message: string | undefined;
}

export interface AnswerStepProps extends StepProps {
  handleChangeStepAnswer: (id: string, answer: StepAnswer) => void;
  id: string;
  currentValue: string | undefined;
}

export interface Answer {
  key?: string;
  value: string;
  url?: string;
}
export interface StepAnswer {
  question: string;
  answer:
    | {
        key?: string;
        value: string;
        url?: string;
      }
    | undefined;
}

export const FilterQuestionsSteps = (checklist: Node<ShapesNames>[]): Node<ShapesNames>[] => {
  return checklist.filter((item) => {
    return item.type === ShapesNames.SELECT_SHAPE || item.type === ShapesNames.INPUT_SHAPE;
  });
};

export const createSelectAnswers = (item: Node<ShapesNames.SELECT_SHAPE>): StepAnswer => {
  if (item.data.list && item.data.list?.length > 0) {
    return { question: item.data.contents?.blocks[0].text as string, answer: { key: '', value: '' } };
  } else if (item.data.card && item.data.card?.length > 0) {
    return { question: item.data.contents?.blocks[0].text as string, answer: { key: '', value: '', url: '' } };
  } else {
    return {
      question: item.data.contents?.blocks[0].text as string,
      answer: undefined
    };
  }
};

export const createAnswersForm = (step: Node<ShapesNames>): StepAnswer => {
  if (step.type === ShapesNames.INPUT_SHAPE) {
    return { question: step.data.contents?.blocks[0].text as string, answer: { value: '' } };
  } else if (step.type === ShapesNames.SELECT_SHAPE) {
    return createSelectAnswers(step as Node<ShapesNames.SELECT_SHAPE>);
  } else {
    return {
      question: step.data.contents?.blocks[0].text as string,
      answer: undefined
    };
  }
};

export const useChecklistAnswersForm = (checklist: Node<ShapesNames>[]) => {
  const { onChange, setValues, values, handleResetForm, handleAddMany, setDefaultValues } = useForm<
    Record<string, StepAnswer>
  >({});

  useEffect(() => {
    const questionsSteps = FilterQuestionsSteps(checklist);
    const mapValues = questionsSteps.reduce((acc, step) => {
      acc[step.data.contents?.blocks[0].key as string] = createAnswersForm(step);
      return acc;
    }, {} as Record<string, StepAnswer>);
    setValues(mapValues);
    setDefaultValues(mapValues);
  }, [checklist]); // eslint-disable-line react-hooks/exhaustive-deps

  return { ChecklistForm: values, onChange, handleResetForm, handleAddMany };
};
