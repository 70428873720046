import React from 'react';
import { SummaryData } from '../utils';
import { ShapesNames } from '../../../shapes/utils';
import InputStep from './InputStep';
import SelectStep from './SelectStep';
import StepInnerContent from './StepInnerContent';
import { Node } from '../../../types/scenario-management.types';

export interface StepContentProps<T extends ShapesNames> {
  isFirstStep: boolean;
  handleBack: () => void;
  handleNext: (answers: SummaryData) => void;
  stepData: Node<T>;
  isLastStep: boolean;
  userAnswers: Array<string>;
  type?: T;
}

export const StepComponent = <T extends ShapesNames>({ stepData, type, ...props }: StepContentProps<T>) => {
  switch (stepData.type) {
    case ShapesNames.INPUT_SHAPE:
      return <InputStep {...props} stepData={stepData} />;
    case ShapesNames.SELECT_SHAPE:
      return <SelectStep {...props} stepData={stepData as Node<ShapesNames.SELECT_SHAPE>} />;
    case ShapesNames.END_SHAPE:
    case ShapesNames.MESSAGE_SHAPE:
    case ShapesNames.START_SHAPE:
    default:
      return <StepInnerContent {...props} type={type} stepData={stepData} />;
  }
};

export const MemoizedStepComponent = React.memo(StepComponent);
