import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import './select.scss';
import CardItem from './CardItem';
import { ListSelectOption } from '../../../../types/select-option.types';

export interface CardItemType extends ListSelectOption {
  url: string;
}

export interface SelectCardProps {
  options: CardItemType[];
  handleChangeCard: (optionValue: string) => void;
  selectedOption: string;
}

const SelectCard: React.FC<SelectCardProps> = ({ options, selectedOption, handleChangeCard }) => {
  const [selectedItem, setSelectedItem] = useState(0);

  useEffect(() => {
    const item = options.findIndex((option) => option.value === selectedOption);
    let itemIndex = item > -1 ? item : 0;
    setSelectedItem(itemIndex);
  }, [selectedOption, options]);

  const handleChange = (index: number) => {
    const optionValue = options[index].value;
    handleChangeCard(optionValue as string);
  };

  return (
    <>
      <Carousel showThumbs={false} showIndicators={false} selectedItem={selectedItem} onChange={handleChange}>
        {options.map((option) => {
          return <CardItem key={option.key} option={option} />;
        })}
      </Carousel>
    </>
  );
};
export default SelectCard;
