import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@mui/material';
import { TextContentPath } from 'i18n/i18n-config';
import React from 'react';

interface ButtonBaseProps extends ButtonProps {
  content?: TextContentPath;
  children?: string;
}

const ButtonBase: React.FC<ButtonBaseProps> = ({ content, children, ...materialProps }) => {
  const { t } = useTranslation();
  const renderedText = content ? t(content) : children;
  return <Button {...materialProps}>{renderedText}</Button>;
};

export default ButtonBase;
