import React from 'react';
import TextBase from '../../../components/TextBase/TextBase';
import BadgeBase from '../../../components/BadgeBase/BadgeBase';
import { TextContentPath } from '../../../i18n/i18n-config';

interface TicketsTabProps {
  content: TextContentPath;
  listSize?: number;
}

const TicketsTab: React.FC<TicketsTabProps> = ({ content, listSize }) => {
  return (
    <div className="tickets__tabs-tab" key={content as string}>
      <TextBase content={content} />
      {listSize !== undefined && <BadgeBase badgeContent={listSize} />}
    </div>
  );
};

export default TicketsTab;
