import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import TableBase from 'components/TableBase/TableBase';
import { getFilteredList, ticketsListHeaders } from './tickets.utils';
import TicketsTab from './TicketsTab/TicketsTab';
import { useAppDispatch } from 'redux-store/hooks';
import {
  useSelectPostVisitTickets,
  useSelectPreVisitTickets,
  useSelectTicketRows
} from 'redux-store/features/tickets/tickets.hooks';
import TicketStatusLabel from 'views/common/components/TicketStatusLabel/TicketStatusLabel';
import { TicketActionsMenu } from './TicketActionsMenu';
import { TicketTableRow } from 'types/tickets.type';
import TicketDrawer from './TicketDrawer/TicketDrawer';
import { useDrawer } from 'views/common/hooks/useDrawer';
import ScheduleVisitModal from 'views/common/components/modals/ScheduleVisitModal/ScheduleVisitModal';
import { useModal } from 'views/common/hooks/useModal';
import { ModalTypes } from 'views/common/enums/modal-types.enum';
import ChecklistScenarioModal from './ChecklistScenarioModal/ChecklistScenarioModal';
import StartVisitModal from 'views/common/components/modals/StartVisitModal/StartVisitModal';
import EndVisitModal from '../common/components/modals/EndVisitModal/EndVisitModal';
import { setSelectedTicket } from 'redux-store/features/tickets/tickets.slice';
import './TicketsList.scss';
import ServicesAssignmentModal from './ServicesAssignmentModal/ServicesAssignmentModal';
import { formatLastUpdated } from 'utils/date-format-utils';
import ConfirmAssignedServicesModal from '../common/components/modals/ConfirmAssignedServicesModal/ConfirmAssignedServicesModal';
import CancelAssignedServicesModal from '../common/components/modals/CancelAssignedServicesModal/CancelAssignedServicesModal';
import RescheduleVisitModal from 'views/common/components/modals/RescheduleModal/RescheduleModal';
import NeedRevisitModal from 'views/common/components/modals/NeedRevisitModal/NeedRevisitModal';
import { fetchTickets } from 'redux-store/features/tickets/tickets.actions';

const TicketsList = () => {
  const [tabValue, setTabValue] = useState(0);
  const { onDrawerToggle, isDrawerOpen } = useDrawer();
  const dispatch = useAppDispatch();
  const ticketsRows = useSelectTicketRows();
  const preVisitTicketsList = useSelectPreVisitTickets();
  const postVisitTicketsList = useSelectPostVisitTickets();
  const { onModalClose, isModalOpen } = useModal(ModalTypes.CHECKLIST_SCENARIO);
  const { isModalOpen: isModalOpenServicesAssignment } = useModal(ModalTypes.SERVICES_ASSIGNMENT);

  const handleDrawerClose = () => onDrawerToggle(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setTabValue(newValue);
  };

  const openTicketDrawer = (id: string | number) => {
    dispatch(setSelectedTicket({ id: id.toString() }));
    onDrawerToggle(true);
  };

  useEffect(() => {
    dispatch(fetchTickets());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="tickets">
      <Tabs
        aria-label="filter-tabs"
        className="tickets__tabs"
        classes={{ indicator: 'tickets__tabs-indicator' }}
        value={tabValue}
        onChange={handleTabChange}
      >
        <Tab label={<TicketsTab content={'table.tab.all'} />} />
        <Tab label={<TicketsTab content={'table.tab.preVisit'} listSize={preVisitTicketsList.length} />} />
        <Tab label={<TicketsTab content={'table.tab.postVisit'} listSize={postVisitTicketsList.length} />} />
      </Tabs>
      <TableBase<TicketTableRow>
        tabProp={tabValue}
        tableHeaders={ticketsListHeaders}
        tableData={getFilteredList(tabValue, preVisitTicketsList, postVisitTicketsList, ticketsRows)}
        sortBy={'ticketId'}
        onRowClickFunction={openTicketDrawer}
        customRenderers={{
          statusDetails: (it) => (
            <TicketStatusLabel visitLabel={it.statusDetails[0]} visitStatus={it.statusDetails[1]} />
          ),
          ticketId: (it) => <span className="color-dark-disabled">{it.ticketId}</span>,
          lastUpdatedDate: (it) => (
            <span className="color-dark-disabled">{formatLastUpdated(new Date(it.lastUpdatedDate))}</span>
          ),
          phone: (it) => (
            <a className="phone" href={`tel:${it.phone}`}>
              {it.phone}
            </a>
          ),
          actions: (it) => <TicketActionsMenu it={it} />
        }}
      />
      <TicketDrawer isDrawerOpen={isDrawerOpen} handleToggleDrawer={handleDrawerClose} />
      <ScheduleVisitModal />
      <StartVisitModal />
      <EndVisitModal />
      <ChecklistScenarioModal isChecklistScenarioModal={isModalOpen} handleCloseModal={() => onModalClose()} />
      <ServicesAssignmentModal isServicesAssignmentModal={isModalOpenServicesAssignment} />
      <ConfirmAssignedServicesModal />
      <CancelAssignedServicesModal />
      <NeedRevisitModal />
      <RescheduleVisitModal />
    </div>
  );
};

export default TicketsList;
