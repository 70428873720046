import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchServiceProviders } from 'redux-store/features/service-providers/service-providers.actions';
import { ServiceProvider } from 'types/tickets.type';

export interface ServiceProvidersState {
  serviceProviders: ServiceProvider[];
  loading: boolean;
}

const initialState: ServiceProvidersState = {
  serviceProviders: [],
  loading: false
};

const serviceProvidersSlice = createSlice({
  name: 'service-providers',
  initialState,
  reducers: {
    setServices: (state, action: PayloadAction<ServiceProvider[]>) => {
      state.serviceProviders = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchServiceProviders.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchServiceProviders.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload) {
        state.serviceProviders = payload;
      }
    });
    builder.addCase(fetchServiceProviders.rejected, (state) => {
      state.loading = false;
    });
  }
});

const { reducer: serviceProvidersReducer } = serviceProvidersSlice;

export default serviceProvidersReducer;
export const { setServices } = serviceProvidersSlice.actions;
