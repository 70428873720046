import { useEffect } from 'react';
import { LocalStorageVariables } from '../../ScenarioBuilder/utils';
import { ScenarioData } from 'types/scenario-management.types';
import { setSelectedScenario } from 'redux-store/features/scenarios/scenarios.slice';
import { useAppDispatch } from 'redux-store/hooks';
import { useSelectScenarios } from 'redux-store/features/scenarios/scenarios.hooks';
import { fetchScenarios } from 'redux-store/features/scenarios/scenarios.actions';

export const useScenarioIdPersistence = (scenario: ScenarioData | undefined) => {
  const scenariosList = useSelectScenarios();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (scenariosList.length === 0) {
      dispatch(fetchScenarios());
    } else if (scenario?.id) {
      window.localStorage.setItem(LocalStorageVariables.CURRENT_SCENARIO_ID, scenario?.id.toString());
    } else {
      const localStorageCurrentId = window.localStorage.getItem(LocalStorageVariables.CURRENT_SCENARIO_ID);
      if (localStorageCurrentId !== null && scenariosList.length !== 0) {
        dispatch(setSelectedScenario({ id: localStorageCurrentId }));
      }
    }
  }, [scenariosList, scenario, dispatch]);
};
