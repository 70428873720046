import React from 'react';
import './services-assignment-modal.scss';
import ModalBase from '../../../components/ModalBase/ModalBase';
import Header from './Header';
import ReportWrapper from './ReportWrapper';
import InfoWrapper from './InfoWrapper';
import { useSelectSelectedTicket } from 'redux-store/features/tickets/tickets.hooks';
import { useServicesAssign } from './useServicesAssign';
import { useModal } from '../../common/hooks/useModal';
import { ModalTypes } from '../../common/enums/modal-types.enum';
import { setSelectedTicket } from 'redux-store/features/tickets/tickets.slice';
import { useAppDispatch } from 'redux-store/hooks';
import { fetchTickets } from 'redux-store/features/tickets/tickets.actions';

interface ChecklistScenarioModalProps {
  isServicesAssignmentModal: boolean;
}

const ServicesAssignmentModal: React.FC<ChecklistScenarioModalProps> = ({ isServicesAssignmentModal }) => {
  const {
    careGrade,
    services,
    handleAddNewService,
    handleSetCareGrade,
    handleUpdateService,
    handleRemoveService,
    handleOpenConfirmAssignServicesModal,
    isUnsavedChange
  } = useServicesAssign(isServicesAssignmentModal);
  const { onModalOpen: openCancelAssignedServices, onModalClose: closeCancelAssignedServices } = useModal(
    ModalTypes.CANCEL_ASSIGNED_SERVICES
  );
  const { onModalClose: closeModalServicesAssignment } = useModal(ModalTypes.SERVICES_ASSIGNMENT);
  const ticket = useSelectSelectedTicket();
  const dispatch = useAppDispatch();

  const handleCloseAndResetModal = () => {
    if (isUnsavedChange) {
      openCancelAssignedServices();
    } else {
      dispatch(fetchTickets());
      closeCancelAssignedServices();
      closeModalServicesAssignment();
      dispatch(setSelectedTicket({ id: '' }));
    }
  };

  return (
    <ModalBase
      isModalOpen={isServicesAssignmentModal}
      closeModal={handleCloseAndResetModal}
      isFullScreen={true}
      customHeader={true}
    >
      <div className="assignment-services-modal">
        <Header
          handleCloseModal={handleCloseAndResetModal}
          handleSaveModal={handleOpenConfirmAssignServicesModal}
          ticketId={ticket?.id as string}
          saveButtonDisabled={!isUnsavedChange || services.length === 0}
        />
        <div className="body-wrapper">
          <ReportWrapper ticket={ticket} />
          <InfoWrapper
            handleSetCareGrade={handleSetCareGrade}
            ticket={ticket}
            selectedCareGrade={careGrade}
            handleAddNewService={handleAddNewService}
            handleUpdateService={handleUpdateService}
            services={services}
            handleRemoveService={handleRemoveService}
          />
        </div>
      </div>
    </ModalBase>
  );
};

export default ServicesAssignmentModal;
