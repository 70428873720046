export enum TicketStatuses {
  OPERATOR_ASSIGNED = 'ticket.status.operatorAssigned',
  VISIT_SCHEDULED = 'ticket.status.visitScheduled',
  VISIT_RESCHEDULED = 'ticket.status.visitRescheduled',
  VISIT_IN_PROGRESS = 'ticket.status.visitInProgress',
  NEED_REVISIT = 'ticket.status.needRevisit',
  VISIT_FINISHED = 'ticket.status.visitFinished',
  WAITING_FOR_APPROVAL = 'ticket.status.waitingForApproval',
  DISAPPROVED = 'ticket.status.disapproved',
  CLOSED = 'ticket.status.closed',
  CANCELLED = 'ticket.status.cancelled'
}

export enum TicketStatusLabels {
  PRE = 'ticket.statusLabel.pre',
  ON = 'ticket.statusLabel.on',
  POST = 'ticket.statusLabel.post'
}

export enum TicketTypes {
  VISITING = 'ticket.type.visiting'
}

export enum StatusColours {
  YELLOW = '#FFC107',
  GREEN = '#009688',
  RED = '#D32F2F',
  BLUE = '#00B0FF'
}

export enum LabelColours {
  YELLOW = 'rgba(255, 193, 7, 0.08)',
  GREEN = 'rgba(0, 150, 136, 0.08)',
  RED = 'rgba(211, 47, 47, 0.08)',
  BLUE = 'rgba(0, 176, 255, 0.08)'
}
