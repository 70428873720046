import React from 'react';
import './checklist-scenario.scss';
import { IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import TextBase from '../../components/TextBase/TextBase';
import { AppPaths, useAppNavigation } from 'utils/app-routing';
import { useSelectSelectedScenario } from 'redux-store/features/scenarios/scenarios.hooks';

const ChecklistScenarioHeader = () => {
  const handleNavigateTo = useAppNavigation();
  const scenario = useSelectSelectedScenario();

  return (
    <div className="header">
      <div className="scenario-name">
        <TextBase content={'checklistScenario.scenarioName'} />
        <TextBase>{scenario?.name}</TextBase>
      </div>
      <IconButton aria-label="return-home" onClick={() => handleNavigateTo(AppPaths.SCENARIO_MANAGEMENT)}>
        <HomeIcon />
      </IconButton>
    </div>
  );
};

export default ChecklistScenarioHeader;
