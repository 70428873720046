import React from 'react';
import TextBase from 'components/TextBase/TextBase';
import ChecklistStepper from './ChecklistStepper/ChecklistStepper';
import './checklist-scenario.scss';
import ChecklistScenarioHeader from './ChecklistScenarioHeader';
import { useScenarioIdPersistence } from '../common/hooks/useScenarioIdPersistence';
import { useSelectSelectedScenario } from 'redux-store/features/scenarios/scenarios.hooks';

const ChecklistScenario = () => {
  const scenario = useSelectSelectedScenario();
  useScenarioIdPersistence(scenario);
  return (
    <div className="checklist-scenario">
      <ChecklistScenarioHeader />
      <TextBase content={'checklistScenario.title'} variant={'h4'} sx={{ display: 'flex', justifyContent: 'center' }} />
      <ChecklistStepper />
    </div>
  );
};

export default ChecklistScenario;
