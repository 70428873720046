import { useState } from 'react';

export type UseListItemOpenState = [(id: string) => boolean, (id: string) => void];
const newState = new Map();

export const useListItemOpenState = (): UseListItemOpenState => {
  const [, setOpenedItems] = useState<string>('');

  const toggleItem = (id: string): void => {
    newState.set(id, !newState.get(id) ?? false);
    setOpenedItems(new Date().toISOString());
  };

  const checkItemState = (id: string): boolean => newState.get(id) ?? false;

  return [checkItemState, toggleItem];
};
