import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TextContentPath } from 'i18n/i18n-config';
import { MessageTypes } from 'views/common/enums/message-types.enum';

export interface MessageContent {
  key: string;
  type: MessageTypes;
  content?: TextContentPath;
  freeText?: string;
  timeout?: number;
  ticketId?: string;
}

export interface SnackbarState {
  contents: MessageContent[];
}

const initialState: SnackbarState = {
  contents: []
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    addMessageToQueue: (state, action: PayloadAction<MessageContent>) => {
      (state as SnackbarState).contents.push({ ...action.payload, timeout: action.payload.timeout ?? 3000 });
    },
    removeMessageFromQueue: (state, action: PayloadAction<Pick<MessageContent, 'key'>>) => {
      state.contents = state.contents.filter((content) => content.key !== action.payload.key);
    }
  }
});

export const { addMessageToQueue, removeMessageFromQueue } = snackbarSlice.actions;
const { reducer: snackbarReducer } = snackbarSlice;

export default snackbarReducer;
