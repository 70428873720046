import { get } from 'lodash';
import { ListSelectOption } from 'types/select-option.types';
import { AppPaths } from 'utils/app-routing';
import { CustomColumns } from './table.types';

export enum OrderType {
  ASCENDING = 'asc',
  DESCENDING = 'desc'
}

export const defaultRowsInTable = { key: '6', value: 6 };
export const defaultRowOptions: ListSelectOption[] = [
  { key: '6', value: 6 },
  { key: '12', value: 12 },
  { key: '24', value: 24 }
];

export const descendingComparator = <T>(a1: T, b1: T, orderBy: string) => {
  const a = get(a1, orderBy);
  const b = get(b1, orderBy);
  if (b < a) return -1;
  if (b > a) return 1;
  return 0;
};

export const getComparator = <Key extends keyof any>(
  order: OrderType,
  orderBy: string
): ((a: { [key in Key]: any }, b: { [key in Key]: any }) => number) => {
  return order === OrderType.DESCENDING
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = <T>(items: readonly T[], comparator: (a: T, b: T) => number) => {
  const stabilizedThis = items.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const itemIncludesSearchTerm = (val: string, searchTerm: string) =>
  val.toLowerCase().includes(searchTerm.toLowerCase());

const findSearchTermInObject = <T extends Record<string, any>>(val: T[keyof T], searchTerm: string) =>
  Object.keys(val).some((key) => itemIncludesSearchTerm(val[key], searchTerm));

export const getFilteredTableItems = <T>(tableData: T[], searchTerm: string): T[] => {
  return tableData.filter((row) =>
    Object.keys(row).some((key) => {
      const val = row[key as keyof typeof row];
      return (
        (typeof val === 'string' && itemIncludesSearchTerm(val, searchTerm)) ||
        (typeof val === 'object' && findSearchTermInObject(val, searchTerm))
      );
    })
  );
};

export const updatedTableData = <T>(tableData: T[], order: OrderType, orderBy: string, searchTerm: string) => {
  return getFilteredTableItems(stableSort(tableData, getComparator(order, orderBy)), searchTerm);
};

export const getCustomColumns = (path: string): Partial<CustomColumns> | undefined => {
  switch (path.slice(1)) {
    case AppPaths.DATA_MANAGEMENT_PATIENTS:
    case AppPaths.TICKETS:
    case AppPaths.INBOX:
      return { name: 'name' };
    case AppPaths.DATA_MANAGEMENT_SERVICES:
      return { code: 'code' };
    default:
      return;
  }
};

export enum clickableColumn {
  TICKET_ID = 'ticketId',
  NAME = 'name',
  ID = 'id',
  ADDRESS = 'address',
  BIRTHDATE = 'birthdate'
}
