import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: {
      main: string;
      light: string;
      dark: string;
      contrastText: string;
    };
    primaryLight: {
      main: string;
      contrastText: string;
    };
    successLight: {
      main: string;
      contrastText: string;
    };
    errorLight: {
      main: string;
      contrastText: string;
    };
  }
  interface PaletteOptions {
    tertiary: {
      main: string;
      light: string;
      dark: string;
      contrastText: string;
    };
    primaryLight: {
      main: string;
      contrastText: string;
    };
    successLight: {
      main: string;
      contrastText: string;
    };
    errorLight: {
      main: string;
      contrastText: string;
    };
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    primaryLight: true;
    successLight: true;
    errorLight: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#3F51B5',
      dark: '#283593',
      contrastText: '#FFFFFF'
    },
    primaryLight: { main: '#283593', contrastText: '#090909' },
    secondary: {
      main: '#3F51B5',
      light: '#8C9EFF',
      dark: '#283593',
      contrastText: '#090909'
    },
    tertiary: {
      main: '#00B0FF',
      light: '#81D4FA',
      dark: '#0091EA',
      contrastText: '#090909'
    },
    success: {
      main: '#009688',
      dark: '#00796B',
      contrastText: '#FFFFFF'
    },
    successLight: {
      main: '#283593',
      contrastText: '#090909'
    },
    warning: {
      main: '#FFC107',
      light: '#FFE082',
      dark: '#FF8F00',
      contrastText: '#090909'
    },
    error: {
      main: '#D32F2F',
      dark: '#B71C1C',
      contrastText: '#FFFFFF'
    },
    errorLight: {
      main: '#283593',
      contrastText: '#090909'
    },
    common: {
      white: '#FFFFFF',
      black: '#090909'
    },
    text: {
      primary: '#090909',
      secondary: '#090909ba',
      disabled: '#0909096e'
    },
    background: {
      paper: '#FFFFFF',
      default: '#F9F9F9'
    }
  },
  spacing: [4, 8, 12, 16, 24, 32, 48, 64, 72, 112, 152],
  typography: {
    fontFamily: 'Noto Sans',
    h1: {
      fontSize: 40,
      fontFamily: 'Poppins',
      fontWeight: 600
    },
    h2: {
      fontSize: 32,
      fontFamily: 'Poppins',
      fontWeight: 600
    },
    h3: {
      fontSize: 28,
      fontFamily: 'Poppins',
      fontWeight: 600
    },
    h4: {
      fontSize: 24,
      fontFamily: 'Poppins',
      fontWeight: 600
    },
    h5: {
      fontSize: 20,
      fontFamily: 'Poppins',
      fontWeight: 600
    },
    h6: {
      fontSize: 16,
      fontFamily: 'Poppins',
      fontWeight: 600
    },
    body1: {
      fontSize: 16,
      fontWeight: 400
    },
    body2: {
      fontSize: 14,
      fontWeight: 400
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 400
    },
    caption: {
      fontSize: 12,
      fontWeight: 400
    },
    overline: {
      fontSize: 12,
      fontWeight: 500
    }
  }
});
