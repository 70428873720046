export enum ModalTypes {
  ASSIGN_CC_TO_PATIENT = 'assignCcToPatientModalOpen',
  CREATE_NEW_SCENARIO = 'createNewScenarioModalOpen',
  SCHEDULE_VISIT = 'scheduleVisitModalOpen',
  START_VISIT = 'startVisitModalOpen',
  END_VISIT = 'endVisitModalOpen',
  CHECKLIST_SCENARIO = 'checklistScenarioModal',
  SERVICES_ASSIGNMENT = 'servicesAssignment',
  APPROVE_SERVICES = 'approveServices',
  DISAPPROVE_SERVICES = 'disapproveServices',
  CONFIRM_ASSIGNED_SERVICES = 'confirmAssignedServices',
  CANCEL_ASSIGNED_SERVICES = 'cancelAssignedServices',
  RESCHEDULE_VISIT = 'rescheduleVisit',
  NEED_REVISIT = 'needRevisit'
}
