import React from 'react';
import { Divider } from '@mui/material';
import TextBase from '../../../components/TextBase/TextBase';
import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import { useTranslation } from 'react-i18next';
import { PatientDefaultValues, PatientWithId } from 'types/patient.types';

interface PatientBasicInfoProps {
  currentPatient: null | PatientWithId;
  handleOpenAssignModal: () => void;
}

const PatientBasicInfo: React.FC<PatientBasicInfoProps> = ({ currentPatient, handleOpenAssignModal }) => {
  const { t } = useTranslation();
  const id = t('patientDetails.patient_ID') + currentPatient?.id.toString();
  return (
    <>
      <div className="patient-name">
        <TextBase variant="h5">{currentPatient?.name}</TextBase>
        <TextBase variant="body1">{id}</TextBase>
      </div>
      <div className="buttons-wrapper">
        <ButtonBase
          disabled={currentPatient?.assignedCoordinator !== PatientDefaultValues.NOT_ASSIGNED}
          onClick={handleOpenAssignModal}
          content={'patientDetails.assignCoordinator'}
        />
        <div className="dot" />
        <ButtonBase disabled={true} content={'patientDetails.editDetails'} />
        <div className="dot" />
        <ButtonBase disabled={true} content={'patientDetails.deletePatient'} />
      </div>
      <Divider />
      <div className="care-grade">
        <TextBase variant="h6" content="patientDetails.careGrade" />
        <TextBase variant="body1">{currentPatient?.assignedCoordinator}</TextBase>
      </div>
    </>
  );
};

export default PatientBasicInfo;
