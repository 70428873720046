import React from 'react';
import TextBase from '../../../components/TextBase/TextBase';
import { Divider } from '@mui/material';
import ButtonBase from '../../../components/ButtonBase/ButtonBase';

interface HeaderProps {
  maxSteps: number;
  currentStep: number;
  handleCloseModal: () => void;
  ticketId: string;
}

const Header: React.FC<HeaderProps> = ({ currentStep, maxSteps, handleCloseModal, ticketId }) => {
  return (
    <div className="modal-header">
      <div className="title-wrapper">
        <div className="ticket-info">
          <TextBase
            variant={'h5'}
            fontSize={20}
            fontWeight={600}
            content={'checklistScenario.checklistScenarioTicket'}
          />
          <TextBase variant={'h5'} fontSize={20} fontWeight={600}>
            {`#${ticketId}`}
          </TextBase>
        </div>
        <ButtonBase content={'common.cancel'} variant={'outlined'} onClick={handleCloseModal} />
      </div>
      <Divider />
      <div className="progress-wrapper">
        <TextBase className="text" content={'checklistScenario.progress'}>
          :
        </TextBase>
        <div className="progress-bar">
          <div className="background-strap">
            <div className="progress-strap" style={{ transform: `translateX(${(currentStep / maxSteps) * 100}%)` }} />
          </div>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default Header;
