import { defaultMarkupAttributes, ShapesColors, ShapesDefaultNames, ShapesNames } from '../utils';
import { defaultTextAttributes } from '../utils';

export const EndAttributes = {
  type: ShapesNames.END_SHAPE,
  size: { width: 120, height: 40 },
  allowOrthogonalResize: false,
  defaultName: ShapesDefaultNames.END_SHAPE,
  attrs: {
    body: {
      fill: '#FFFFFF',
      stroke: ShapesColors.END_SHAPE,
      rx: 2,
      ry: 2,
      strokeWidth: 2
    },
    label: {
      ...defaultTextAttributes,
      textWrap: {
        text: ShapesDefaultNames.END_SHAPE,
        width: -40,
        height: -10,
        ellipsis: '...'
      }
    },
    iconBackground: {
      width: 30,
      height: 40,
      fill: ShapesColors.END_SHAPE,
      position: 'relative',
      x: 0,
      y: 1
    }
  },
  data: {
    name: ShapesDefaultNames.END_SHAPE,
    contents: null
  },
  markup: defaultMarkupAttributes
};
