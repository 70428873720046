import S3 from 'aws-sdk/clients/s3';

export const REGION = process.env.REACT_APP_AWS_REGION;

export const S3Bucket = new S3({
  params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
  region: process.env.REACT_APP_AWS_REGION
});

S3Bucket.config.update({
  accessKeyId: process.env.REACT_APP_AWS_BUCKET_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_BUCKET_SECRET_ACCESS_KEY
});
