import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ScenariosManagement.scss';

import { Button, Card, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ScenariosTable from './ScenariosTable';
import { useScenariosTableRows, validateNewScenarioName } from './utils';
import NewScenarioModal from './NewScenarioModal';
import { AppPaths } from 'utils/app-routing';
import TextBase from '../../components/TextBase/TextBase';
import { TextContentPath } from 'i18n/i18n-config';
import { useModal } from '../common/hooks/useModal';
import { ModalTypes } from '../common/enums/modal-types.enum';
import {
  useSelectScenarios,
  useSelectScenariosLoading,
  useSelectScenariosNames
} from 'redux-store/features/scenarios/scenarios.hooks';
import { NewScenarioData } from 'types/scenario-management.types';
import { setSelectedScenario } from 'redux-store/features/scenarios/scenarios.slice';
import { useAppDispatch } from 'redux-store/hooks';
import {
  createAndSelectScenario,
  deleteScenario,
  fetchScenarios
} from 'redux-store/features/scenarios/scenarios.actions';

const ScenariosManagement = () => {
  const navigate = useNavigate();
  const { onModalClose, onModalOpen } = useModal(ModalTypes.CREATE_NEW_SCENARIO);
  const [newScenarioName, setNewScenarioName] = useState<{ name: string; error: TextContentPath | '' }>({
    name: '',
    error: ''
  });
  const scenarios = useSelectScenarios();
  const scenariosFetchStatus = useSelectScenariosLoading();
  const scenariosNames = useSelectScenariosNames();
  const dispatch = useAppDispatch();
  const handleChangeNewScenarioNameInput = (inputName: string) => {
    const isInputNameUsed = scenariosNames.includes(inputName);
    const error = validateNewScenarioName(inputName, isInputNameUsed);
    setNewScenarioName({ name: inputName, error: error });
  };

  const handleAddNewScenario = async () => {
    const newScenario: NewScenarioData = {
      valid: false,
      name: newScenarioName.name,
      scenario: [],
      checklistNodes: []
    };
    dispatch(createAndSelectScenario(newScenario));
    handleCloseButton();
    navigate(`../${AppPaths.SCENARIO_BUILDER}`);
  };

  const handleEditScenario = (index: string) => {
    dispatch(setSelectedScenario({ id: index }));
    handleCloseButton();
    navigate(`../${AppPaths.SCENARIO_BUILDER}`);
  };

  const handleGoToPatientForm = (index: string) => {
    dispatch(setSelectedScenario({ id: index }));
    navigate(`../${AppPaths.CHECKLIST_SCENARIO}`);
  };

  const handleCloseButton = () => {
    onModalClose();
    setNewScenarioName({ name: '', error: '' });
  };

  const handleDeleteScenario = (key: string) => {
    dispatch(deleteScenario(key));
    dispatch(fetchScenarios());
  };

  const createdTableRows = useScenariosTableRows(scenarios, scenariosFetchStatus);

  return (
    <div className="scenarios-management">
      <Card className="card-container">
        <TextBase content={'scenarioManagement.scenariosList'} variant="h5" />
        {scenariosFetchStatus && <CircularProgress />}
        {!scenariosFetchStatus && (
          <ScenariosTable
            scenariosTableRows={createdTableRows}
            handleGoToPatientForm={handleGoToPatientForm}
            handleEditScenario={handleEditScenario}
            handleDeleteScenario={handleDeleteScenario}
          />
        )}
        <Button variant="outlined" aria-label="add" onClick={() => onModalOpen()} startIcon={<AddIcon />}>
          <TextBase content={'scenarioManagement.add'} />
        </Button>
      </Card>
      <NewScenarioModal
        handleAddNewScenario={handleAddNewScenario}
        handleChangeNewScenarioNameInput={handleChangeNewScenarioNameInput}
        newScenarioName={newScenarioName}
        closeModal={handleCloseButton}
      />
    </div>
  );
};

export default ScenariosManagement;
