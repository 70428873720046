import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import { ChevronDown, Icon } from 'react-feather';
import { ListSelectOption } from '../../types/select-option.types';
import { TextContentPath } from '../../i18n/i18n-config';
import TextBase from '../TextBase/TextBase';
import './SelectBase.scss';
import LabelBase from '../LabelBase/LabelBase';

export interface SelectListProps extends SelectProps {
  options: ListSelectOption[];
  selectedOption: ListSelectOption;
  selectName?: string;
  label?: TextContentPath;
  customChevronComponent?: Icon;
  selectPlaceholder?: TextContentPath;
}

const SelectBase: React.FC<SelectListProps> = ({
  options,
  selectedOption,
  selectName = '',
  label,
  customChevronComponent = ChevronDown,
  selectPlaceholder,
  ...props
}) => {
  return (
    <>
      <LabelBase label={label} />
      <Select
        {...props}
        className="select-base"
        name={selectName}
        value={selectedOption.value}
        IconComponent={customChevronComponent}
        displayEmpty
        renderValue={() =>
          selectPlaceholder && !selectedOption ? (
            <TextBase className="render-value" content={selectPlaceholder} />
          ) : (
            selectedOption.value
          )
        }
      >
        {options.map((option, index) => {
          return (
            <MenuItem key={index} value={option.value as string}>
              <TextBase content={option.value as TextContentPath} />
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};
export default SelectBase;
