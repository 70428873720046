import React, { FormEvent } from 'react';
import { addMinutes, differenceInMinutes } from 'date-fns';
import ModalBase from 'components/ModalBase/ModalBase';
import ButtonBase from 'components/ButtonBase/ButtonBase';
import { InitialFormState, useForm } from 'views/common/hooks/useForm';
import { ListSelectOption } from 'types/select-option.types';
import DatePickerBase from 'components/DateTimePicker/DatePickerBase';
import TimePickerBase from 'components/DateTimePicker/TimePickerBase';
import TextBase from 'components/TextBase/TextBase';
import { useModal } from 'views/common/hooks/useModal';
import { ModalTypes } from 'views/common/enums/modal-types.enum';
import { useSelectSelectedTicket } from 'redux-store/features/tickets/tickets.hooks';
import { Ticket } from 'types/tickets.type';
import { TicketStatuses, TicketStatusLabels } from 'views/common/enums/ticket.enum';
import { useTicketUpdate } from 'views/common/hooks/useTicketUpdate';
import './StartVisitModal.scss';
import { useMessages } from 'views/common/hooks/useMessage';

interface InitialModalFormState extends InitialFormState {
  visitDate: ListSelectOption | undefined;
  startHour: ListSelectOption | undefined;
}

const initialStartVisitModalState: InitialModalFormState = {
  visitDate: {
    key: 'visitDate',
    value: new Date()
  },
  startHour: {
    key: 'startHour',
    value: new Date()
  }
};

const StartVisitModal: React.FC = () => {
  const { onChange, values, onSetError, isFormInvalid } = useForm<InitialModalFormState>(initialStartVisitModalState);
  const { isModalOpen, onModalClose } = useModal(ModalTypes.START_VISIT);
  const { messages } = useMessages('startVisit');
  const selectedTicket = useSelectSelectedTicket();

  const { handleTicketUpdate } = useTicketUpdate(messages);

  const updateSelectedTicket = (currentTicket: Ticket, startDate: string, startTime: string, endTime: string) => {
    const updatedTicket: Ticket = {
      ...currentTicket,
      scheduledDate: { startTime, startDate, endTime },
      ticketStatus: TicketStatuses.VISIT_IN_PROGRESS,
      ticketStatusLabel: TicketStatusLabels.ON,
      lastUpdatedDate: new Date().toISOString()
    };
    handleTicketUpdate(updatedTicket);
  };

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    onModalClose();
    const { startHour, visitDate } = values;
    if (!!startHour && !!visitDate && !isFormInvalid(values) && selectedTicket?.scheduledDate) {
      const result = differenceInMinutes(
        new Date(selectedTicket.scheduledDate.endTime),
        new Date(selectedTicket.scheduledDate.startTime)
      );
      const newEndTime = addMinutes(new Date(startHour.value as string), result).toISOString();
      updateSelectedTicket(selectedTicket, visitDate.value as string, startHour.value as string, newEndTime);
    }
  };

  return (
    <ModalBase isModalOpen={isModalOpen} closeModal={onModalClose} headerText={'startVisit.startVisit'}>
      <form className="start-visit-modal visit-modal modal" onSubmit={(e) => onFormSubmit(e)}>
        <TextBase content={'startVisit.confirmDateAndTime'} fontWeight={600} />
        <div className="start-visit-modal__pickers">
          <div>
            <DatePickerBase
              disablePast={false}
              handleChange={onChange}
              selectedDate={values.visitDate}
              pickerName={'visitDate'}
              labelText={'startVisit.date'}
              handleError={(error) => onSetError('visitDate', error)}
            />
          </div>
          <div>
            <TimePickerBase
              selectedTime={values.startHour}
              handleChange={onChange}
              pickerName={'startHour'}
              labelText={'startVisit.time'}
              handleError={(error) => onSetError('startHour', error)}
            />
          </div>
        </div>
        <div className="modal__btn-group">
          <ButtonBase content={'common.cancel'} size={'large'} type={'button'} onClick={onModalClose} />
          <ButtonBase
            content={'common.continue'}
            variant={'contained'}
            disableElevation
            size={'large'}
            type={'submit'}
            disabled={isFormInvalid(values)}
          />
        </div>
      </form>
    </ModalBase>
  );
};

export default StartVisitModal;
