import StepInnerContent from './StepInnerContent';
import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { StepContentProps } from './StepComponent';
import { ShapesNames } from '../../../shapes/utils';

const InputStep: React.FC<StepContentProps<ShapesNames.INPUT_SHAPE>> = ({ userAnswers, ...props }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(userAnswers[0]);
  }, [userAnswers]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <StepInnerContent {...props} userAnswers={[value]}>
      <TextField
        label=""
        id="input-step-field"
        variant="outlined"
        size="small"
        value={value}
        onChange={handleInputChange}
        autoComplete="off"
      />
    </StepInnerContent>
  );
};
export default InputStep;
