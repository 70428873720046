import React, { useEffect, useState } from 'react';
import { dia } from '@clientio/rappid';
import './inspector.scss';
import { rappid } from 'services/rappid.service';
import GenericShapeDetails from './GenericShapeDetails/GenericShapeDetails';
import InspectorContainer from './InspectorContainer/InspectorContainer';

const Inspector = () => {
  const [element, setElement] = useState<dia.Element | null>(null);

  useEffect(() => {
    if (rappid) {
      rappid.setInspectorElementFunction(setElement);
    }
  }, []);

  const onCloseInspector = () => {
    setElement(null);
    document.querySelector('.navigator-container')?.classList.remove('translated');
  };

  return (
    <>
      {rappid && (
        <InspectorContainer hidden={!element}>
          {element && <GenericShapeDetails element={element} closeInspector={onCloseInspector} />}
        </InspectorContainer>
      )}
    </>
  );
};

export default Inspector;
