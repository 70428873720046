export const toolbarConfig = {
  tools: [
    {
      type: 'undo',
      name: 'undo',
      group: 'undo-redo',
      attrs: {
        button: {
          'data-tooltip': 'Undo',
          'data-tooltip-position': 'top',
          'data-tooltip-position-selector': '.toolbar-container'
        }
      },
      theme: 'modern'
    },
    {
      type: 'redo',
      name: 'redo',
      group: 'undo-redo',
      attrs: {
        button: {
          'data-tooltip': 'Redo',
          'data-tooltip-position': 'top',
          'data-tooltip-position-selector': '.toolbar-container'
        }
      },
      theme: 'modern'
    },
    {
      type: 'zoom-in',
      name: 'zoom-in',
      group: 'zoom',
      attrs: {
        button: {
          'data-tooltip': 'Zoom In',
          'data-tooltip-position': 'top',
          'data-tooltip-position-selector': '.toolbar-container'
        }
      },
      theme: 'modern'
    },
    {
      type: 'zoom-out',
      name: 'zoom-out',
      group: 'zoom',
      attrs: {
        button: {
          'data-tooltip': 'Zoom Out',
          'data-tooltip-position': 'top',
          'data-tooltip-position-selector': '.toolbar-container'
        }
      },
      theme: 'modern'
    },
    {
      type: 'button',
      name: 'tree',
      group: 'tree',
      attrs: {
        button: {
          'data-tooltip': 'Auto layout',
          'data-tooltip-position': 'top',
          'data-tooltip-position-selector': '.toolbar-container'
        }
      },
      theme: 'modern'
    }
  ]
};
