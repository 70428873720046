import { CareCoordinator } from 'types/care-coordinator.types';
import { PatientWithId } from 'types/patient.types';
import { ScenarioData } from 'types/scenario-management.types';
import { Service } from 'types/service.types';
import { ServiceProvider, Ticket } from 'types/tickets.type';

interface CRUD<T> {
  create: (item: ToStoreObject<T>) => Promise<IDBValidKey>;
  update: (item: T) => Promise<IDBValidKey>;
  read: (itemId: string | number) => Promise<T>;
  readAll: () => Promise<T[]>;
  delete: (itemId: string | number) => Promise<void>;
}

export enum DataPaths {
  SCENARIOS = 'SCENARIOS',
  PATIENTS = 'PATIENTS',
  TICKETS = 'TICKETS',
  SERVICES = 'SERVICES',
  CARE_COORDINATORS = 'CARE_COORDINATORS',
  SERVICE_PROVIDERS = 'SERVICE_PROVIDERS'
}

export type DataType<T extends DataPaths> = T extends DataPaths.SCENARIOS
  ? ScenarioData
  : T extends DataPaths.TICKETS
  ? Ticket
  : T extends DataPaths.SERVICES
  ? Service
  : T extends DataPaths.CARE_COORDINATORS
  ? CareCoordinator
  : T extends DataPaths.SERVICE_PROVIDERS
  ? StoredObject<ServiceProvider>
  : T extends DataPaths.PATIENTS
  ? PatientWithId
  : never;

export type API = { [key in DataPaths]: CRUD<DataType<key>> };
export type ToStoreObject<T extends Record<string, any>> = Omit<T, 'id'>;
export type StoredObject<T extends Record<string, any>> = T & { id: string };
