import { InitialModalFormState } from './AssignCCToPatientModal/AssignCCToPatientModal';
import { CareCoordinator } from 'types/care-coordinator.types';
import { ScenarioData } from 'types/scenario-management.types';

export const initialAssignmentFormState: InitialModalFormState = {
  patient: undefined,
  careCoordinator: undefined,
  checklistScenario: undefined
};

export const getScenarios = (scenariosList: ScenarioData[]) =>
  scenariosList.map((scenario) => ({ key: scenario.id ?? '0', value: scenario.name }));

export const getCareCoordinators = (careCoordinators: CareCoordinator[]) =>
  careCoordinators.map((cc) => ({ key: cc.id, value: `${cc.name} ${cc.surname}` }));

export const getSelectedCareCoordinator = (
  id: string,
  careCoordinators: CareCoordinator[]
): CareCoordinator | undefined => {
  return careCoordinators.find((careCoordinator) => careCoordinator.id === id) ?? undefined;
};

export const getSelectedScenario = (id: string, scenarios: ScenarioData[]): ScenarioData | undefined => {
  return scenarios.find((scenario) => scenario.id === id) ?? undefined;
};

export const phoneNumber = (contact: string | undefined) => contact?.split(' ')[0].replace(',', '');

export const emailAddress = (contact: string | undefined) => contact?.split(' ')[1].replace(',', '');

export const handleAddPropertyToArray = <T>(array: T[], object: { [key: string]: any }) => {
  return array.map((item) => {
    return { ...item, ...object };
  });
};
