import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchServices } from 'redux-store/features/services/services.actions';
import { Service } from 'types/service.types';

export interface ServicesState {
  services: Service[];
  loading: boolean;
}

const initialState: ServicesState = {
  services: [],
  loading: false
};

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setServices: (state, action: PayloadAction<Service[]>) => {
      state.services = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchServices.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchServices.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload) {
        state.services = payload;
      }
    });
    builder.addCase(fetchServices.rejected, (state) => {
      state.loading = false;
    });
  }
});

const { reducer: servicesReducer } = servicesSlice;

export default servicesReducer;
export const { setServices } = servicesSlice.actions;
