import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from 'services/data/data-broker';

export const fetchCareCoordinators = createAsyncThunk('careCoordinator/fetchCareCoordinators', async () => {
  const api = await Api;
  try {
    const careCoordinators = await api.CARE_COORDINATORS.readAll();
    return careCoordinators;
  } catch (err) {
    console.error('careCoordinator/fetchCareCoordinators error: ', err);
  }
});
