import React from 'react';
import './ServicesCard.scss';
import { Ticket } from '../../../../../types/tickets.type';
import CardBase from '../../../../../components/CardBase/CardBase';
import TextBase from '../../../../../components/TextBase/TextBase';
import { TextContentPath } from '../../../../../i18n/i18n-config';
import { Divider } from '@mui/material';
import { uniqueId } from 'lodash';

interface ServicesCardProps {
  currentTicket: Ticket;
}

const ServicesCard: React.FC<ServicesCardProps> = ({ currentTicket }) => {
  return (
    <CardBase>
      {currentTicket.services?.map((service, index) => (
        <React.Fragment key={uniqueId()}>
          {index !== 0 && <Divider className="services-divider" />}
          <div className="services-card">
            <div className="services-card__section">
              <TextBase variant="caption" fontWeight="600" className="color-dark">
                {service.id.toString()}
              </TextBase>
              <TextBase variant="caption" fontWeight="600" className="color-dark">
                {service.serviceProvider === undefined ? '' : service.serviceProvider?.clinicName.toString()}
              </TextBase>
            </div>
            <div className="services-card__section">
              <div className="services-card__section-details">
                <TextBase
                  variant="caption"
                  content={service.serviceType as unknown as TextContentPath}
                  className="color-dark-secondary"
                />
                <TextBase variant="caption" className="color-dark-disabled">
                  {service.name.toString()}
                </TextBase>
              </div>
              {service.serviceProvider === undefined ? (
                <TextBase
                  variant="caption"
                  className="color-dark-secondary"
                  content={'ticket.details.noAssignedServicesProvider'}
                />
              ) : (
                <TextBase variant="caption" className="color-dark-disabled">
                  {service.serviceProvider?.clinicAddress.toString()}
                </TextBase>
              )}
            </div>
          </div>
        </React.Fragment>
      ))}
    </CardBase>
  );
};

export default ServicesCard;
