import { View } from '@react-pdf/renderer';
import React from 'react';
import { RawDraftContentState } from 'react-draft-wysiwyg';
import PDFText from './PDFText';
import { SummaryData } from '../utils';
import ImageQuestion from './ImageQuestion';

type PdfDocumentNodeProps = {
  index: number;
  node: SummaryData;
};

const PDFDocumentNode: React.FC<PdfDocumentNodeProps> = ({ index, node }) => {
  const getQuestion = (question: RawDraftContentState | null) => {
    if (!question) return '';
    return question.blocks.map((block, index) => {
      if (block.type === 'atomic') {
        return <ImageQuestion key={`block-${index}`} question={question} index={index} />;
      } else {
        return <PDFText key={`block-${index}`}>{block.text}</PDFText>;
      }
    });
  };

  return (
    <View key={`question-${index}`} style={{ marginBottom: 10 }}>
      {getQuestion(node.question)}
      {node.answers.map((answer: string) => (
        <PDFText key={answer}>{`- ${answer}`}</PDFText>
      ))}
    </View>
  );
};

export default PDFDocumentNode;
