import { dia, highlighters, shapes, ui, layout, linkTools } from '@clientio/rappid';
import { getShapeHaloConfig, toolbarConfig } from './helpers';
import React from 'react';
import { EndShape, InputShape, MessageShape, SelectShape, StartShape } from '../shapes';
import { routerAndConnectorConfig, ShapesNames } from '../shapes/utils';
import { ScenarioCells } from '../types/scenario-management.types';
import { sourceAnchorProperties, targetAnchorProperties } from '../shapes/utils';
import { embeddedCellsPosition } from '../types/select-option.types';

export class RappidService {
  static instance: RappidService | undefined;

  public graph!: dia.Graph;

  private paper!: dia.Paper;

  private paperScroller!: ui.PaperScroller;

  private stencil!: ui.Stencil;

  private navigator!: ui.Navigator;

  private toolsToolbar!: ui.Toolbar;

  private halo!: ui.Halo | null;

  private setInspectorElement!: React.Dispatch<React.SetStateAction<dia.Element | null>>;

  private commandManager!: dia.CommandManager;

  private treeLayout!: layout.TreeLayout;

  private initialShapePosition!: dia.Point;

  private embeddedCellsPosition: embeddedCellsPosition[] = [];

  private collisionDetected!: boolean;

  static getInstance() {
    if (RappidService.instance === undefined) {
      RappidService.instance = new RappidService();
    }
    return this.instance;
  }

  public initialize(
    paperElement: Element,
    stencilElement: Element,
    navigatorElement: Element,
    toolsElement: Element
  ): void {
    this.initializePaperElements(paperElement);
    this.initializeStencil(stencilElement);
    this.initializeCommandManagerAndToolbars(toolsElement);
    this.initializeNavigator(navigatorElement);
    this.initializeTooltips();
    this.initializeEvents();
    this.initializeLayout();
  }

  public destroyDiagram(): void {
    this.paperScroller.remove();
    this.paper.remove();
    this.graph.destroy();
    this.stencil.remove();
    this.navigator.remove();
    this.toolsToolbar.remove();
  }

  public setInspectorElementFunction(setInspectorElement: React.Dispatch<React.SetStateAction<dia.Element | null>>) {
    this.setInspectorElement = setInspectorElement;
  }

  public loadScenarioCells(cells: ScenarioCells, ignoreCommandManager = false): void {
    this.commandManager.initBatchCommand();
    this.paper.freeze();
    this.graph.clear();
    this.graph.addCells(cells, { ignoreCommandManager });
    this.paper.unfreeze();
    this.positionContentOnPaper();
    this.commandManager.storeBatchCommand();
  }

  public loadScenario(cells: ScenarioCells): void {
    const cellsToAdd = cells.length ? cells : [new StartShape()];
    this.loadScenarioCells(cellsToAdd, true);
  }

  private initializePaperElements(paperElement: Element): void {
    const graph = (this.graph = new dia.Graph({}, { cellNamespace: shapes }));

    const paper = (this.paper = new dia.Paper({
      width: 1000,
      height: 1000,
      gridSize: 10,
      model: graph,
      defaultLink: new shapes.standard.Link(),
      background: { color: '#f4f6fd' },
      drawGrid: { name: 'mesh', args: { color: 'lightgrey', scaleFactor: 2 } },
      markAvailable: true,
      cellViewNamespace: shapes,
      magnetThreshold: 20,
      linkPinning: false,
      validateConnection: this.isConnectionValid,
      ...routerAndConnectorConfig
    }));

    const paperScroller = (this.paperScroller = new ui.PaperScroller({
      paper,
      autoResizePaper: true,
      cursor: 'grab',
      contentOptions: (paperScroller: ui.PaperScroller) => {
        const { height, width } = paperScroller.getVisibleArea();
        return {
          padding: {
            bottom: height,
            top: height,
            left: width,
            right: width
          },
          allowNewOrigin: 'any'
        };
      }
    }));
    paperElement.appendChild(paperScroller.el);
    this.paperScroller.render().center();

    this.positionContentOnPaper();
  }

  private initializeStencil(stencilElement: Element): void {
    const stencil = (this.stencil = new ui.Stencil({
      paper: this.paperScroller,
      dropAnimation: true,
      width: 250,
      layout: { columns: 1, columnWidth: 230, horizontalAlign: 'middle', rowHeight: 60, rowGap: 10 },
      groups: this.getStencilGroups(),
      canDrag: (cellView: dia.CellView) => this.validateDragging(cellView)
    }));

    stencilElement.appendChild(stencil.el);
    stencil.render();
    this.stencil.load(this.getStencilShapes());
  }

  private getStencilGroups() {
    return {
      standard: { index: 1, label: 'Standard nodes' }
    };
  }

  private getStencilShapes() {
    return {
      standard: [new MessageShape(), new SelectShape(), new InputShape(), new EndShape()]
    };
  }

  private initializeCommandManagerAndToolbars(toolsElement: Element): void {
    const commandManager = (this.commandManager = new dia.CommandManager({
      graph: this.graph,
      cmdBeforeAdd: (name, cell, graph, options) => !options.ignoreCommandManager
    }));

    const toolsToolbar = (this.toolsToolbar = new ui.Toolbar({
      tools: toolbarConfig.tools,
      autoToggle: true,
      references: {
        paperScroller: this.paperScroller,
        commandManager
      }
    }));
    toolsElement.appendChild(toolsToolbar.el);
    toolsToolbar.render();
  }

  private initializeNavigator(navigatorElement: Element): void {
    const navigator = (this.navigator = new ui.Navigator({
      paperScroller: this.paperScroller,
      width: 300,
      height: 200,
      padding: 10,
      zoomOptions: { max: 2, min: 0.2 },
      paperOptions: {
        ...routerAndConnectorConfig
      }
    }));
    navigatorElement.appendChild(navigator.el);
    navigator.render();
  }

  private createShapeHalo(cellView: dia.CellView) {
    const cellType = cellView.model.get('type');
    const cellId = cellView.model.get('id');
    const links: dia.Cell[] = this.graph.getLinks();
    const sourcesId = links.map((link) => link.attributes.source.id);
    const handlesConfig = getShapeHaloConfig(cellType, sourcesId.includes(cellId));
    this.halo = new ui.Halo({
      cellView,
      boxContent: '',
      handles: handlesConfig
    });
    this.halo.on('action:link:add', () => {
      this.closeShapeHalo();
      this.createShapeHalo(cellView);
    });
    this.halo.render();
  }

  private closeShapeHalo(): void {
    if (this.halo) {
      this.halo.remove();
      this.halo = null;
    }
  }

  private initializeTooltips(): ui.Tooltip {
    return new ui.Tooltip({
      rootTarget: document.body,
      target: '[data-tooltip]',
      direction: ui.Tooltip.TooltipArrowPosition.Auto,
      padding: 10
    });
  }

  private positionContentOnPaper(): void {
    this.paperScroller.positionContent('top', { padding: 10 });
  }

  private initializeEvents(): void {
    this.graph.on('add', (cell: dia.Cell, graph: dia.Graph, opt: { [key: string]: any }) => {
      this.onGraphAdd(cell, opt);
    });
    this.graph.on('remove', (cell: dia.Cell) => this.onGraphRemove(cell));
    this.paper.on('blank:pointerdown', (e: dia.Event) => this.onBlankPaperPointerdown(e));
    this.paper.on('element:pointerdown', (elementView: dia.ElementView) => {
      this.onElementPointerdown(elementView);
      this.initialShapePosition = elementView.model.position();
    });
    this.graph.on('change:position', (element: dia.Element) => {
      this.canShapeBePlaced(element);
    });
    this.paper.on('element:pointerup', (elementView: dia.ElementView) => {
      if (this.collisionDetected) this.redoShapeMovement(elementView.model);
    });
    this.paper.on('cell:mousewheel', (cellView: dia.CellView, e: dia.Event, ox: number, oy: number, delta: number) => {
      this.onMousewheel(e, ox, oy, delta);
    });
    this.paper.on('blank:mousewheel', (e: dia.Event, ox: number, oy: number, delta: number) => {
      this.onMousewheel(e, ox, oy, delta);
    });
    this.paper.on(`link:connect`, (linkView) => {
      this.anchorTargetSetup(linkView.model);
      this.closeShapeHalo();
    });
    this.paper.on('link:mouseleave', (linkView: dia.LinkView) => linkView.removeTools());
    this.paper.on('link:mouseenter', (linkView: dia.LinkView) => this.addLinkTools(linkView));
    this.graph.on('change:data', (element: dia.Element) => this.onElementDataChange(element));
    this.toolsToolbar.on('tree:pointerclick', () => {
      this.updateLayout();
    });
  }

  private onElementDataChange(element: dia.Element) {
    this.commandManager.initBatchCommand();
    this.closeInspector();
    const elementView = element.findView(this.paper) as dia.ElementView;
    this.onElementPointerdown(elementView);
    element.attr('label/textWrap/text', element.prop('data/name'), { ignoreCommandManager: true });
    if (element instanceof SelectShape) {
      element.updateOptions();
    }
    this.commandManager.storeBatchCommand();
  }

  private validateDragging(cellView: dia.CellView): boolean {
    if (cellView.model.prop('type') === ShapesNames.END_SHAPE) {
      const existEndShapeOnPaper = this.graph
        .getElements()
        .find((element) => element.prop('type') === ShapesNames.END_SHAPE);
      return !existEndShapeOnPaper;
    }
    return true;
  }

  private isConnectionValid = (cellViewS: dia.CellView, magnetS: SVGElement, cellViewT: dia.CellView): boolean => {
    const links: dia.Cell[] = this.graph
      .getCells()
      .filter((cell) => cell.get('type') === ShapesNames.STANDARD_LINK_SHAPE);
    const idCellViewS = cellViewS.model.get('id');
    const idCellViewT = cellViewT.model.get('id');
    const targetsId = links.map((link) => link.attributes.target.id);
    const unacceptableCellTypes = [ShapesNames.STANDARD_LINK_SHAPE, ShapesNames.START_SHAPE];
    const revertLink = links.filter((link) => {
      return link.attributes.target.id === idCellViewS && link.attributes.source.id === idCellViewT;
    });

    if (
      !unacceptableCellTypes.includes(cellViewT.model.get('type')) &&
      !targetsId.includes(idCellViewT) &&
      idCellViewT !== idCellViewS &&
      revertLink.length === 0
    ) {
      return true;
    }
    return false;
  };

  private onMousewheel(evt: dia.Event, ox: number, oy: number, delta: number): void {
    evt.preventDefault();
    this.paperScroller.zoom(delta * 0.2, { min: 0.2, max: 5, grid: 0.2, ox, oy });
  }

  private onBlankPaperPointerdown(event: dia.Event): void {
    this.paper.removeTools();
    this.closeShapeHalo();
    this.paperScroller.startPanning(event);
    this.clearHighlightedShapes();
    this.closeInspector();
  }

  private onElementPointerdown(elementView: dia.ElementView): void {
    if (elementView.model.get('type') === ShapesNames.SELECT_OPTION_SHAPE) return;
    this.openInspector(elementView.model);
    this.paper.removeTools();
    this.clearHighlightedShapes();
    this.createShapeHalo(elementView);
    this.highlightShape(elementView);
  }

  private onGraphAdd(cell: dia.Cell, opt: { [key: string]: any }): void {
    const isNotSelectOptionShape = cell.prop('type') !== ShapesNames.SELECT_OPTION_SHAPE;
    if (cell.isElement() && isNotSelectOptionShape && opt.stencil) {
      const view = cell.findView(this.paper) as dia.ElementView;
      this.onElementPointerdown(view);
    }

    if (cell.isLink()) {
      this.anchorSourceSetup(cell as dia.Link);
    }
  }

  private onGraphRemove(cell: dia.Cell): void {
    if (cell.isElement() && cell.prop('type') !== ShapesNames.SELECT_OPTION_SHAPE) {
      this.closeInspector();
    }
  }

  private closeInspector(): void {
    this.setInspectorElement(null);
    this.navigator.el.parentElement?.classList.remove('translated');
  }

  private openInspector(element: dia.Element): void {
    this.setInspectorElement(element);
    this.navigator.el.parentElement?.classList.add('translated');
  }

  private addLinkTools(linkView: dia.LinkView): void {
    const removeTool = new linkTools.Remove({ distance: '50%' });
    const toolsView = new dia.ToolsView({
      name: 'basic-tools',
      tools: [removeTool]
    });

    linkView.addTools(toolsView);
  }

  private clearHighlightedShapes(): void {
    this.graph.getCells().forEach((cell: dia.Cell) => {
      const elementView = cell.findView(this.paper);
      highlighters.mask.remove(elementView, 'selected-element-highlight');
    });
  }

  private highlightShape(elementView: dia.ElementView): void {
    highlighters.mask.add(elementView, { selector: 'root' }, 'selected-element-highlight', {
      deep: true,
      padding: 0,
      attrs: {
        stroke: '#2ff7ab',
        'stroke-width': 2,
        'stroke-dasharray': 5
      }
    });
  }

  private initializeLayout(): void {
    this.treeLayout = new layout.TreeLayout({
      graph: this.graph,
      parentGap: 50,
      siblingGap: 40,
      direction: 'B',
      updateVertices: null
    });
  }

  private updateLayout(): void {
    this.commandManager.initBatchCommand();
    this.treeLayout.layout();
    this.commandManager.storeBatchCommand();
  }

  private anchorSourceSetup(link: dia.Link): void {
    const source = link.getSourceElement();
    source && link.source(source, { anchor: sourceAnchorProperties }, { ignoreCommandManager: true });
  }

  private anchorTargetSetup(link: dia.Link): void {
    const target = link.getTargetElement();
    target && link.target(target, { anchor: targetAnchorProperties }, { ignoreCommandManager: true });
  }

  private canShapeBePlaced(movedElement: dia.Element): void {
    if (movedElement.getParentCell()) return;
    this.collisionDetected = false;
    const allElements = this.graph.getElements();
    const scaledBBox = movedElement.getBBox().inflate(30);
    const embeddedCellsIds = movedElement.getEmbeddedCells().map((item) => item.get('id'));
    allElements.forEach((element) => {
      if (movedElement.id === element.id || embeddedCellsIds.includes(element.get('id'))) return;
      const elementBBox = element.getBBox();
      if (scaledBBox.intersect(elementBBox)) {
        this.collisionDetected = true;
      }
    });
    if (!this.collisionDetected) {
      this.initialShapePosition = movedElement.position();
      this.getEmbeddedShapesPositions(movedElement);
    }
  }

  private getEmbeddedShapesPositions(element: dia.Element): void {
    this.embeddedCellsPosition = [];
    const embeddedCells = element.getEmbeddedCells();
    embeddedCells.forEach((embeddedCell) => {
      this.embeddedCellsPosition.push({ id: embeddedCell.get('id'), position: embeddedCell.position() });
    });
  }

  private redoShapeMovement(element: dia.Element): void {
    element.set('position', this.initialShapePosition);
    if (element.get('type') === ShapesNames.SELECT_SHAPE) {
      (element as SelectShape).updatePositions();
    }
  }
}

export default RappidService;

export const rappid = RappidService.getInstance();
