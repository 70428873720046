import React from 'react';
import TableBase from 'components/TableBase/TableBase';
import TextBase from 'components/TextBase/TextBase';
import { useServices } from 'redux-store/features/services/services.hooks';
import { TableHeader } from 'components/TableBase/table.types';

export const servicesListHeaders: TableHeader[] = [
  {
    column: 'table.column.code',
    id: 'code',
    enableSort: true
  },
  {
    column: 'table.column.serviceType',
    id: 'serviceType',
    enableSort: true
  },
  {
    column: 'table.column.name',
    id: 'name',
    enableSort: true
  }
];

const ServicesList = () => {
  const servicesList = useServices();
  return (
    <>
      <TableBase
        tableHeaders={servicesListHeaders}
        tableData={servicesList}
        sortBy={'code'}
        addButtonText={'table.addService'}
        customRenderers={{
          serviceType: (it) => <TextBase variant="caption">{it.serviceType}</TextBase>
        }}
      />
    </>
  );
};

export default ServicesList;
