import React from 'react';
import './ScenariosManagement.scss';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { ScenariosTableProps } from 'types/scenario-management.types';
import TextBase from './../../components/TextBase/TextBase';

const ScenariosTable: React.FC<ScenariosTableProps> = ({
  scenariosTableRows,
  handleGoToPatientForm,
  handleEditScenario,
  handleDeleteScenario
}) => {
  return (
    <TableContainer style={{ maxHeight: '80%' }} component={Paper}>
      <Table stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <TextBase content={'scenarioManagement.scenarioName'} />
            </TableCell>
            <TableCell align="right">
              <TextBase content={'scenarioManagement.patientForm'} />
            </TableCell>
            <TableCell align="right">
              <TextBase content={'scenarioManagement.edit'} />
            </TableCell>
            <TableCell align="right">
              <TextBase content={'scenarioManagement.delete'} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {scenariosTableRows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                <TextBase>{row.name}</TextBase>
              </TableCell>
              <TableCell align="right">
                <IconButton
                  aria-label="patient-form"
                  onClick={() => handleGoToPatientForm(row.key)}
                  disabled={!row.valid}
                >
                  {row.patientFormIcon}
                </IconButton>
              </TableCell>
              <TableCell align="right">
                <IconButton aria-label="edit" onClick={() => handleEditScenario(row.key)}>
                  {row.editIcon}
                </IconButton>
              </TableCell>
              <TableCell align="right">
                <IconButton aria-label="delete" onClick={() => handleDeleteScenario(row.key)}>
                  {row.deleteIcon}
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ScenariosTable;
