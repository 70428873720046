import React, { ChangeEvent } from 'react';
import { uniqueId } from 'lodash';
import ModalBase from 'components/ModalBase/ModalBase';
import ButtonBase from 'components/ButtonBase/ButtonBase';
import TextBase from 'components/TextBase/TextBase';
import { useModal } from 'views/common/hooks/useModal';
import { ModalTypes } from 'views/common/enums/modal-types.enum';
import TextFieldBase from 'components/TextFieldBase/TextFieldBase';
import { InitialFormState, useForm } from 'views/common/hooks/useForm';
import { ListSelectOption } from 'types/select-option.types';
import './DisapproveReportModal.scss';
import { Ticket } from 'types/tickets.type';
import { TicketStatuses } from 'views/common/enums/ticket.enum';
import { useSelectSelectedTicket } from 'redux-store/features/tickets/tickets.hooks';
import { useTicketUpdate } from 'views/common/hooks/useTicketUpdate';
import { updatedMemo } from 'views/Tickets/tickets.utils';
import { useMessages } from 'views/common/hooks/useMessage';

const disapprovedTicket = (ticket: Ticket, note: string): Ticket => {
  return {
    ...ticket,
    ticketStatus: TicketStatuses.DISAPPROVED,
    lastUpdatedDate: new Date().toISOString(),
    memo: updatedMemo(ticket, note)
  };
};

interface InitialDisapproveReportFormState extends InitialFormState {
  disapprovalReason: ListSelectOption | undefined;
}

const initialDisapproveReportModalState: InitialDisapproveReportFormState = {
  disapprovalReason: {
    key: 'disapprovalReason',
    value: ''
  }
};

const DisapproveReportModal: React.FC = () => {
  const { isModalOpen, onModalClose } = useModal(ModalTypes.DISAPPROVE_SERVICES);
  const { onChange, values, isFormInvalid, handleResetForm } = useForm<InitialDisapproveReportFormState>(
    initialDisapproveReportModalState
  );
  const { messages } = useMessages('disapproveReport');
  const selectedTicket = useSelectSelectedTicket();
  const { handleTicketUpdate } = useTicketUpdate(messages);

  const onReportDisapprove = () => {
    onModalClose();
    if (!selectedTicket || isFormInvalid(values)) return;
    handleTicketUpdate(disapprovedTicket(selectedTicket, values.disapprovalReason?.value as string));
    handleResetForm();
  };

  const updateReasonField = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange('disapprovalReason', { key: values.disapprovalReason?.key ?? uniqueId(), value: e.target.value });
  };

  return (
    <ModalBase isModalOpen={isModalOpen} closeModal={onModalClose} headerText={'inbox.disapproveReport'}>
      <form className="disapprove-report-modal modal" onSubmit={onReportDisapprove}>
        <TextBase content={'inbox.disapproveReportInfo'} variant="body1" className="color-dark-secondary" />
        <div className="disapprove-report-modal__memo">
          <TextFieldBase
            required
            multiline
            minRows="2"
            maxRows="4"
            labelText="inbox.reasonForDisapproval"
            placeholderText="inbox.typeACommentHere"
            value={values.disapprovalReason?.value}
            onChange={updateReasonField}
          />
        </div>
        <div className="modal__btn-group">
          <ButtonBase onClick={onModalClose} content={'common.cancel'} size={'large'} type={'button'} />
          <ButtonBase
            disabled={!values.disapprovalReason?.value}
            content={'common.confirm'}
            size={'large'}
            type={'submit'}
            variant={'contained'}
          />
        </div>
      </form>
    </ModalBase>
  );
};

export default DisapproveReportModal;
