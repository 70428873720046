import React from 'react';
import './../checklist-scenario-modal.scss';
import TextBase from '../../../../components/TextBase/TextBase';
import TextFieldBase from '../../../../components/TextFieldBase/TextFieldBase';
import { AnswerStepProps } from '../utils';

const InputStep: React.FC<AnswerStepProps> = ({ message, handleChangeStepAnswer, id, currentValue }) => {
  return (
    <div className="input-step">
      <TextBase variant={'h5'}>{message}</TextBase>
      <TextFieldBase
        defaultValue={currentValue}
        onChange={(e) => handleChangeStepAnswer(id, { question: message as string, answer: { value: e.target.value } })}
      />
    </div>
  );
};

export default InputStep;
