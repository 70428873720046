import React from 'react';
import { RawDraftContentState } from 'react-draft-wysiwyg';
import { Image } from '@react-pdf/renderer';
import { getImageQuestionData } from '../utils';

type ImageQuestionType = {
  question: RawDraftContentState;
  index: number;
};

const ImageQuestion: React.FC<ImageQuestionType> = ({ question, index }) => {
  const { src, styles } = getImageQuestionData(question, index);
  return <Image src={src} style={{ ...styles, marginVertical: 10, maxWidth: '100%' }} />;
};

export default ImageQuestion;
