import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from 'services/data/data-broker';

export const fetchServiceProviders = createAsyncThunk('service-providers/fetchServiceProviders', async () => {
  const api = await Api;
  try {
    const serviceProviders = await api.SERVICE_PROVIDERS.readAll();
    return serviceProviders;
  } catch (err) {
    console.error('service-providers/fetchServiceProviders error: ', err);
  }
});
