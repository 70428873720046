import { ModalTypes } from '../enums/modal-types.enum';
import { useAppDispatch } from '../../../redux-store/hooks';
import { setModalClose, setModalOpen } from '../../../redux-store/features/modal/modal.slice';
import { useSelector } from 'react-redux';
import { selectModalState } from '../../../redux-store/features/modal/modal.selectors';

export const useModal = (modalType: ModalTypes) => {
  const dispatch = useAppDispatch();

  const onModalOpen = () => {
    dispatch(setModalOpen(modalType));
  };

  const onModalClose = () => {
    dispatch(setModalClose(modalType));
  };

  const isModalOpen = useSelector(selectModalState(modalType));

  return { onModalOpen, onModalClose, isModalOpen };
};
