import i18n from 'i18next';
import { initReactI18next, TFunction } from 'react-i18next';
import en from './translations/en.json';

const resources = {
  en
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  defaultNS: 'translation',
  fallbackLng: 'en'
});

export type TextContentPath = Parameters<TFunction<'translation'>>[0];

export default i18n;
