import { useEffect, useState } from 'react';
import Api from 'services/data/data-broker';
import { API } from 'services/data/types';

export const useContextApi = () => {
  const [api, setApi] = useState<API>();

  useEffect(() => {
    const getApi = async () => {
      const api = await Api;
      setApi(api);
    };
    getApi();
  }, []);

  return api;
};
