export enum SupportGrades {
  NSG1 = 'NSG1',
  NSG2 = 'NSG2',
  NSG3 = 'NSG3',
  NSG4 = 'NSG4'
}

export enum NursingGrades {
  NNCG1 = 'NNCG1',
  NNCG2 = 'NNCG2',
  NNCG3 = 'NNCG3',
  NNCG4 = 'NNCG4',
  NNCG5 = 'NNCG5'
}
