import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { createScenario, deleteScenario, fetchScenarios, updateScenario } from './scenarios.actions';
import { ScenarioData } from 'types/scenario-management.types';

export const scenariosAdapter = createEntityAdapter<ScenarioData>({
  selectId: (scenario) => scenario.id,
  sortComparer: (a, b) => a.id.localeCompare(b.id)
});

export type ScenariosState = EntityState<ScenarioData> & {
  selectedScenario: ScenarioData | undefined;
  loading: boolean;
};

const initialState: ScenariosState = scenariosAdapter.getInitialState({ selectedScenario: undefined, loading: false });

const scenariosSlice = createSlice({
  name: 'scenarios',
  initialState,
  reducers: {
    addScenario: (state, action: PayloadAction<ScenarioData>) => {
      scenariosAdapter.setOne(state as ScenariosState, action.payload);
    },
    setScenarios: (state, action: PayloadAction<ScenarioData[]>) => {
      scenariosAdapter.setAll(state as ScenariosState, action.payload);
    },
    setSelectedScenario: (state, action: PayloadAction<{ id: string }>) => {
      const scenario = scenariosAdapter.getSelectors().selectById(state as ScenariosState, action.payload.id);
      state.selectedScenario = scenario;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScenarios.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchScenarios.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload) {
        scenariosAdapter.setAll(state as ScenariosState, payload);
      }
    });
    builder.addCase(fetchScenarios.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateScenario.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload) {
        scenariosAdapter.updateOne(state as ScenariosState, { id: payload.id, changes: { ...payload } });
      }
    });
    builder.addCase(updateScenario.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createScenario.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload) {
        scenariosAdapter.setOne(state as ScenariosState, payload);
      }
    });
    builder.addCase(deleteScenario.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload) {
        scenariosAdapter.removeOne(state as ScenariosState, payload);
      }
    });
  }
});

const { reducer: scenariosReducer } = scenariosSlice;

export default scenariosReducer;
export const { setSelectedScenario, addScenario, setScenarios } = scenariosSlice.actions;
