import React from 'react';
import './selectDetails.scss';
import { Select, FormControl, SelectChangeEvent } from '@mui/material';
import ListOptionsWrapper from './ListOptionsWrapper';
import CardOptionsWrapper from './CardOptionsWrapper';
import { CardSelectOption, ListSelectOption, SelectType } from 'types/select-option.types';

interface SelectDetailsProps {
  listOptions: ListSelectOption[];
  setListOptions: Function;
  cardOptions: CardSelectOption[];
  setCardOptions: Function;
  selectType: string;
  setSelectType: Function;
}

const SelectDetails: React.FC<SelectDetailsProps> = ({
  listOptions,
  setListOptions,
  cardOptions,
  setCardOptions,
  selectType,
  setSelectType
}) => {
  const handleAddListOption = () => {
    setListOptions([...listOptions, { value: '', key: '' }]);
  };

  const handleAddCardOption = () => {
    setCardOptions([...cardOptions, { value: '', key: '', url: '' }]);
  };

  const handleRemoveCardItem = (removeIndex: number) => {
    let updatedCard = cardOptions.filter((item, optionIndex) => {
      return removeIndex !== optionIndex;
    });
    setCardOptions(updatedCard);
  };

  const handleRemoveListItem = (removeIndex: number) => {
    let updatedList = listOptions.filter((item, optionIndex) => {
      return removeIndex !== optionIndex;
    });
    setListOptions(updatedList);
  };

  const handleSetSelectType = (event: SelectChangeEvent<string>) => {
    setSelectType(event.target.value);
  };

  const handleChangeListOptionTextField = (textFieldInput: string, index: number, newInputValue: string) => {
    const updatedListOption = listOptions;
    updatedListOption[index] = { ...listOptions[index], [textFieldInput]: newInputValue };
    setListOptions(updatedListOption);
  };

  const handleChangeCardTextField = (textFieldInput: string, index: number, newInputValue: string) => {
    const updatedCards = cardOptions;
    updatedCards[index] = { ...updatedCards[index], [textFieldInput]: newInputValue };
    setCardOptions(updatedCards);
  };

  return (
    <div className="select-option-container">
      <FormControl>
        <Select native value={selectType} onChange={handleSetSelectType}>
          <option value={SelectType.LIST}>list</option>
          <option value={SelectType.CARD}>card</option>
        </Select>
      </FormControl>
      {selectType === SelectType.LIST && (
        <ListOptionsWrapper
          listOptions={listOptions}
          handleChangeListOptionTextField={handleChangeListOptionTextField}
          handleRemoveListItem={handleRemoveListItem}
          handleAddListOption={handleAddListOption}
        />
      )}
      {selectType === SelectType.CARD && (
        <CardOptionsWrapper
          cardOptions={cardOptions}
          handleChangeCardTextField={handleChangeCardTextField}
          handleRemoveCardItem={handleRemoveCardItem}
          handleAddCardOption={handleAddCardOption}
        />
      )}
    </div>
  );
};

export default SelectDetails;
