import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import { StandardTextFieldProps } from '@mui/material/TextField/TextField';
import { TextContentPath } from 'i18n/i18n-config';
import LabelBase from '../LabelBase/LabelBase';
import './TextFieldBase.scss';

interface TextFieldBaseProps extends StandardTextFieldProps {
  labelText?: TextContentPath;
  placeholderText?: TextContentPath;
  error?: boolean;
  helperText?: string;
  startIcon?: React.ReactNode;
}

const TextFieldBase: React.FC<TextFieldBaseProps> = ({
  labelText,
  placeholderText,
  error,
  helperText,
  startIcon,
  ...materialProps
}) => {
  const { t } = useTranslation();
  const placeholder = placeholderText ? t(placeholderText) : '';

  return (
    <>
      <LabelBase label={labelText} />
      <TextField
        className="text-field-base"
        autoComplete="off"
        placeholder={placeholder as string}
        error={error}
        helperText={helperText}
        InputProps={{ startAdornment: startIcon }}
        {...materialProps}
      />
    </>
  );
};

export default TextFieldBase;
