import { uniqueId } from 'lodash';
import { TableHeader } from 'components/TableBase/table.types';
import { Ticket, TicketTableRow } from 'types/tickets.type';
import { LabelColours, StatusColours, TicketStatuses, TicketStatusLabels } from 'views/common/enums/ticket.enum';
import { TextContentPath } from 'i18n/i18n-config';
import { handleAddPropertyToArray } from 'views/DataManagement/Patients/patients.utils';
import { CareCoordinator } from 'types/care-coordinator.types';
import { AppPaths } from 'utils/app-routing';
import { ServiceTypes } from 'services/data/indexedDB/mock/raw-data/serivces';

export const ticketsListHeaders: TableHeader[] = [
  {
    column: 'table.column.id',
    id: 'ticketId',
    enableSort: true
  },
  {
    column: 'table.column.name',
    id: 'name',
    enableSort: true
  },
  {
    column: 'table.column.address',
    id: 'address',
    enableSort: true
  },
  {
    column: 'table.column.phone',
    id: 'phone',
    enableSort: true
  },
  {
    column: 'table.column.status',
    id: 'statusDetails',
    enableSort: true
  },
  {
    column: 'table.column.updated',
    id: 'lastUpdatedDate',
    enableSort: true
  },
  {
    column: 'table.column.actions',
    id: 'actions',
    enableSort: false
  }
];

export const getTicketRows = (tickets: Ticket[]): TicketTableRow[] => {
  return handleAddPropertyToArray(tickets, { actions: undefined }).map((ticket) => {
    return {
      id: ticket.id,
      ticketId: `#${ticket.id}`,
      name: ticket.patientAssigned.name,
      address: ticket.patientAssigned.address,
      phone: ticket.patientAssigned.contact.split(' ')[0].replace(',', ''),
      statusDetails: [ticket.ticketStatusLabel, ticket.ticketStatus],
      lastUpdatedDate: ticket.lastUpdatedDate,
      actions: ticket.actions
    };
  });
};

export const getStatusColor = (visitStatus: TicketStatuses) => {
  switch (visitStatus) {
    case TicketStatuses.OPERATOR_ASSIGNED:
    case TicketStatuses.VISIT_SCHEDULED:
    case TicketStatuses.VISIT_RESCHEDULED:
    case TicketStatuses.NEED_REVISIT:
      return StatusColours.YELLOW;
    case TicketStatuses.VISIT_FINISHED:
    case TicketStatuses.WAITING_FOR_APPROVAL:
    case TicketStatuses.CLOSED:
      return StatusColours.GREEN;
    case TicketStatuses.DISAPPROVED:
    case TicketStatuses.CANCELLED:
      return StatusColours.RED;
    case TicketStatuses.VISIT_IN_PROGRESS:
      return StatusColours.BLUE;
    default:
      return StatusColours.BLUE;
  }
};

export const getStatusBackground = (visitLabel: TextContentPath, visitStatus: TicketStatuses) => {
  const isRedStatus = visitStatus === TicketStatuses.DISAPPROVED || visitStatus === TicketStatuses.CANCELLED;
  switch (visitLabel) {
    case TicketStatusLabels.PRE:
      return LabelColours.YELLOW;
    case TicketStatusLabels.ON:
      return LabelColours.BLUE;
    case TicketStatusLabels.POST:
      return isRedStatus ? LabelColours.RED : LabelColours.GREEN;
    default:
      return LabelColours.YELLOW;
  }
};

export const getFilteredList = (
  tabIndex: number,
  preVisitTicketsList: TicketTableRow[],
  postVisitTicketsList: TicketTableRow[],
  ticketsList: TicketTableRow[]
): TicketTableRow[] => {
  switch (tabIndex) {
    case 1:
      return preVisitTicketsList;
    case 2:
      return postVisitTicketsList;
    default:
      return ticketsList;
  }
};

// to remove in the future, for now generates 'id' in '000000001' string format
export const generateCustomId = (ticketsListLength: number) => (ticketsListLength + 1).toString().padStart(9, '0');

export const enum TicketActionsMenuItems {
  CHECKLIST_SCENARIO = 'ticket.action.checklistScenario',
  END_VISIT = 'ticket.action.endVisit',
  START_VISIT = 'ticket.action.startVisit',
  RESCHEDULE_VISIT = 'ticket.action.rescheduleVisit',
  SCHEDULE_VISIT = 'ticket.action.scheduleVisit',
  ASSIGN_SERVICES = 'ticket.action.assignServices',
  EDIT_ASSIGNED_SERVICES = 'ticket.action.editServices',
  APPROVE_SERVICES = 'ticket.action.approveServices',
  DISAPPROVE_SERVICES = 'ticket.action.disapproveServices',
  NEED_REVISIT = 'ticket.action.needRevisit'
}

export const enum TicketActionsMenuItemsTypes {
  CHECKLIST_SCENARIO = 'checklist-scenario',
  END_VISIT = 'end-visit',
  START_VISIT = 'start-visit',
  RESCHEDULE_VISIT = 'reschedule-visit',
  SCHEDULE_VISIT = 'schedule-visit',
  ASSIGN_SERVICES = 'assign-services',
  EDIT_ASSIGNED_SERVICES = 'edit-assigned-services',
  APPROVE_SERVICES = 'approve-services',
  DISAPPROVE_SERVICES = 'disapprove-services',
  NEED_REVISIT = 'need-revisit'
}

export const MenuItems = [
  {
    displayRequirements: [TicketStatuses.VISIT_IN_PROGRESS],
    name: TicketActionsMenuItems.CHECKLIST_SCENARIO,
    type: TicketActionsMenuItemsTypes.CHECKLIST_SCENARIO
  },
  {
    displayRequirements: [TicketStatuses.VISIT_IN_PROGRESS],
    name: TicketActionsMenuItems.END_VISIT,
    type: TicketActionsMenuItemsTypes.END_VISIT
  },
  {
    displayRequirements: [TicketStatuses.VISIT_IN_PROGRESS],
    name: TicketActionsMenuItems.NEED_REVISIT,
    type: TicketActionsMenuItemsTypes.NEED_REVISIT
  },
  {
    displayRequirements: [TicketStatuses.VISIT_SCHEDULED, TicketStatuses.VISIT_RESCHEDULED],
    name: TicketActionsMenuItems.START_VISIT,
    type: TicketActionsMenuItemsTypes.START_VISIT
  },
  {
    displayRequirements: [
      TicketStatuses.VISIT_SCHEDULED,
      TicketStatuses.NEED_REVISIT,
      TicketStatuses.VISIT_RESCHEDULED
    ],
    name: TicketActionsMenuItems.RESCHEDULE_VISIT,
    type: TicketActionsMenuItemsTypes.RESCHEDULE_VISIT
  },
  {
    displayRequirements: [TicketStatuses.OPERATOR_ASSIGNED],
    name: TicketActionsMenuItems.SCHEDULE_VISIT,
    type: TicketActionsMenuItemsTypes.SCHEDULE_VISIT
  },
  {
    displayRequirements: [TicketStatuses.VISIT_FINISHED],
    name: TicketActionsMenuItems.ASSIGN_SERVICES,
    type: TicketActionsMenuItemsTypes.ASSIGN_SERVICES
  },
  {
    displayRequirements: [TicketStatuses.DISAPPROVED],
    name: TicketActionsMenuItems.EDIT_ASSIGNED_SERVICES,
    type: TicketActionsMenuItemsTypes.EDIT_ASSIGNED_SERVICES
  },
  {
    displayRequirements: [TicketStatuses.WAITING_FOR_APPROVAL],
    name: TicketActionsMenuItems.APPROVE_SERVICES,
    type: TicketActionsMenuItemsTypes.APPROVE_SERVICES
  },
  {
    displayRequirements: [TicketStatuses.WAITING_FOR_APPROVAL],
    name: TicketActionsMenuItems.DISAPPROVE_SERVICES,
    type: TicketActionsMenuItemsTypes.DISAPPROVE_SERVICES
  }
];

export const createNote = (memo: string, ticketOwner: CareCoordinator) => {
  return {
    id: uniqueId(),
    memo,
    createdBy: ticketOwner,
    createdDate: new Date().toISOString()
  };
};

export const updatedMemo = (currentTicket: Ticket, note: string) => {
  return !!note
    ? [...currentTicket.memo, createNote(note, currentTicket.ticketOwner as CareCoordinator)]
    : [...currentTicket.memo];
};

export const handleIsMenuActionDisabled = (ticket: Ticket, item: TicketActionsMenuItems, pathName: string): boolean => {
  const routeName = pathName.slice(1);

  // checklist scenario should be filled out in order to finish visit
  const shouldDisableEndVisitBtn =
    ticket.ticketStatus === TicketStatuses.VISIT_IN_PROGRESS &&
    item === TicketActionsMenuItems.END_VISIT &&
    ticket.checklistAnswers === undefined;

  // approve and disapprove buttons should be disabled on tickets view
  const shouldDisableAcceptingBtn =
    routeName === AppPaths.TICKETS &&
    (item === TicketActionsMenuItems.APPROVE_SERVICES || item === TicketActionsMenuItems.DISAPPROVE_SERVICES);
  return shouldDisableEndVisitBtn || shouldDisableAcceptingBtn;
};

export const handleCreateEmptyService = (servicesLength: number) => {
  return {
    serviceProvider: undefined,
    id: servicesLength + 1,
    code: uniqueId(),
    serviceType: ServiceTypes.NURSING,
    name: ``
  };
};
