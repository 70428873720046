const contentRegex = /<(.|\n)*?>/g;

export const hasContentAnyText = (content: string) => {
  return content.replace(contentRegex, '').trim().length !== 0;
};

export const isEmptyAnyObjectPropertyValue = (object: { [key: string]: any }) => {
  const objectMapValidationResult = Object.values(object).map((propertyValue) => {
    if (typeof propertyValue === 'string') {
      return hasContentAnyText(propertyValue);
    }
    return true;
  });
  return objectMapValidationResult.includes(false);
};

export const getFormattedFileName = (name: string): string => {
  return name.trim().replace(/ /g, '_');
};

const getProperTimeValue = (timeValue: number): string => {
  return timeValue < 10 ? `0${timeValue}` : `${timeValue}`;
};

const getFormattedTime = (date: Date): string => {
  return `${getProperTimeValue(date.getHours())}/${getProperTimeValue(date.getMinutes())}/${getProperTimeValue(
    date.getSeconds()
  )}`;
};

export const getFormattedDate = (date: Date): string => {
  const splitDate = date.toISOString().split('T');
  const formattedDate = splitDate[0].split('-').join('/');
  return `${formattedDate} ${getFormattedTime(date)}`;
};
