import React from 'react';
import DrawerBase from 'components/DrawerBase/DrawerBase';
import HeaderSection from './HeaderSection/HeaderSection';
import PatientDetailsSection from './PatientDetailsSection/PatientDetailsSection';
import VisitsReports from './VisitsReports/VisitsReports';
import AssignedServices from './AssignedServices/AssignedServices';
import Memos from './Memos/Memos';
import ActionsSection from './ActionsSection/ActionsSection';
import { useSelectSelectedTicket } from 'redux-store/features/tickets/tickets.hooks';
import './TicketDrawer.scss';

interface TicketDrawerProps {
  isDrawerOpen: boolean;
  handleToggleDrawer: () => void;
}

const TicketDrawer: React.FC<TicketDrawerProps> = ({ isDrawerOpen, handleToggleDrawer }) => {
  const currentTicket = useSelectSelectedTicket();

  return (
    <>
      {!!currentTicket && (
        <DrawerBase anchor={'right'} isDrawerOpen={isDrawerOpen} handleToggleDrawer={handleToggleDrawer}>
          <div className="drawer-wrapper">
            <HeaderSection currentTicket={currentTicket} />
            <ActionsSection currentTicket={currentTicket} />
            <PatientDetailsSection currentPatient={currentTicket.patientAssigned} />
            <VisitsReports currentTicket={currentTicket} />
            <AssignedServices currentTicket={currentTicket} />
            <Memos currentTicket={currentTicket} />
          </div>
        </DrawerBase>
      )}
    </>
  );
};

export default TicketDrawer;
