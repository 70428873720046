import { defaultCareCoordinatorsData } from 'services/data/indexedDB/mock/raw-data/care-coordinators';
import { ToStoreObject } from 'services/data/types';
import { PatientWithId, PatientDefaultValues } from 'types/patient.types';

export const defaultPatientsData: ToStoreObject<PatientWithId>[] = [
  {
    name: 'Arthur Doe',
    gender: 'Male',
    birthdate: new Date('1978-11-09T12:00:00.000Z').toISOString(),
    address: '254-0354, Osawa 12-jonishi',
    contact: '+1849-673-6091 arthur.doe@domain.com',
    careGrade: PatientDefaultValues.NOT_ASSIGNED,
    assignedCoordinator: PatientDefaultValues.NOT_ASSIGNED
  },
  {
    name: 'Rebecca Doe',
    gender: 'Female',
    birthdate: new Date('1993-05-12T12:00:00.000Z').toISOString(),
    address: '347-1117, Kita 12-jonishi',
    contact: '+2249-673-6091 john.doe@domain.com',
    careGrade: PatientDefaultValues.NOT_ASSIGNED,
    assignedCoordinator: PatientDefaultValues.NOT_ASSIGNED
  },
  {
    name: 'John Dillon',
    gender: 'Male',
    birthdate: new Date('1992-06-13T12:00:00.000Z').toISOString(),
    address: '347-1117, Kita 12-jonishi',
    contact: '+7449-673-6091 john.dillon@domain.com',
    careGrade: 'NSG1',
    assignedCoordinator: `${defaultCareCoordinatorsData[0].name} ${defaultCareCoordinatorsData[0].surname}`
  },
  {
    name: 'Jenny Doe',
    gender: 'Female',
    birthdate: new Date('1972-12-12T12:00:00.000Z').toISOString(),
    address: '347-1117, Kita 12-jonishi',
    contact: '+2549-673-6091 jenny.doe@domain.com',
    careGrade: PatientDefaultValues.NOT_ASSIGNED,
    assignedCoordinator: `${defaultCareCoordinatorsData[1].name} ${defaultCareCoordinatorsData[1].surname}`
  },
  {
    name: 'Vilgefort Albert',
    gender: 'Male',
    birthdate: new Date('1963-05-12T12:00:00.000Z').toISOString(),
    address: '347-1117, Kita 12-jonishi',
    contact: '+9949-673-6091 vilgefort.albert@domain.com',
    careGrade: 'NSG2',
    assignedCoordinator: PatientDefaultValues.NOT_ASSIGNED
  },
  {
    name: 'Bethany Primal',
    gender: 'Female',
    birthdate: new Date('1970-09-13T12:00:00.000Z').toISOString(),
    address: '347-1117, Kita 12-jonishi',
    contact: '+9449-673-6091 bethany.primal@domain.com',
    careGrade: PatientDefaultValues.NOT_ASSIGNED,
    assignedCoordinator: `${defaultCareCoordinatorsData[2].name} ${defaultCareCoordinatorsData[2].surname}`
  },
  {
    name: 'Johnny Doe',
    gender: 'Male',
    birthdate: new Date('1969-04-11T12:00:00.000Z').toISOString(),
    address: '347-1117, Kita 12-jonishi',
    contact: '+7449-673-6091 johnny.doe@domain.com',
    careGrade: 'NNCG1',
    assignedCoordinator: PatientDefaultValues.NOT_ASSIGNED
  },
  {
    name: 'Doesine Marth',
    gender: 'Female',
    birthdate: new Date('1983-11-12T12:00:00.000Z').toISOString(),
    address: '347-1117, Kita 12-jonishi',
    contact: '+7449-673-6091 doesine.marth@domain.com',
    careGrade: PatientDefaultValues.NOT_ASSIGNED,
    assignedCoordinator: `${defaultCareCoordinatorsData[3].name} ${defaultCareCoordinatorsData[3].surname}`
  },
  {
    name: 'Kelly Mournfang',
    gender: 'Male',
    birthdate: new Date('1953-07-18T12:00:00.000Z').toISOString(),
    address: '347-1117, Kita 12-jonishi',
    contact: '+1649-673-6091 kelly.mournfang@domain.com',
    careGrade: 'NNCG2',
    assignedCoordinator: PatientDefaultValues.NOT_ASSIGNED
  },
  {
    name: 'Keisha Mournfang',
    gender: 'Female',
    birthdate: new Date('1976-04-22T12:00:00.000Z').toISOString(),
    address: '347-1117, Kita 12-jonishi',
    contact: '+3649-673-6091 keisha.mournfang@domain.com',
    careGrade: 'Not assigned',
    assignedCoordinator: PatientDefaultValues.NOT_ASSIGNED
  },
  {
    name: 'Ganali Zorga',
    gender: 'Male',
    birthdate: new Date('1983-08-16T12:00:00.000Z').toISOString(),
    address: '347-1117, Kita 12-jonishi',
    contact: '+8149-673-6091 ganali.zorga@domain.com',
    careGrade: 'Not assigned',
    assignedCoordinator: PatientDefaultValues.NOT_ASSIGNED
  },
  {
    name: 'Ganeli Zorga',
    gender: 'Female',
    birthdate: new Date('1988-08-18T12:00:00.000Z').toISOString(),
    address: '347-1117, Kita 12-jonishi',
    contact: '+8149-673-6091 john.doe@domain.com',
    careGrade: PatientDefaultValues.NOT_ASSIGNED,
    assignedCoordinator: `${defaultCareCoordinatorsData[5].name} ${defaultCareCoordinatorsData[5].surname}`
  },
  {
    name: 'John Doe',
    gender: 'Male',
    birthdate: new Date('1970-04-20T12:00:00.000Z').toISOString(),
    address: '347-1117, Kita 12-jonishi',
    contact: '+8149-673-6091 john.doe@domain.com',
    careGrade: 'NNCG5',
    assignedCoordinator: PatientDefaultValues.NOT_ASSIGNED
  },
  {
    name: 'John Doe',
    gender: 'Female',
    birthdate: new Date('1989-07-25T12:00:00.000Z').toISOString(),
    address: '347-1117, Kita 12-jonishi',
    contact: '+8149-673-6091 john.doe@domain.com',
    careGrade: PatientDefaultValues.NOT_ASSIGNED,
    assignedCoordinator: PatientDefaultValues.NOT_ASSIGNED
  },
  {
    name: 'John Doe',
    gender: 'Male',
    birthdate: new Date('1944-07-12T12:00:00.000Z').toISOString(),
    address: '347-1117, Kita 12-jonishi',
    contact: '+8149-673-6091 john.doe@domain.com',
    careGrade: PatientDefaultValues.NOT_ASSIGNED,
    assignedCoordinator: `${defaultCareCoordinatorsData[4].name} ${defaultCareCoordinatorsData[4].surname}`
  },
  {
    name: 'John Doe',
    gender: 'Female',
    birthdate: new Date('1999-05-17T12:00:00.000Z').toISOString(),
    address: '347-1117, Kita 12-jonishi',
    contact: '+8149-673-6091 john.doe@domain.com',
    careGrade: PatientDefaultValues.NOT_ASSIGNED,
    assignedCoordinator: `${defaultCareCoordinatorsData[2].name} ${defaultCareCoordinatorsData[2].surname}`
  },
  {
    name: 'John Doe',
    gender: 'Male',
    birthdate: new Date('1987-09-22T12:00:00.000Z').toISOString(),
    address: '347-1117, Kita 12-jonishi',
    contact: '+8149-673-6091 john.doe@domain.com',
    careGrade: PatientDefaultValues.NOT_ASSIGNED,
    assignedCoordinator: PatientDefaultValues.NOT_ASSIGNED
  }
];
