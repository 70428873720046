import React, { useEffect, useMemo, useState } from 'react';
import './checklist-scenario-modal.scss';
import Header from './Header';
import ModalBase from 'components/ModalBase/ModalBase';
import StepsWrapper from './Steps/StepsWrapper';
import { useSteps } from './useSteps';
import { useSelectSelectedTicket } from 'redux-store/features/tickets/tickets.hooks';
import { StepAnswer, useChecklistAnswersForm } from './utils';
import { useTicketUpdate } from 'views/common/hooks/useTicketUpdate';
import { useMessages } from 'views/common/hooks/useMessage';

interface ChecklistScenarioModalProps {
  isChecklistScenarioModal: boolean;
  handleCloseModal: () => void;
}

const ChecklistScenarioModal: React.FC<ChecklistScenarioModalProps> = ({
  isChecklistScenarioModal,
  handleCloseModal
}) => {
  const [triggerLoadAnswers, setTriggerLoadAnswers] = useState(false);
  const { messages } = useMessages('checklistScenario');

  const { handleTicketUpdate } = useTicketUpdate(messages);
  const ticket = useSelectSelectedTicket();
  const checklist = useMemo(
    () => (ticket?.checklistScenario?.checklistNodes ? ticket?.checklistScenario.checklistNodes : []),
    [ticket]
  );
  // -1 because of indexes in array
  const maxSteps = ticket?.checklistScenario?.checklistNodes.length
    ? ticket?.checklistScenario?.checklistNodes.length - 1
    : 0;
  const { currentStep, handleNextStep, handlePrevStep, handleResetSteps } = useSteps(maxSteps);

  const { ChecklistForm, onChange, handleResetForm } = useChecklistAnswersForm(checklist);

  const handleChangeStepAnswer = (id: string, answer: StepAnswer) => {
    onChange(id, answer);
  };

  const handleCompleteScenario = async () => {
    if (ticket) {
      const res = await handleTicketUpdate({ ...ticket, checklistAnswers: ChecklistForm });
      if (res) {
        handleCloseAndResetModal();
      }
    }
  };

  const handleCloseAndResetModal = () => {
    handleResetChecklist();
    handleCloseModal();
  };

  const handleResetChecklist = () => {
    handleResetForm();
    handleResetSteps();
    setTriggerLoadAnswers(!triggerLoadAnswers);
  };

  useEffect(() => {
    if (ticket?.checklistAnswers) {
      Object.keys(ticket?.checklistAnswers).forEach((key) => {
        ticket?.checklistAnswers && onChange(key, ticket?.checklistAnswers[key]);
      });
    }
  }, [ticket, triggerLoadAnswers]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModalBase
      isModalOpen={isChecklistScenarioModal}
      closeModal={handleCloseAndResetModal}
      isFullScreen={true}
      customHeader={true}
    >
      <div className="checklist-scenario-modal">
        <Header
          currentStep={currentStep}
          maxSteps={maxSteps}
          handleCloseModal={handleCloseAndResetModal}
          ticketId={ticket?.id as string}
        />
        {ticket?.checklistScenario && (
          <StepsWrapper
            currentStep={currentStep}
            handleNextStep={handleNextStep}
            handlePrevStep={handlePrevStep}
            maxSteps={maxSteps}
            checklistNodes={ticket.checklistScenario.checklistNodes}
            handleChangeStepAnswer={handleChangeStepAnswer}
            handleCompleteScenario={handleCompleteScenario}
            handleResetChecklist={handleResetChecklist}
            ChecklistForm={ChecklistForm}
          />
        )}
      </div>
    </ModalBase>
  );
};

export default ChecklistScenarioModal;
