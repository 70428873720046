import { useEffect, useState } from 'react';
import { useSelectSelectedTicket } from 'redux-store/features/tickets/tickets.hooks';
import { useAppDispatch } from 'redux-store/hooks';
import { NursingGrades, SupportGrades } from '../../common/enums/care-grade.enum';
import { Ticket } from 'types/tickets.type';
import { handleCreateEmptyService } from '../tickets.utils';
import { useModal } from '../../common/hooks/useModal';
import { ModalTypes } from '../../common/enums/modal-types.enum';
import { AssignedService } from 'types/service.types';
import { updateAndSelectTicket } from 'redux-store/features/tickets/tickets.actions';

export type CareGradeItem = SupportGrades | NursingGrades;
export type CareGrade = {
  key: CareGradeItem;
  value: CareGradeItem;
};

export const useServicesAssign = (isServicesAssignmentModal: boolean) => {
  const ticket = useSelectSelectedTicket();
  const dispatch = useAppDispatch();
  const [careGrade, setCareGrade] = useState<CareGrade | undefined>(undefined);
  const [services, setServices] = useState<AssignedService[]>([]);
  const [isUnsavedChange, setIsUnsavedChange] = useState(false);
  const { onModalOpen: openModalConfirmAssignedServices } = useModal(ModalTypes.CONFIRM_ASSIGNED_SERVICES);

  const handleSetCareGrade = ({ key, value }: CareGrade) => {
    setCareGrade({ key: key, value: value });
    setIsUnsavedChange(true);
  };

  const handleAddServices = (service: AssignedService) => {
    setServices((prevState) => [...prevState, service]);
  };
  const handleAddNewService = () => {
    const serviceLength = ticket?.services.length ? ticket?.services.length : 0;
    setServices((prevState) => [...prevState, handleCreateEmptyService(serviceLength)]);
  };

  const handleUpdateService = (updatedService: AssignedService, serviceIndex: number) => {
    let updatedServices = services.map((service, index) => {
      return index !== serviceIndex ? service : updatedService;
    });
    setServices(updatedServices);
    setIsUnsavedChange(true);
  };

  const handleRemoveService = (index: number) => {
    const updatedServices = services.filter((service, serviceIndex) => {
      return serviceIndex !== index;
    });
    setServices([...updatedServices]);
    setIsUnsavedChange(true);
  };

  const handleOpenConfirmAssignServicesModal = () => {
    dispatch(updateAndSelectTicket({ ...ticket, services: services, careGrade: careGrade?.value } as Ticket));
    openModalConfirmAssignedServices();
  };

  useEffect(() => {
    setIsUnsavedChange(false);
    setServices([]);
    ticket?.careGrade && setCareGrade({ key: ticket?.careGrade, value: ticket?.careGrade });
    if (ticket?.services.length === 0 && services.length === 0) {
      handleAddNewService();
    } else {
      ticket?.services.forEach((service) => {
        handleAddServices(service);
      });
    }
  }, [isServicesAssignmentModal]); // eslint-disable-line react-hooks/exhaustive-deps
  return {
    careGrade,
    setServices,
    services,
    handleAddNewService,
    handleSetCareGrade,
    handleUpdateService,
    handleRemoveService,
    handleOpenConfirmAssignServicesModal,
    isUnsavedChange
  };
};
