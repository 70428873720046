import { API, DataPaths } from 'services/data/types';

export const createApi = async () => {
  return Object.values(DataPaths).reduce((acc, store) => {
    acc[store] = {
      create: async (item) => {
        throw new Error(`${store} create not implemented`);
      },
      read: async (itemId) => {
        throw new Error(`${store} read not implemented`);
      },
      update: async (item) => {
        throw new Error(`${store} update not implemented`);
      },
      delete: async (itemId) => {
        throw new Error(`${store} delete not implemented`);
      },
      readAll: async () => {
        throw new Error(`${store} readAll not implemented`);
      }
    };
    return acc;
  }, {} as API);
};
