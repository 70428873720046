import React from 'react';
import { TextContentPath } from '../../i18n/i18n-config';
import TextBase from '../TextBase/TextBase';

export interface LabelProps {
  label: TextContentPath | undefined;
}

const LabelBase: React.FC<LabelProps> = ({ label }) => {
  return <>{!!label && <TextBase content={label} variant={'caption'} />}</>;
};
export default LabelBase;
