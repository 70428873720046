import React from 'react';
import { useLocation } from 'react-router-dom';
import ButtonBase from 'components/ButtonBase/ButtonBase';
import { handleIsMenuActionDisabled, MenuItems, TicketActionsMenuItemsTypes } from 'views/Tickets/tickets.utils';
import { useTicketAction } from 'views/common/hooks/useTicketAction';
import { Ticket } from 'types/tickets.type';
import './ActionsSection.scss';

interface ActionsSectionProps {
  currentTicket: Ticket;
}

const ActionsSection: React.FC<ActionsSectionProps> = ({ currentTicket }) => {
  const { triggerSelectedAction } = useTicketAction();
  const location = useLocation();

  const handleSelectedAction = (itemType: TicketActionsMenuItemsTypes) => {
    triggerSelectedAction(itemType);
  };

  const displayedItems = MenuItems.filter((item) => item.displayRequirements.includes(currentTicket.ticketStatus));

  return (
    <div className="actions-selection">
      {displayedItems &&
        displayedItems.map((item, index) => {
          return (
            <div key={item.name} className="action-button">
              <ButtonBase
                content={item.name}
                onClick={() => handleSelectedAction(item.type)}
                disabled={handleIsMenuActionDisabled(currentTicket, item.name, location.pathname)}
              />
              {index !== displayedItems.length - 1 && <div className="dot" />}
            </div>
          );
        })}
    </div>
  );
};
export default ActionsSection;
