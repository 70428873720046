import React from 'react';
import './../checklist-scenario-modal.scss';
import ButtonBase from 'components/ButtonBase/ButtonBase';
import TextBase from 'components/TextBase/TextBase';
import CheckIcon from 'assets/check-circle/check-circle.svg';

interface EndStepProps {
  message: string | undefined;
  handleResetChecklist: () => void;
  handleCompleteScenario: () => void;
}

const EndStep: React.FC<EndStepProps> = ({ message, handleResetChecklist, handleCompleteScenario }) => {
  const handleReset = () => {
    handleResetChecklist();
  };
  return (
    <div className="end-step">
      <img src={CheckIcon} alt="Check Icon" />
      <TextBase variant={'h4'} content={'checklistScenario.checklistScenarioCompleted'} />
      <TextBase variant={'body1'}>{message}</TextBase>
      <div className="buttons-wrapper">
        <ButtonBase content={'checklistScenario.restartScenario'} variant={'outlined'} onClick={() => handleReset()} />
        <ButtonBase
          content={'checklistScenario.saveAndComplete'}
          variant={'contained'}
          onClick={() => handleCompleteScenario()}
        />
      </div>
    </div>
  );
};

export default EndStep;
