import React, { ChangeEvent } from 'react';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { isInvalidScenarioFile } from './utils';
import { rappid } from 'services/rappid.service';
import { openPopup, PopupType } from 'utils/popup-utils';
import { getFormattedFileName } from 'utils/generic-utils';
import { useSelectSelectedScenario } from 'redux-store/features/scenarios/scenarios.hooks';

export const UploadDownloadButtons = () => {
  const scenario = useSelectSelectedScenario();

  const handleDownloadClick = () => {
    if (!rappid) return;
    if (scenario) {
      const graph = rappid.graph.toJSON();
      const scenarioData = { scenario: graph.cells };
      const blob = new Blob([JSON.stringify(scenarioData, null, 2)], { type: 'application/json' });
      const anchorElement = document.createElement('a');
      const scenarioName = getFormattedFileName(scenario.name);

      anchorElement.href = URL.createObjectURL(blob);
      anchorElement.setAttribute('download', scenarioName);
      document.body.appendChild(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
    }
  };

  const handleUploadClick = (fileInput: ChangeEvent<HTMLInputElement>) => {
    const files = fileInput.currentTarget.files;
    if (!rappid) return;

    if (!files || files.length === 0) {
      return openPopup(PopupType.UPLOAD_NO_SELECTED_FILE);
    }

    const reader = new FileReader();
    reader.onload = (event) => onLoadReaderFile(event);
    reader.onerror = () => openPopup(PopupType.UPLOAD_FAILED);
    reader.readAsText(files[0]);
  };

  const onLoadReaderFile = (event: ProgressEvent<FileReader>) => {
    if (!event.target || !event.target.result) {
      return openPopup(PopupType.UPLOAD_FAILED);
    }
    const result = event.target.result;
    let scenarioData;
    if (typeof result === 'string') {
      scenarioData = JSON.parse(result);
    }

    if (isInvalidScenarioFile(scenarioData)) {
      return openPopup(PopupType.UPLOAD_WRONG_DATA);
    }
    rappid?.loadScenarioCells(scenarioData.scenario);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        onClick={handleDownloadClick}
        className="download-btn"
        data-tooltip="Download scenario as JSON file"
        data-tooltip-position="top"
      >
        <DownloadIcon />
      </Button>
      <label htmlFor="upload-file-input">
        <input accept="application/JSON" id="upload-file-input" type="file" onChange={handleUploadClick} />
        <Button
          variant="outlined"
          size="small"
          component="span"
          className="upload-btn"
          data-tooltip="Upload scenario from JSON file"
          data-tooltip-position="top"
        >
          <UploadIcon />
        </Button>
      </label>
    </>
  );
};

export default UploadDownloadButtons;
