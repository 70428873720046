import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from 'services/data/data-broker';

export const fetchServices = createAsyncThunk('services/fetchServices', async () => {
  const api = await Api;
  try {
    const scenarios = await api.SERVICES.readAll();
    return scenarios;
  } catch (err) {
    console.error('services/fetchServices error: ', err);
  }
});
