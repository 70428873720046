import React, { useState } from 'react';
import './PatientDrawer.scss';
import DrawerBase from '../../../components/DrawerBase/DrawerBase';
import { Box, Tab, Tabs } from '@mui/material';
import TextBase from '../../../components/TextBase/TextBase';
import PatientInfo from './Tabs/PatientInfo';
import BaseTabPanel from '../../../components/BaseTabPanel/BaseTabPanel';
import { useTranslation } from 'react-i18next';
import PatientBasicInfo from './PatientBasicInfo';
import AssignCCToPatientModal from './AssignCCToPatientModal/AssignCCToPatientModal';
import { useModal } from '../../common/hooks/useModal';
import { ModalTypes } from '../../common/enums/modal-types.enum';
import { PatientWithId } from 'types/patient.types';

interface PatientDrawerProps {
  isDrawerOpen: boolean;
  handleToggleDrawer: () => void;
  currentPatient: null | PatientWithId;
}

const PatientDrawer: React.FC<PatientDrawerProps> = ({ handleToggleDrawer, isDrawerOpen, currentPatient }) => {
  const [value, setValue] = useState(0);
  const { onModalOpen } = useModal(ModalTypes.ASSIGN_CC_TO_PATIENT);
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <DrawerBase anchor={'right'} isDrawerOpen={isDrawerOpen} handleToggleDrawer={handleToggleDrawer}>
      <div className="drawer-wrapper">
        <PatientBasicInfo currentPatient={currentPatient} handleOpenAssignModal={() => onModalOpen()} />
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="TABS">
              <Tab label={t('patientDetails.patientInfo')} />
              <Tab label={t('patientDetails.medicalHistory')} />
              <Tab label={t('patientDetails.tickets')} />
            </Tabs>
          </Box>
          <BaseTabPanel value={value} index={0}>
            <PatientInfo currentPatient={currentPatient} />
          </BaseTabPanel>
          <BaseTabPanel value={value} index={1}>
            <TextBase>{t('patientDetails.medicalHistory')}</TextBase>
          </BaseTabPanel>
          <BaseTabPanel value={value} index={2}>
            <TextBase>{t('patientDetails.tickets')}</TextBase>
          </BaseTabPanel>
        </Box>
      </div>
      {currentPatient && <AssignCCToPatientModal selectedPatient={currentPatient} />}
    </DrawerBase>
  );
};

export default PatientDrawer;
