import { ToStoreObject } from 'services/data/types';
import { ShapesNames, ShapesDefaultNames } from 'shapes/utils';
import { ScenarioCells, Node, ScenarioData } from 'types/scenario-management.types';
import { SelectType } from 'types/select-option.types';

export const defaultNodes: ScenarioCells = [
  {
    type: 'app.StartShape',
    size: {
      width: 120,
      height: 40
    },
    allowOrthogonalResize: false,
    defaultName: 'Start',
    data: {
      name: 'Start',
      contents: {
        blocks: [
          {
            key: '35r6t',
            text: 'asd',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {}
          }
        ],
        entityMap: {}
      }
    },
    markup: [
      {
        tagName: 'rect',
        selector: 'body'
      },
      {
        tagName: 'rect',
        selector: 'iconBackground'
      },
      {
        tagName: 'text',
        selector: 'label'
      }
    ],
    position: {
      x: -450,
      y: 0
    },
    angle: 0,
    id: 'cef07c91-02ae-4fea-956f-a087ff9ba8d5',
    z: 1,
    attrs: {}
  },
  {
    type: 'app.SelectShape',
    size: {
      width: 120,
      height: 160
    },
    allowOrthogonalResize: false,
    defaultName: 'Select',
    data: {
      list: [
        {
          value: 'a1',
          key: 'a1'
        },
        {
          value: 'a2',
          key: 'a2'
        },
        {
          value: 'a3',
          key: 'a3'
        }
      ],
      card: [],
      name: 'Select',
      contents: {
        blocks: [
          {
            key: '2avbe',
            text: 'aaa',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {}
          }
        ],
        entityMap: {}
      },
      type: 'list'
    },
    markup: [
      {
        tagName: 'rect',
        selector: 'body'
      },
      {
        tagName: 'rect',
        selector: 'iconBackground'
      },
      {
        tagName: 'text',
        selector: 'label'
      }
    ],
    position: {
      x: -450,
      y: 80
    },
    angle: 0,
    id: '85ddb3f0-6a10-4199-94b8-ea6b3994d45a',
    z: 2,
    embeds: [
      '75260101-2e44-44fd-ad09-d2733b7853b2',
      'ab7e562f-0bd1-4f7d-adfe-e9bd9921804b',
      '733357ca-d978-41ac-9ae0-629352aca336'
    ],
    attrs: {}
  },
  {
    type: 'standard.Link',
    source: {
      id: 'cef07c91-02ae-4fea-956f-a087ff9ba8d5'
    },
    target: {
      id: '85ddb3f0-6a10-4199-94b8-ea6b3994d45a'
    },
    id: '8b414fd8-2edb-49c5-894c-5e8814c8969e',
    z: 3,
    attrs: {}
  },
  {
    type: 'app.InputShape',
    size: {
      width: 120,
      height: 40
    },
    allowOrthogonalResize: false,
    defaultName: 'Input',
    data: {
      name: 'Input',
      contents: {
        blocks: [
          {
            key: '1cvam',
            text: 'asd',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {}
          }
        ],
        entityMap: {}
      }
    },
    markup: [
      {
        tagName: 'rect',
        selector: 'body'
      },
      {
        tagName: 'rect',
        selector: 'iconBackground'
      },
      {
        tagName: 'text',
        selector: 'label'
      }
    ],
    position: {
      x: -450,
      y: 290
    },
    angle: 0,
    id: 'd6c9d05e-e6c8-4c83-9de4-80b3aa905917',
    z: 4,
    attrs: {}
  },
  {
    type: 'standard.Link',
    source: {
      id: '85ddb3f0-6a10-4199-94b8-ea6b3994d45a'
    },
    target: {
      id: 'd6c9d05e-e6c8-4c83-9de4-80b3aa905917'
    },
    id: 'e0c891f9-a24f-4c37-98cb-755102bcb081',
    z: 5,
    attrs: {}
  },
  {
    type: 'app.EndShape',
    size: {
      width: 120,
      height: 40
    },
    allowOrthogonalResize: false,
    defaultName: 'End',
    data: {
      name: 'End',
      contents: {
        blocks: [
          {
            key: '4l7le',
            text: 'asd',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {}
          }
        ],
        entityMap: {}
      }
    },
    markup: [
      {
        tagName: 'rect',
        selector: 'body'
      },
      {
        tagName: 'rect',
        selector: 'iconBackground'
      },
      {
        tagName: 'text',
        selector: 'label'
      }
    ],
    position: {
      x: -450,
      y: 530
    },
    angle: 0,
    id: '9b9bb951-5b0f-4d1b-871d-3c1a9b57177d',
    z: 6,
    attrs: {}
  },
  {
    type: 'app.SelectShape',
    size: {
      width: 120,
      height: 120
    },
    allowOrthogonalResize: false,
    defaultName: 'Select',
    data: {
      name: 'Select',
      contents: {
        blocks: [
          {
            key: '7hv2c',
            text: 'sda',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {}
          }
        ],
        entityMap: {}
      },
      type: 'card',
      list: [],
      card: [
        {
          value: 'a1',
          key: 'a1',
          url: 'https://www.look4dog.com/img/thumbs/crop/w350h350q80/breeds/wyzel-weimarski-32921.jpeg'
        },
        {
          value: 's2',
          key: 's1',
          url: 'https://tueuropa.pl/uploads/articles_files/2021/11/05/6e7f9516-1948-d9e8-ca22-00007380aca5.jpg'
        }
      ]
    },
    markup: [
      {
        tagName: 'rect',
        selector: 'body'
      },
      {
        tagName: 'rect',
        selector: 'iconBackground'
      },
      {
        tagName: 'text',
        selector: 'label'
      }
    ],
    position: {
      x: -450,
      y: 370
    },
    angle: 0,
    id: '40d25fa3-810c-40c6-8a11-7d98f1e8c1de',
    z: 7,
    embeds: ['8df893d4-fbcb-42aa-83c5-06c412570c89', 'cb6f049c-e3e3-469a-870f-14229fab32a4'],
    attrs: {}
  },
  {
    type: 'standard.Link',
    source: {
      id: 'd6c9d05e-e6c8-4c83-9de4-80b3aa905917'
    },
    target: {
      id: '40d25fa3-810c-40c6-8a11-7d98f1e8c1de'
    },
    id: 'a1521ef3-3407-437f-b5f1-562aaa9ed422',
    z: 8,
    attrs: {}
  },
  {
    type: 'standard.Link',
    source: {
      id: '40d25fa3-810c-40c6-8a11-7d98f1e8c1de'
    },
    target: {
      id: '9b9bb951-5b0f-4d1b-871d-3c1a9b57177d'
    },
    id: '75c68b18-7dac-4fb5-b0ba-1809401f9856',
    z: 9,
    attrs: {}
  },
  {
    type: 'app.SelectOptionShape',
    size: {
      width: 120,
      height: 40
    },
    allowOrthogonalResize: false,
    defaultName: 'Select option',
    data: {
      name: 'Select option',
      value: 'a1',
      key: 'a1',
      type: 'list'
    },
    markup: [
      {
        tagName: 'rect',
        selector: 'body'
      },
      {
        tagName: 'text',
        selector: 'label'
      }
    ],
    position: {
      x: -450,
      y: 120
    },
    angle: 0,
    id: '75260101-2e44-44fd-ad09-d2733b7853b2',
    parent: '85ddb3f0-6a10-4199-94b8-ea6b3994d45a',
    z: 10,
    attrs: {
      label: {
        textWrap: {
          text: 'a1'
        }
      }
    }
  },
  {
    type: 'app.SelectOptionShape',
    size: {
      width: 120,
      height: 40
    },
    allowOrthogonalResize: false,
    defaultName: 'Select option',
    data: {
      name: 'Select option',
      value: 'a2',
      key: 'a2',
      type: 'list'
    },
    markup: [
      {
        tagName: 'rect',
        selector: 'body'
      },
      {
        tagName: 'text',
        selector: 'label'
      }
    ],
    position: {
      x: -450,
      y: 160
    },
    angle: 0,
    id: 'ab7e562f-0bd1-4f7d-adfe-e9bd9921804b',
    parent: '85ddb3f0-6a10-4199-94b8-ea6b3994d45a',
    z: 11,
    attrs: {
      label: {
        textWrap: {
          text: 'a2'
        }
      }
    }
  },
  {
    type: 'app.SelectOptionShape',
    size: {
      width: 120,
      height: 40
    },
    allowOrthogonalResize: false,
    defaultName: 'Select option',
    data: {
      name: 'Select option',
      value: 'a3',
      key: 'a3',
      type: 'list'
    },
    markup: [
      {
        tagName: 'rect',
        selector: 'body'
      },
      {
        tagName: 'text',
        selector: 'label'
      }
    ],
    position: {
      x: -450,
      y: 200
    },
    angle: 0,
    id: '733357ca-d978-41ac-9ae0-629352aca336',
    parent: '85ddb3f0-6a10-4199-94b8-ea6b3994d45a',
    z: 12,
    attrs: {
      label: {
        textWrap: {
          text: 'a3'
        }
      }
    }
  },
  {
    type: 'app.SelectOptionShape',
    size: {
      width: 120,
      height: 40
    },
    allowOrthogonalResize: false,
    defaultName: 'Select option',
    data: {
      name: 'Select option',
      value: 'a1',
      key: 'a1',
      url: 'https://www.look4dog.com/img/thumbs/crop/w350h350q80/breeds/wyzel-weimarski-32921.jpeg',
      type: 'card'
    },
    markup: [
      {
        tagName: 'rect',
        selector: 'body'
      },
      {
        tagName: 'text',
        selector: 'label'
      }
    ],
    position: {
      x: -450,
      y: 410
    },
    angle: 0,
    id: '8df893d4-fbcb-42aa-83c5-06c412570c89',
    parent: '40d25fa3-810c-40c6-8a11-7d98f1e8c1de',
    z: 13,
    attrs: {
      label: {
        textWrap: {
          text: 'a1'
        }
      }
    }
  },
  {
    type: 'app.SelectOptionShape',
    size: {
      width: 120,
      height: 40
    },
    allowOrthogonalResize: false,
    defaultName: 'Select option',
    data: {
      name: 'Select option',
      value: 's2',
      key: 's1',
      url: 'https://tueuropa.pl/uploads/articles_files/2021/11/05/6e7f9516-1948-d9e8-ca22-00007380aca5.jpg',
      type: 'card'
    },
    markup: [
      {
        tagName: 'rect',
        selector: 'body'
      },
      {
        tagName: 'text',
        selector: 'label'
      }
    ],
    position: {
      x: -450,
      y: 450
    },
    angle: 0,
    id: 'cb6f049c-e3e3-469a-870f-14229fab32a4',
    parent: '40d25fa3-810c-40c6-8a11-7d98f1e8c1de',
    z: 14,
    attrs: {
      label: {
        textWrap: {
          text: 's1'
        }
      }
    }
  }
];

export const defaultsChecklistNode: Node<ShapesNames>[] = [
  {
    type: ShapesNames.START_SHAPE,
    data: {
      name: ShapesDefaultNames.START_SHAPE,
      contents: {
        blocks: [
          {
            key: '35r6t',
            text: 'asd',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {}
          }
        ],
        entityMap: {}
      }
    }
  },
  {
    type: ShapesNames.SELECT_SHAPE,
    data: {
      list: [
        {
          value: 'a1',
          key: 'a1'
        },
        {
          value: 'a2',
          key: 'a2'
        },
        {
          value: 'a3',
          key: 'a3'
        }
      ],
      card: [],
      name: ShapesDefaultNames.SELECT_SHAPE,
      contents: {
        blocks: [
          {
            key: '2avbe',
            text: 'aaa',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {}
          }
        ],
        entityMap: {}
      },
      type: SelectType.LIST
    }
  },
  {
    type: ShapesNames.INPUT_SHAPE,
    data: {
      name: ShapesDefaultNames.INPUT_SHAPE,
      contents: {
        blocks: [
          {
            key: '1cvam',
            text: 'asd',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {}
          }
        ],
        entityMap: {}
      }
    }
  },
  {
    type: ShapesNames.SELECT_SHAPE,
    data: {
      list: [],
      card: [
        {
          value: 'a1',
          key: 'a1',
          url: 'https://www.look4dog.com/img/thumbs/crop/w350h350q80/breeds/wyzel-weimarski-32921.jpeg'
        },
        {
          value: 's2',
          key: 's1',
          url: 'https://tueuropa.pl/uploads/articles_files/2021/11/05/6e7f9516-1948-d9e8-ca22-00007380aca5.jpg'
        }
      ],
      name: ShapesDefaultNames.SELECT_SHAPE,
      contents: {
        blocks: [
          {
            key: '7hv2c',
            text: 'sda',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {}
          }
        ],
        entityMap: {}
      },
      type: SelectType.CARD
    }
  },
  {
    type: ShapesNames.END_SHAPE,
    data: {
      name: ShapesDefaultNames.END_SHAPE,
      contents: {
        blocks: [
          {
            key: '4l7le',
            text: 'asd',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {}
          }
        ],
        entityMap: {}
      }
    }
  }
];

export const defaultScenariosData: ToStoreObject<ScenarioData>[] = [
  { name: 'scenario 1', valid: true, scenario: defaultNodes, checklistNodes: defaultsChecklistNode },
  { name: 'scenario 2', valid: true, scenario: defaultNodes, checklistNodes: defaultsChecklistNode },
  { name: 'scenario 3', valid: true, scenario: defaultNodes, checklistNodes: defaultsChecklistNode }
];
