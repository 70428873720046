import React from 'react';
import { Button, IconButton } from '@mui/material';
import Header from 'components/Header/Header';
import UploadDownloadButtons from './UploadDownloadButtons';
import './scenarioBuilder.scss';
import HomeIcon from '@mui/icons-material/Home';
import TextBase from '../../components/TextBase/TextBase';
import { useTranslation } from 'react-i18next';
import { AppPaths } from 'utils/app-routing';

interface ScenarioBuilderHeaderProps {
  scenarioName: string;
  saveDiagram: () => void;
  handleNavigateTo: (path: AppPaths) => void;
}

const ScenarioBuilderHeader: React.FC<ScenarioBuilderHeaderProps> = ({
  scenarioName,
  saveDiagram,
  handleNavigateTo
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Header>
        <div className="header-tools">
          <TextBase>{`${t('scenarioManagement.scenarioName')}: ${scenarioName}`}</TextBase>
          <UploadDownloadButtons />
          <Button
            variant="contained"
            size="small"
            onClick={saveDiagram}
            data-tooltip={t('scenarioManagement.save')}
            data-tooltip-position="top"
          >
            <TextBase content={'scenarioManagement.save'} />
          </Button>
        </div>
        <IconButton aria-label="return-home" onClick={() => handleNavigateTo(AppPaths.SCENARIO_MANAGEMENT)}>
          <HomeIcon />
        </IconButton>
      </Header>
    </>
  );
};

export default ScenarioBuilderHeader;
