import { ui } from '@clientio/rappid';

const DEFAULT_POPUP_DURATION = 3000;
const LONG_MESSAGE_POPUP_DURATION = 6000;

export enum PopupType {
  NODE_CHANGES_SAVED = 'node_changes_saved',
  SCENARIO_VALID = 'scenario_valid',
  SCENARIO_INVALID = 'scenario_invalid',
  SCENARIO_SAVED_SUCCESS = 'scenario_saved_success',
  SCENARIO_SAVED_ERROR = 'scenario_saved_error',
  UPLOAD_WRONG_DATA = 'scenario_wrong_data_upload',
  UPLOAD_FAILED = 'upload_failed',
  UPLOAD_NO_SELECTED_FILE = 'upload_no_selected_file',
  IMAGE_WITH_THIS_NAME_EXIST = 'image_with_this_name_exist',
  IMAGE_UPLOADED = 'image_uploaded'
}

const enum PopupSeverityType {
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success'
}

export const popupInfoList = {
  [PopupType.NODE_CHANGES_SAVED]: {
    content: 'Successfully saved data!',
    type: PopupSeverityType.SUCCESS,
    duration: DEFAULT_POPUP_DURATION
  },
  [PopupType.SCENARIO_VALID]: {
    content: 'Validation passed!',
    type: PopupSeverityType.SUCCESS,
    duration: DEFAULT_POPUP_DURATION
  },
  [PopupType.SCENARIO_INVALID]: {
    content: 'An error was detected during validation. Please check the nodes and connections again!',
    type: PopupSeverityType.WARNING,
    duration: LONG_MESSAGE_POPUP_DURATION
  },
  [PopupType.SCENARIO_SAVED_SUCCESS]: {
    content: 'Scenario saved successfully!',
    type: PopupSeverityType.SUCCESS,
    duration: DEFAULT_POPUP_DURATION
  },
  [PopupType.SCENARIO_SAVED_ERROR]: {
    content: 'An error occurred while saving the changes.',
    type: PopupSeverityType.ERROR,
    duration: DEFAULT_POPUP_DURATION
  },
  [PopupType.UPLOAD_WRONG_DATA]: {
    content: 'Cannot load scenario, wrong data structure!',
    type: PopupSeverityType.ERROR,
    duration: DEFAULT_POPUP_DURATION
  },
  [PopupType.UPLOAD_FAILED]: {
    content: 'Cannot load scenario from JSON file',
    type: PopupSeverityType.ERROR,
    duration: DEFAULT_POPUP_DURATION
  },
  [PopupType.UPLOAD_NO_SELECTED_FILE]: {
    content: 'No file selected',
    type: PopupSeverityType.ERROR,
    duration: DEFAULT_POPUP_DURATION
  },
  [PopupType.IMAGE_WITH_THIS_NAME_EXIST]: {
    content: 'image withs this name already exist',
    type: PopupSeverityType.ERROR,
    duration: DEFAULT_POPUP_DURATION
  },
  [PopupType.IMAGE_UPLOADED]: {
    content: 'image uploaded',
    type: PopupSeverityType.SUCCESS,
    duration: DEFAULT_POPUP_DURATION
  }
};

export const openPopup = (popupType: PopupType): void => {
  const { content, type, duration } = popupInfoList[popupType];
  new ui.FlashMessage({
    theme: 'modern',
    type,
    content,
    closeAnimation: { delay: duration }
  }).open();
};
