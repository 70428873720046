import { uniqueId } from 'lodash';
import { ToStoreObject } from 'services/data/types';
import { Service } from 'types/service.types';
import { ServiceProvider } from 'types/tickets.type';

export enum ServiceTypes {
  NURSING = 'Nursing',
  MEDICAL = 'Medical',
  HOMECARE = 'Home Care',
  HOSPITAL = 'Hospital',
  HC31 = 'HC_Level3_1'
}

export const defaultServicesData: ToStoreObject<Service>[] = [
  {
    code: uniqueId(),
    serviceType: ServiceTypes.NURSING,
    name: 'Lorem ipsum dolor sit amet (re-examination sit amet) (more lorem description)'
  },
  {
    code: uniqueId(),
    serviceType: ServiceTypes.MEDICAL,
    name: 'Lorem ipsum dolor sit amet (re-examination sit amet) (more lorem description)'
  },
  {
    code: uniqueId(),
    serviceType: ServiceTypes.HOMECARE,
    name: 'Lorem ipsum dolor sit amet (re-examination sit amet) (more lorem description)'
  },
  {
    code: uniqueId(),
    serviceType: ServiceTypes.HOSPITAL,
    name: 'Lorem ipsum dolor sit amet (re-examination sit amet) (more lorem description)'
  },
  {
    code: uniqueId(),
    serviceType: ServiceTypes.NURSING,
    name: 'Lorem ipsum dolor sit amet (re-examination sit amet) (more lorem description)'
  },
  {
    code: uniqueId(),
    serviceType: ServiceTypes.NURSING,
    name: 'Lorem ipsum dolor sit amet (re-examination sit amet) (more lorem description)'
  },
  {
    code: uniqueId(),
    serviceType: ServiceTypes.HC31,
    name: 'Lorem ipsum dolor sit amet (re-examination sit amet) (more lorem description)'
  },
  {
    code: uniqueId(),
    serviceType: ServiceTypes.NURSING,
    name: 'Lorem ipsum dolor sit amet (re-examination sit amet) (more lorem description)'
  }
];

export const serviceProviderList: ServiceProvider[] = [
  {
    clinicId: 1,
    clinicName: 'clinic 1 name',
    clinicDetails: 'clinic 1 details',
    clinicAddress: 'clinic 1 address'
  },
  {
    clinicId: 2,
    clinicName: 'clinic 2 name',
    clinicDetails: 'clinic 2 details',
    clinicAddress: 'clinic 2 address'
  },
  {
    clinicId: 3,
    clinicName: 'clinic 3 name',
    clinicDetails: 'clinic 3 details',
    clinicAddress: 'clinic 3 address'
  }
];
