import { createSelector } from '@reduxjs/toolkit';
import { patientsAdapter } from './patients.slice';
import { RootState } from 'redux-store/store-config';
import { PatientWithId } from 'types/patient.types';

export const getPatients = (state: RootState): PatientWithId[] =>
  patientsAdapter.getSelectors().selectAll(state.patientsState);

//export const selectSelectedTicket = (state: RootState): Ticket | undefined => state.ticketsState.selectedTicket;

export const selectPatients = createSelector([getPatients], (patients) => patients);
