import React from 'react';
import { useLocation } from 'react-router-dom';
import { TableCell, TableRow } from '@mui/material';
import clsx from 'clsx';
import './TableBodyRow.scss';
import { TableBodyRowProps, TableDataItem } from '../../table.types';
import { clickableColumn, getCustomColumns } from '../../table-utils';

const styleSelectedColumns = <T extends TableDataItem>(tableItem: keyof T, path: string): boolean => {
  return Object.values(getCustomColumns(path) ?? {}).some((enumValue) => enumValue === tableItem);
};

const getTableItemKeys = <T extends TableDataItem>(tableItem: T): (keyof T)[] => {
  const { id, ...updatedTableItems } = tableItem;
  return Object.keys(updatedTableItems) as (keyof T)[];
};

const checkIfCellIsClickable = (customRenderer: boolean, itemProperty: string): boolean => {
  return customRenderer || itemProperty === clickableColumn.TICKET_ID || itemProperty === clickableColumn.BIRTHDATE;
};

const tableCellStyles = <T extends TableDataItem>(itemProperty: keyof T, pathName: string) =>
  clsx('custom-width', styleSelectedColumns(itemProperty, pathName) && 'styled-column');

const TableBodyRow = <T extends TableDataItem>({
  tableItem,
  customRenderers,
  onRowClickFunction
}: TableBodyRowProps<T>) => {
  const location = useLocation();

  const handleOnRowClick = (id: string | number, customRenderer: boolean, itemProperty: string) => {
    if (checkIfCellIsClickable(customRenderer, itemProperty) && onRowClickFunction && id) {
      onRowClickFunction(id);
    }
  };
  return (
    <TableRow>
      {getTableItemKeys(tableItem).map((itemProperty) => {
        const customRenderer = customRenderers?.[itemProperty];
        return (
          <TableCell
            onClick={() => {
              handleOnRowClick(tableItem.id, !customRenderer as boolean, itemProperty as string);
            }}
            className={clsx(
              tableCellStyles(itemProperty, location.pathname),
              checkIfCellIsClickable(!customRenderer as boolean, itemProperty as string) && 'cursor-pointer'
            )}
            key={itemProperty as string}
          >
            {!!customRenderer ? customRenderer(tableItem) : tableItem[itemProperty]}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default TableBodyRow;
