import React from 'react';
import ModalBase from 'components/ModalBase/ModalBase';
import ButtonBase from 'components/ButtonBase/ButtonBase';
import TextBase from 'components/TextBase/TextBase';
import { useModal } from 'views/common/hooks/useModal';
import { ModalTypes } from 'views/common/enums/modal-types.enum';
import './CancelAssignedServicesModal.scss';
import { setSelectedTicket } from 'redux-store/features/tickets/tickets.slice';
import { useAppDispatch } from 'redux-store/hooks';
import AlertTriangle from 'assets/styled-icons/alertTriangle/AlertTriangle';
import { fetchTickets } from 'redux-store/features/tickets/tickets.actions';

const CancelAssignedServicesModal: React.FC = () => {
  const { isModalOpen, onModalClose: closeCancelAssignedServices } = useModal(ModalTypes.CANCEL_ASSIGNED_SERVICES);
  const { onModalClose: closeModalServicesAssignment } = useModal(ModalTypes.SERVICES_ASSIGNMENT);
  const dispatch = useAppDispatch();

  const handleQuitCancelAssignedServices = () => {
    closeCancelAssignedServices();
    closeModalServicesAssignment();
    dispatch(setSelectedTicket({ id: '' }));
    dispatch(fetchTickets());
  };

  return (
    <ModalBase isModalOpen={isModalOpen} closeModal={closeCancelAssignedServices} customHeader={true}>
      <div className="approve-report-modal">
        <AlertTriangle />
        <TextBase variant={'h4'} content={'ticket.cancelAssignedServicesModal.unsavedChanges'} fontWeight={600} />
        <TextBase content={'ticket.cancelAssignedServicesModal.info'} fontWeight={400} />
        <div className="modal__btn-group">
          <ButtonBase
            content={'common.quit'}
            size={'large'}
            type={'button'}
            variant={'contained'}
            onClick={() => handleQuitCancelAssignedServices()}
          />
          <ButtonBase content={'common.cancel'} disableElevation size={'large'} onClick={closeCancelAssignedServices} />
        </div>
      </div>
    </ModalBase>
  );
};

export default CancelAssignedServicesModal;
