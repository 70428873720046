import { ShapesColors, ShapesDefaultNames, ShapesNames, defaultTextAttributes } from '../utils';

export const SelectOptionAttributes = {
  type: ShapesNames.SELECT_OPTION_SHAPE,
  size: { width: 120, height: 40 },
  allowOrthogonalResize: false,
  defaultName: ShapesDefaultNames.SELECT_OPTION,
  attrs: {
    attrs: {
      root: { pointerEvents: 'none' }
    },
    body: {
      fill: 'transparent',
      stroke: ShapesColors.SELECT_OPTION_SHAPE,
      strokeWidth: 2,
      pointerEvents: 'none'
    },
    label: {
      ...defaultTextAttributes,
      textAnchor: 'middle',
      refX: 0.5,
      pointerEvents: 'none',
      textWrap: {
        width: -20,
        height: -20,
        ellipsis: '...'
      }
    }
  },
  data: {
    name: ShapesDefaultNames.SELECT_OPTION,
    value: ''
  },
  markup: [
    {
      tagName: 'rect',
      selector: 'body'
    },
    {
      tagName: 'text',
      selector: 'label'
    }
  ]
};
