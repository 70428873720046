import React from 'react';
import './BadgeBase.scss';

interface BadgeBaseProps {
  badgeContent: number;
  max?: boolean;
  maxValue?: number;
}

const defaultShowMaxValue = true;
const defaultMaxValue = 5;

const BadgeBase: React.FC<BadgeBaseProps> = ({
  badgeContent,
  max = defaultShowMaxValue,
  maxValue = defaultMaxValue
}) => {
  const showValue = (): string => {
    if (max && badgeContent > maxValue) return `${maxValue}+`;
    return badgeContent.toString();
  };

  return <div className="badge">{showValue()}</div>;
};

export default BadgeBase;
