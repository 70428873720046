import { defaultMarkupAttributes, ShapesColors, ShapesDefaultNames, ShapesNames } from '../utils';
import { defaultTextAttributes } from '../utils';

export const StartAttributes = {
  type: ShapesNames.START_SHAPE,
  size: { width: 120, height: 40 },
  allowOrthogonalResize: false,
  defaultName: ShapesDefaultNames.START_SHAPE,
  attrs: {
    body: {
      fill: ShapesColors.START_SHAPE,
      stroke: ShapesColors.START_SHAPE,
      rx: 2,
      ry: 2,
      strokeWidth: 2
    },
    label: {
      ...defaultTextAttributes,
      fontSize: 20,
      strokeWidth: 0,
      refX: 0.5,
      textAnchor: 'middle',
      textWrap: {
        text: ShapesDefaultNames.START_SHAPE,
        width: -10,
        height: -10,
        ellipsis: '...'
      },
      fill: '#FFFFFF'
    }
  },
  data: {
    name: ShapesDefaultNames.START_SHAPE,
    contents: null
  },
  markup: defaultMarkupAttributes
};
