import { combineReducers } from '@reduxjs/toolkit';
import careCoordinatorReducer from './features/care-coordinator/care-coordinator.slice';
import ticketsReducer from './features/tickets/tickets.slice';
import snackbarReducer from './features/snackbar/snackbar.slice';
import modalReducer from './features/modal/modal.slice';
import scenariosReducer from 'redux-store/features/scenarios/scenarios.slice';
import servicesReducer from 'redux-store/features/services/services.slice';
import serviceProvidersReducer from 'redux-store/features/service-providers/service-providers.slice';
import patientsReducer from 'redux-store/features/patients/patients.slice';

export const reducers = {
  careCoordinatorState: careCoordinatorReducer,
  ticketsState: ticketsReducer,
  snackbarState: snackbarReducer,
  modalState: modalReducer,
  scenariosState: scenariosReducer,
  servicesState: servicesReducer,
  serviceProvidersState: serviceProvidersReducer,
  patientsState: patientsReducer
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
