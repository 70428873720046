import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './select.scss';
import { CardItemType } from './SelectCard';
import TextBase from '../../../../components/TextBase/TextBase';

export interface CardItemProps {
  option: CardItemType;
}

const CardItem: React.FC<CardItemProps> = ({ option }) => {
  return (
    <div className="carousel-item">
      <img src={option.url} alt="card-img" />
      <TextBase className="info">{option.value as string}</TextBase>
    </div>
  );
};

export default CardItem;
