import { MemoizedIconComponent } from 'components/Icon/Icon';
import React from 'react';
import './AlertTriangle.scss';

const AlertTriangle = () => {
  return (
    <div className="alert-triangle">
      <MemoizedIconComponent name="AlertTriangle" />
    </div>
  );
};

export default AlertTriangle;
