import React from 'react';
import { TicketStatusDetails } from 'types/tickets.type';
import TextBase from 'components/TextBase/TextBase';
import { TicketTypes } from 'views/common/enums/ticket.enum';
import { getStatusBackground, getStatusColor } from 'views/Tickets/tickets.utils';
import { TextContentPath } from 'i18n/i18n-config';
import './TicketStatusLabel.scss';

const TicketStatusLabel: React.FC<TicketStatusDetails> = ({ visitLabel, visitStatus }) => {
  return (
    <div className="ticket-details">
      <div
        className="ticket-details__label ticket-details__indicator"
        style={{ backgroundColor: getStatusBackground(visitLabel, visitStatus) }}
      >
        <TextBase content={visitLabel} variant="caption" />
        <TextBase content={TicketTypes.VISITING} variant="caption" />
      </div>
      <div className="ticket-details__label">
        <div className="ticket-details__square" style={{ backgroundColor: getStatusColor(visitStatus) }} />
        <TextBase content={visitStatus as TextContentPath} variant="caption" />
      </div>
    </div>
  );
};

export default TicketStatusLabel;
