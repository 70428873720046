import React from 'react';
import { InputAdornment } from '@mui/material';
import './Searchbar.scss';
import { MemoizedIconComponent } from 'components/Icon/Icon';
import TextFieldBase from 'components/TextFieldBase/TextFieldBase';

interface SearchbarProps {
  setSearchValue: (event: string) => void;
}

const Searchbar: React.FC<SearchbarProps> = ({ setSearchValue }) => {
  const getSearchValue = (searchValue: string) => setSearchValue(searchValue);
  return (
    <TextFieldBase
      onChange={(e) => getSearchValue(e.target.value)}
      placeholderText={'table.search'}
      className="search"
      startIcon={
        <InputAdornment position="start">
          <MemoizedIconComponent name="Search" />
        </InputAdornment>
      }
    />
  );
};

export default Searchbar;
