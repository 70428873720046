export enum PatientDefaultValues {
  NOT_ASSIGNED = 'Not assigned'
}
export interface Patient {
  name: string;
  gender: string;
  birthdate: string;
  address: string;
  contact: string;
  careGrade: string;
  assignedCoordinator: string;
}

export interface PatientWithId extends Patient {
  id: string;
}
