import { S3Bucket } from './bucket';

export const generateFileKey = (scenarioName: string, fileName: string) => {
  return `${scenarioName.replace(' ', '_')}/${fileName.replace(' ', '_')}`;
};

export const uploadFile = (file: File, scenarioName: string) => {
  let renamedFile = file;
  renamedFile.name.replace(' ', '_');
  const params = {
    ACL: 'public-read',
    Body: renamedFile,
    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME as string,
    Key: generateFileKey(scenarioName, file.name)
  };
  return S3Bucket.putObject(params, function (err, data) {
    if (err) console.log(err, err.stack);
  });
};

export const getListObject = () => {
  const params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME as string
  };
  return S3Bucket.listObjects(params, function (err, data) {
    if (err) console.log(err, err.stack);
  });
};

export const generateUploadFileUrl = (fileName: string, scenarioName: string) => {
  return `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${
    process.env.REACT_APP_AWS_REGION
  }.amazonaws.com/${generateFileKey(scenarioName, fileName)}`;
};
