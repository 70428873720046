import { useCallback, useState } from 'react';
import { DateValidationError } from '@mui/x-date-pickers/internals';
import { TimeValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useTimeValidation';

export interface InitialFormState {}

export const useForm = <T extends InitialFormState>(initialState: T) => {
  const [values, setValues] = useState(initialState);
  const [defaultValues, setDefaultValues] = useState(initialState);

  const onChange = useCallback(
    (name: keyof T, value: typeof initialState[typeof name] | undefined) => {
      setValues((prevState) => ({ ...prevState, [name]: !!value ? { ...values[name], ...value } : undefined }));
    },
    [values]
  );

  const onSetError = (name: keyof T, error: DateValidationError | TimeValidationError | undefined) => {
    setValues((prevState) => ({ ...prevState, [name]: { ...values[name], error } }));
  };

  const handleResetForm = () => {
    setValues({ ...defaultValues });
  };

  const handleAddMany = (newValues: T) => {
    setValues((prevState) => ({ ...prevState, ...newValues }));
  };

  const isFormInvalid = (formValues: T, optionalValues?: (keyof T)[]): boolean => {
    const formValuesArr = Object.values(formValues);
    return formValuesArr.some((formValue) => {
      const requiredValues = !optionalValues?.includes(formValue.key) && !formValue?.value;
      return requiredValues || !!formValue?.error;
    });
  };

  return { onChange, values, onSetError, setValues, handleResetForm, handleAddMany, setDefaultValues, isFormInvalid };
};
