import { defaultMarkupAttributes, ShapesColors, ShapesDefaultNames, ShapesNames } from '../utils';
import { defaultTextAttributes } from '../utils';
import { SelectType } from 'types/select-option.types';

export const SelectAttributes = {
  type: ShapesNames.SELECT_SHAPE,
  size: { width: 120, height: 40 },
  allowOrthogonalResize: false,
  defaultName: ShapesDefaultNames.SELECT_SHAPE,
  attrs: {
    body: {
      fill: '#FFFFFF',
      stroke: ShapesColors.SELECT_SHAPE,
      rx: 2,
      ry: 2,
      strokeWidth: 2
    },
    label: {
      ...defaultTextAttributes,
      refY: 20,
      textWrap: {
        text: ShapesDefaultNames.SELECT_SHAPE,
        width: -40,
        height: 30,
        ellipsis: '...'
      }
    },
    iconBackground: {
      width: 30,
      height: 40,
      fill: ShapesColors.SELECT_SHAPE,
      x: 0,
      y: 1
    }
  },
  data: {
    list: [],
    card: [],
    name: ShapesDefaultNames.SELECT_SHAPE,
    contents: null,
    type: SelectType.LIST
  },
  markup: defaultMarkupAttributes
};
