import React from 'react';
import { IconButton } from '@mui/material';
import './HeaderSection.scss';
import { Ticket } from 'types/tickets.type';
import TextBase from 'components/TextBase/TextBase';
import TicketStatusLabel from 'views/common/components/TicketStatusLabel/TicketStatusLabel';
import { MemoizedIconComponent } from 'components/Icon/Icon';
import CareCoordinatorCell from 'views/common/components/CareCoordinatorCell/CareCoordinatorCell';
import { formatLastUpdated } from 'utils/date-format-utils';

interface HeaderSectionProps {
  currentTicket: null | Ticket;
}

const HeaderSection: React.FC<HeaderSectionProps> = ({ currentTicket }) => {
  return (
    <div className="header-section">
      <div className="header-section__title color-dark">
        <TextBase variant={'h5'} content={'ticket.details.ticket'} />
        <TextBase variant={'h5'}>{`#${currentTicket?.id}`}</TextBase>
      </div>
      <TextBase variant={'subtitle2'} fontWeight={600} className="header-section__username">
        {currentTicket?.patientAssigned.name}
      </TextBase>
      <div className="header-section__info">
        <div className="header-section__info-status">
          {!!currentTicket && (
            <TicketStatusLabel
              visitLabel={currentTicket?.ticketStatusLabel}
              visitStatus={currentTicket?.ticketStatus}
            />
          )}
          <IconButton className="dark-color">
            <MemoizedIconComponent name={'ChevronDown'} />
          </IconButton>
          <div className="gray-color">
            <TextBase content={'common.updated'} variant="caption" />
            <TextBase variant="caption">
              {currentTicket?.lastUpdatedDate && formatLastUpdated(new Date(currentTicket?.lastUpdatedDate))}
            </TextBase>
          </div>
        </div>
        <CareCoordinatorCell careCoordinator={currentTicket?.ticketOwner} colorClassName={'color-dark-disabled'} />
      </div>
    </div>
  );
};

export default HeaderSection;
