import React from 'react';
import './services-assignment-modal.scss';
import TextBase from '../../../components/TextBase/TextBase';
import SelectBase from '../../../components/SelectBase/SelectBase';
import { SupportGrades } from '../../common/enums/care-grade.enum';
import { CareGrade } from 'views/Tickets/ServicesAssignmentModal/useServicesAssign';

interface SelectCareGradeProps {
  selectedCareGrade: CareGrade | undefined;
  handleSetCareGrade: ({ key, value }: CareGrade) => void;
}

const mockOptions: CareGrade[] = [
  { key: SupportGrades.NSG1, value: SupportGrades.NSG1 },
  { key: SupportGrades.NSG2, value: SupportGrades.NSG2 },
  { key: SupportGrades.NSG3, value: SupportGrades.NSG3 },
  { key: SupportGrades.NSG4, value: SupportGrades.NSG4 }
];
const SelectCareGrade: React.FC<SelectCareGradeProps> = ({ selectedCareGrade, handleSetCareGrade }) => {
  return (
    <div className="select-care-grade">
      <TextBase variant={'h6'} content={'ticket.assignServicesModal.selectCareGrade'} />
      <SelectBase
        options={mockOptions}
        selectedOption={selectedCareGrade ? selectedCareGrade : { key: '', value: '' }}
        onChange={(e) =>
          handleSetCareGrade({ key: e.target.value as SupportGrades, value: e.target.value as SupportGrades })
        }
      />
    </div>
  );
};

export default SelectCareGrade;
