import { createAsyncThunk } from '@reduxjs/toolkit';
import { addScenario, setSelectedScenario } from 'redux-store/features/scenarios/scenarios.slice';
import Api from 'services/data/data-broker';
import { NewScenarioData, ScenarioData } from 'types/scenario-management.types';

export const fetchScenarios = createAsyncThunk('scenarios/fetchScenarios', async () => {
  const api = await Api;
  try {
    const scenarios = await api.SCENARIOS.readAll();
    return scenarios;
  } catch (err) {
    console.error('scenarios/fetchScenarios error: ', err);
  }
});

export const updateScenario = createAsyncThunk('scenarios/updateScenario', async (scenario: ScenarioData) => {
  const api = await Api;
  try {
    const scenarioId = await api.SCENARIOS.update(scenario);
    return { ...scenario, id: scenarioId.toString() };
  } catch (err) {
    console.error('scenarios/updateScenario error: ', err);
  }
});

export const createScenario = createAsyncThunk(
  'scenarios/createScenario',
  async (scenario: ScenarioData | NewScenarioData) => {
    const api = await Api;
    try {
      const scenarioId = await api.SCENARIOS.create(scenario as ScenarioData);
      return { ...scenario, id: scenarioId.toString() };
    } catch (err) {
      console.error('scenarios/createScenario error: ', err);
    }
  }
);

export const createAndSelectScenario = createAsyncThunk(
  'scenarios/createAndSelectScenario',
  async (scenario: ScenarioData | NewScenarioData, thunkApi) => {
    const api = await Api;
    try {
      const scenarioId = await api.SCENARIOS.create(scenario as ScenarioData);
      const newScenario = { ...scenario, id: scenarioId.toString() };
      thunkApi.dispatch(addScenario(newScenario));
      thunkApi.dispatch(setSelectedScenario({ id: newScenario.id }));
    } catch (err) {
      console.error('scenarios/createAndSelectScenario error: ', err);
    }
  }
);

export const deleteScenario = createAsyncThunk('scenarios/deleteScenario', async (scenarioId: string) => {
  const api = await Api;
  try {
    await api.SCENARIOS.delete(scenarioId);
    return scenarioId;
  } catch (err) {
    console.error('scenarios/deleteScenario error: ', err);
  }
});
