import { useSelector } from 'react-redux';
import {
  selectScenariosList,
  selectScenariosLoading,
  selectScenariosNames,
  selectSelectedScenario
} from './scenarios.selectors';

export const useSelectScenarios = () => useSelector(selectScenariosList);
export const useSelectScenariosLoading = () => useSelector(selectScenariosLoading);
export const useSelectSelectedScenario = () => useSelector(selectSelectedScenario);
export const useSelectScenariosNames = () => useSelector(selectScenariosNames);
