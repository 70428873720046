import { useState } from 'react';

export const useSteps = (maxSteps: number) => {
  const [currentStep, setCurrentStep] = useState(0);

  const handleNextStep = () => {
    if (maxSteps > currentStep) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleResetSteps = () => {
    setCurrentStep(0);
  };

  return { currentStep, handleNextStep, handlePrevStep, handleResetSteps };
};
