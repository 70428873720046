import { MemoizedIconComponent } from 'components/Icon/Icon';
import React from 'react';
import './CheckIcon.scss';

const CheckIcon = () => {
  return (
    <div className="check-approve">
      <MemoizedIconComponent name="Check" />
    </div>
  );
};

export default CheckIcon;
