import React from 'react';
import './ScenariosManagement.scss';
import { Box, Button } from '@mui/material';
import { NewScenarioModalProps } from '../../types/scenario-management.types';
import ModalBase from '../../components/ModalBase/ModalBase';
import TextBase from '../../components/TextBase/TextBase';
import TextFieldBase from '../../components/TextFieldBase/TextFieldBase';
import { useTranslation } from 'react-i18next';
import { TextContentPath } from '../../i18n/i18n-config';
import { ModalTypes } from '../common/enums/modal-types.enum';
import { useModal } from '../common/hooks/useModal';

const NewScenarioModal: React.FC<NewScenarioModalProps> = ({
  newScenarioName,
  handleChangeNewScenarioNameInput,
  handleAddNewScenario,
  closeModal
}) => {
  const { t } = useTranslation();
  const { isModalOpen } = useModal(ModalTypes.CREATE_NEW_SCENARIO);

  return (
    <ModalBase isModalOpen={isModalOpen} closeModal={closeModal} headerText={'scenarioManagement.createScenario'}>
      <Box className="modal-add-new-scenario">
        <TextFieldBase
          size={'small'}
          onChange={(e) => handleChangeNewScenarioNameInput(e.target.value)}
          defaultValue={newScenarioName.name}
          labelText={'scenarioManagement.newScenarioName'}
          error={newScenarioName.error !== ''}
          helperText={t(newScenarioName.error as TextContentPath) as string}
        />
        <Button
          variant="outlined"
          aria-label="create-scenario"
          onClick={() => {
            handleAddNewScenario();
          }}
          disabled={newScenarioName.error !== '' || newScenarioName.name.length === 0}
        >
          <TextBase content={'scenarioManagement.createScenario'} />
        </Button>
      </Box>
    </ModalBase>
  );
};

export default NewScenarioModal;
