import React from 'react';
import './Memos.scss';
import TextBase from 'components/TextBase/TextBase';
import { MemoizedIconComponent } from 'components/Icon/Icon';
import TextFieldBase from 'components/TextFieldBase/TextFieldBase';
import MemosList from './MemosList/MemosList';
import { Ticket } from 'types/tickets.type';

interface MemosProps {
  currentTicket: Ticket;
}

const Memos: React.FC<MemosProps> = ({ currentTicket }) => {
  return (
    <div className="memos">
      <div className="memos__header">
        <TextBase content={'ticket.memo.memos'} variant="h6" className="color-dark" />
        <div className="memos__header-sort">
          <TextBase content={'common.newestFirst'} variant="caption" className="color-dark-secondary" />
          <MemoizedIconComponent name={'ArrowDown'} />
        </div>
      </div>
      <div className="memos__add">
        <img src={currentTicket.ticketOwner?.imagePath} alt="profile pic" className="avatar" />
        <TextFieldBase fullWidth placeholderText={'ticket.memo.addMemo'} />
      </div>
      <MemosList currentTicket={currentTicket} />
    </div>
  );
};

export default Memos;
