import React from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumbs } from '@mui/material';
import TextBase from '../TextBase/TextBase';
import { breadcrumbNameMap } from '../../utils/menu-utils';
import { TextContentPath } from '../../i18n/i18n-config';
import './BreadcrumbsBase.scss';

const formattedName = (index: number, pathNames: string[]): string => {
  return `${pathNames.slice(0, index + 1).join('/')}`;
};

const BreadcrumbsBase = () => {
  const location = useLocation();
  const pathNames = location.pathname.split('/').filter((pathEl) => pathEl);
  return (
    <Breadcrumbs className="breadcrumbs">
      {pathNames.map((value, index) => (
        <div key={formattedName(index, pathNames)}>
          <TextBase
            className="breadcrumbs__text"
            content={breadcrumbNameMap[formattedName(index, pathNames)] as TextContentPath}
            variant="h5"
          />
        </div>
      ))}
    </Breadcrumbs>
  );
};

export default BreadcrumbsBase;
