import { createSelector } from '@reduxjs/toolkit';
import { Ticket } from 'types/tickets.type';
import { TicketStatuses, TicketStatusLabels } from 'views/common/enums/ticket.enum';
import { getTicketRows } from 'views/Tickets/tickets.utils';
import { ticketsAdapter } from './tickets.slice';
import { RootState } from 'redux-store/store-config';
import { getInboxRows } from 'views/Inbox/inbox.utils';

export const selectTickets = (state: RootState): Ticket[] =>
  ticketsAdapter.getSelectors().selectAll(state.ticketsState);

export const selectSelectedTicket = (state: RootState): Ticket | undefined => state.ticketsState.selectedTicket;

export const selectTicketRows = createSelector([selectTickets], (tickets) => getTicketRows(tickets));

export const selectPreVisitTickets = createSelector([selectTickets], (tickets) =>
  getTicketRows(tickets.filter((ticket) => ticket.ticketStatusLabel === TicketStatusLabels.PRE))
);

export const selectPostVisitTickets = createSelector([selectTickets], (tickets) =>
  getTicketRows(tickets.filter((ticket) => ticket.ticketStatusLabel === TicketStatusLabels.POST))
);

export const selectWaitingForApprovalTickets = createSelector([selectTickets], (tickets) =>
  getInboxRows(tickets.filter((ticket) => ticket.ticketStatus === TicketStatuses.WAITING_FOR_APPROVAL))
);

export const selectWaitingForApprovalTicketsAmount = createSelector(
  [selectWaitingForApprovalTickets],
  (tickets) => tickets.length
);
