import React, { useEffect, useMemo, useState } from 'react';
import { Table, TableContainer, TableHead } from '@mui/material';
import { debounce } from 'lodash';
import './TableBase.scss';
import { TableBaseProps, TableDataItem } from './table.types';
import { defaultRowOptions, defaultRowsInTable, getFilteredTableItems, OrderType } from './table-utils';
import TableHeaderRow from './TableHeaderRow/TableHeaderRow';
import TableToolbar from './TableToolbar/TableToolbar';
import TablePaginationBase from './TablePaginationBase/TablePaginationBase';
import TableBodyRows from './TableBodyRows/TableBodyRows';
import { SortByColumnKeys, SortByColumnValues } from 'views/common/enums/sort-by-column-keys.enum';

const TableBase = <T extends TableDataItem>({
  tableHeaders,
  tableData,
  sortBy,
  addButtonText,
  currentPage = 0,
  rowsInTable = defaultRowsInTable,
  rowsOptions = defaultRowOptions,
  customRenderers,
  sortWildcard,
  onRowClickFunction,
  tabProp
}: TableBaseProps<T>) => {
  const [order, setOrder] = useState<OrderType>(OrderType.ASCENDING);
  const [orderBy, setOrderBy] = useState<string>(sortBy);
  const [page, setPage] = useState(currentPage);
  const [rowsPerPage, setRowsPerPage] = useState(rowsInTable);
  const [searchValue, setSearchValue] = useState('');
  const [wildcardValue, setWildcardValue] = useState<T[keyof T] | undefined>(sortWildcard);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    setWildcardValue(undefined);
    setOrder(order === OrderType.ASCENDING ? OrderType.DESCENDING : OrderType.ASCENDING);
    setOrderBy(property);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage({ key: event.target.value, value: parseInt(event.target.value, 10) });
    setPage(0);
  };
  const handleSearch = (searchTerm: string | undefined) => {
    setSearchValue(searchTerm ?? '');
    setPage(0);
  };

  const debouncedChangeHandler = useMemo(() => debounce(handleSearch, 200), []);

  useEffect(() => {
    // resets page when a different tab is selected, e.g. in Tickets List there are 3 tabs with different data - on tab change the page is set to the first one
    if (tabProp) setPage(0);
  }, [tabProp]);

  useEffect(() => {
    if (orderBy === SortByColumnKeys.CARE_COORDINATOR) setOrderBy(SortByColumnValues.CARE_COORDINATOR);
  }, [orderBy]);

  return (
    <div className="table">
      <TableToolbar setSearchValue={debouncedChangeHandler} addButtonText={addButtonText} />
      <TableContainer>
        <Table>
          <TableHead className="table__head">
            <TableHeaderRow<T>
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
              tableHeaders={tableHeaders}
            />
          </TableHead>
          <TableBodyRows<T>
            tableData={tableData}
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage?.value as number}
            searchBy={searchValue}
            customRenderers={customRenderers}
            sortWildcard={wildcardValue}
            onRowClickFunction={onRowClickFunction}
          />
        </Table>
      </TableContainer>
      <TablePaginationBase
        count={getFilteredTableItems(tableData, searchValue).length}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsOptions}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};
export default TableBase;
