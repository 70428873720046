import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import './selectDetails.scss';
import { IconButton, TextField, Card } from '@mui/material';
import { CardSelectOption } from 'types/select-option.types';
import UploadFileInputBase from '../../UploadFileInputBase/UploadFileInputBase';
import { useSelectSelectedScenario } from 'redux-store/features/scenarios/scenarios.hooks';
import { fileUploadApi } from 'services/data/fileUpload';

interface CardOptionsComponentProps {
  cardItem: CardSelectOption;
  handleChangeCardTextField: Function;
  handleRemoveCardItem: Function;
  index: number;
}

const CardOptionsWrapper: React.FC<CardOptionsComponentProps> = ({
  cardItem,
  handleChangeCardTextField,
  handleRemoveCardItem,
  index
}) => {
  const scenario = useSelectSelectedScenario();

  const handleFileInput = (e: any) => {
    if (scenario) {
      const file: File = e.target.files[0];
      fileUploadApi.handleFileUpload(scenario?.name, file, index, handleChangeCardTextField);
    }
  };

  return (
    <Card>
      <TextField
        label="key"
        autoComplete="off"
        defaultValue={cardItem.key}
        onChange={(e) => handleChangeCardTextField('key', index, e.currentTarget.value)}
      />
      <TextField
        label="value"
        autoComplete="off"
        defaultValue={cardItem.value}
        onChange={(e) => handleChangeCardTextField('value', index, e.currentTarget.value)}
      />
      <UploadFileInputBase buttonDisabled={cardItem.url.length > 0} inputOnChange={(e) => handleFileInput(e)} />
      {cardItem.url.length > 0 && (
        <div className="image-container">
          <img src={cardItem.url} alt="card-img" />
        </div>
      )}
      <IconButton className="delete-button" aria-label="delete" onClick={() => handleRemoveCardItem(index)}>
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Card>
  );
};

export default CardOptionsWrapper;
