import React, { useCallback, useMemo } from 'react';
import { AxiosError } from 'axios';
import ListAltIcon from '@mui/icons-material/ListAlt';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ScenarioData, ScenariosTableRow } from 'types/scenario-management.types';
import clsx from 'clsx';
import { TextContentPath } from 'i18n/i18n-config';

export const useScenariosTableRows = (scenarios: ScenarioData[], fetchingDataStatus: boolean): ScenariosTableRow[] => {
  const createRows = useCallback(
    (value: ScenarioData[]): ScenariosTableRow[] => {
      return value.map((scenario) => {
        return {
          name: scenario.name,
          patientFormIcon: <ListAltIcon fontSize="small" className={clsx(!scenario.valid && 'invalid-scenario')} />,
          editIcon: <EditIcon fontSize="small" />,
          deleteIcon: <DeleteIcon fontSize="small" />,
          valid: scenario.valid,
          key: scenario.id!!.toString()
        };
      });
    },
    [scenarios, fetchingDataStatus] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return useMemo(() => createRows(Array.from(scenarios.values())), [createRows]); // eslint-disable-line react-hooks/exhaustive-deps
};

export const handleApiError = function (error: Error | AxiosError) {
  console.log(error);
};

const regexScenarioName = /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[a-zA-Z0-9]+|[\u3131-\uD79D]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤]+/u;

export const validateNewScenarioName = (inputName: string, isInputNameUsed: boolean): TextContentPath | '' => {
  if (inputName.length < 3) {
    return 'scenarioManagement.newNameErrors.nameIsToShort';
  } else if (isInputNameUsed) {
    return 'scenarioManagement.newNameErrors.nameExists';
  } else if (!regexScenarioName.test(inputName)) {
    return 'scenarioManagement.newNameErrors.nameIsInvalid';
  } else {
    return '';
  }
};
