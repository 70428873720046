import React from 'react';
import TextBase from 'components/TextBase/TextBase';
import { CareCoordinator } from 'types/care-coordinator.types';
import './CareCoordinatorCell.scss';

interface CareCoordinatorCellProps {
  careCoordinator: CareCoordinator | undefined;
  colorClassName: string;
}

const CareCoordinatorCell: React.FC<CareCoordinatorCellProps> = ({ careCoordinator, colorClassName }) => {
  return (
    <React.Fragment>
      {!!careCoordinator && (
        <div className="care-coordinator">
          <img src={careCoordinator.imagePath} alt="profile pic" className="avatar" />
          <TextBase
            variant="caption"
            className={colorClassName}
          >{`${careCoordinator.name} ${careCoordinator.surname}`}</TextBase>
        </div>
      )}
    </React.Fragment>
  );
};

export default CareCoordinatorCell;
