export enum ShapesNames {
  MESSAGE_SHAPE = 'app.MessageShape',
  SELECT_SHAPE = 'app.SelectShape',
  END_SHAPE = 'app.EndShape',
  START_SHAPE = 'app.StartShape',
  INPUT_SHAPE = 'app.InputShape',
  STANDARD_LINK_SHAPE = 'standard.Link',
  SELECT_OPTION_SHAPE = 'app.SelectOptionShape'
}

export enum ShapesColors {
  MESSAGE_SHAPE = '#FFFF00',
  SELECT_SHAPE = '#006400',
  END_SHAPE = '#0100c8',
  START_SHAPE = '#0100c8',
  INPUT_SHAPE = '#00c800',
  LINK_SHAPE = '#000000',
  SELECT_OPTION_SHAPE = '#006400'
}

export enum ShapesDefaultNames {
  MESSAGE_SHAPE = 'Message',
  SELECT_SHAPE = 'Select',
  END_SHAPE = 'End',
  START_SHAPE = 'Start',
  INPUT_SHAPE = 'Input',
  LINK_SHAPE = 'Link',
  SELECT_OPTION = 'Select option'
}

export const defaultTextAttributes = {
  fill: '#000000',
  fontFamily: 'Arial Narrow',
  fontWeight: 'Normal',
  fontSize: 12,
  strokeWidth: 0,
  refX: 34,
  refY: 0.5,
  textVerticalAnchor: 'middle',
  textAnchor: 'left'
};

export const defaultMarkupAttributes = [
  {
    tagName: 'rect',
    selector: 'body'
  },
  {
    tagName: 'rect',
    selector: 'iconBackground'
  },
  {
    tagName: 'text',
    selector: 'label'
  }
];

export const routerAndConnectorConfig = {
  defaultRouter: {
    name: 'manhattan',
    args: {
      padding: 5,
      step: 20,
      startDirections: ['bottom'],
      endDirections: ['top']
    }
  },
  defaultConnector: {
    name: 'rounded',
    args: {
      radius: 3
    }
  }
};

export const defaultShapeSize = {
  width: 120,
  height: 40
};

export const sourceAnchorProperties = {
  name: 'bottom',
  args: {
    rotate: false
  }
};

export const targetAnchorProperties = {
  name: 'top',
  args: {
    rotate: true
  }
};

export const targetAnchorPropertiesFix = {
  name: 'modelCenter',
  args: {
    rotate: true
  }
};
