import { useContextApi } from 'context-store/api/hooks/useContextApi';
import { useAppInit } from 'context-store/api/hooks/useAppInit';
import { createContext, useContext } from 'react';
import { API } from 'services/data/types';

interface ApiProviderState {
  api: API | undefined;
}

const initState: ApiProviderState = {
  api: undefined
};

export const ApiContext = createContext(initState);

const ApiProvider: React.FC = ({ children }) => {
  const api = useContextApi();
  useAppInit(api);

  return <ApiContext.Provider value={{ api }}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  const { api } = useContext(ApiContext);
  return api;
};

export default ApiProvider;
