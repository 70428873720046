import { RootState } from '../../store-config';
import { ScenarioData } from 'types/scenario-management.types';
import { scenariosAdapter } from 'redux-store/features/scenarios/scenarios.slice';
import { createSelector } from '@reduxjs/toolkit';

const selectScenarios = (state: RootState): ScenarioData[] =>
  scenariosAdapter.getSelectors().selectAll(state.scenariosState);

const getIsLoading = (state: RootState) => state.scenariosState.loading;
const getSelectedScenario = (state: RootState) => state.scenariosState.selectedScenario;

export const selectScenariosList = createSelector([selectScenarios], (scenarios) => scenarios);

export const selectScenariosLoading = createSelector([getIsLoading], (isLoading): boolean => isLoading);

export const selectSelectedScenario = createSelector(
  [getSelectedScenario],
  (scenario): ScenarioData | undefined => scenario
);

export const selectScenariosNames = createSelector([selectScenarios], (scenarios): string[] => {
  if (scenarios) {
    return scenarios.map((scenario) => {
      return scenario.name;
    });
  } else {
    return [];
  }
});
