import React from 'react';
import { Toolbar } from '@mui/material';
import './ToolbarBase.scss';
import BreadcrumbsBase from '../BreadcrumbsBase/BreadcrumbsBase';

const ToolbarBase: React.FC = () => {
  return (
    <Toolbar className="toolbar">
      <BreadcrumbsBase />
    </Toolbar>
  );
};

export default ToolbarBase;
