import React from 'react';
import './TableToolbar.scss';
import { MemoizedIconComponent } from '../../Icon/Icon';
import ButtonBase from '../../ButtonBase/ButtonBase';
import Searchbar from './Searchbar/Searchbar';
import { TextContentPath } from '../../../i18n/i18n-config';

interface TableToolbarProps {
  setSearchValue: (event: string) => void;
  addButtonText?: TextContentPath;
}

const TableToolbar: React.FC<TableToolbarProps> = ({ setSearchValue, addButtonText }) => {
  return (
    <div className="table-toolbar">
      <div className="table-toolbar__section">
        <ButtonBase startIcon={<MemoizedIconComponent name="Filter" />} content={'table.filters'} color={'inherit'} />
        <Searchbar setSearchValue={setSearchValue} />
      </div>
      {!!addButtonText && (
        <div className="table-toolbar__section">
          <ButtonBase startIcon={<MemoizedIconComponent name="Plus" />} content={addButtonText} color={'inherit'} />
          <ButtonBase
            startIcon={<MemoizedIconComponent name="Upload" />}
            content={'table.uploadCsv'}
            color={'inherit'}
          />
        </div>
      )}
    </div>
  );
};

export default TableToolbar;
