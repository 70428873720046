import React, { useEffect, useState } from 'react';
import { SnackbarOrigin } from '@mui/material/Snackbar/Snackbar';
import { Snackbar } from '@mui/material';
import { useAppDispatch } from 'redux-store/hooks';
import { useSelectSnackbarContent } from 'redux-store/features/snackbar/snackbar.hooks';
import { MessageContent, removeMessageFromQueue } from 'redux-store/features/snackbar/snackbar.slice';
import { ListenerEvents } from 'views/common/enums/listener-events.enum';
import SnackbarBaseContent from './SnackbarBaseContent';

const defaultAnchorOrigin: SnackbarOrigin = { vertical: 'top', horizontal: 'center' };

const SnackbarBase: React.FC = () => {
  const [currentSnackbar, setCurrentSnackbar] = useState<MessageContent | null>(null);
  const dispatch = useAppDispatch();
  const contents = useSelectSnackbarContent();

  useEffect(() => {
    if (contents[0]?.key !== currentSnackbar?.key) {
      setCurrentSnackbar(contents[0]);
    }
  }, [contents]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = (
    key: Pick<MessageContent, 'key'>,
    event?: React.SyntheticEvent | Event,
    reason?: string
  ): void => {
    if (reason === ListenerEvents.CLICKAWAY) return;
    if (!!key) dispatch(removeMessageFromQueue(key));
  };

  return (
    <>
      {!!currentSnackbar && (
        <Snackbar
          autoHideDuration={currentSnackbar.timeout}
          anchorOrigin={defaultAnchorOrigin}
          open={!!currentSnackbar.content}
          onClose={(event, reason) => handleClose({ key: currentSnackbar.key }, event, reason)}
          onClick={() => handleClose({ key: currentSnackbar.key })}
          key={currentSnackbar.key}
        >
          <div>
            <SnackbarBaseContent
              messageType={currentSnackbar.type}
              messageContent={currentSnackbar.content}
              messageFreeText={currentSnackbar.freeText}
              ticketId={currentSnackbar.ticketId}
            />
          </div>
        </Snackbar>
      )}
    </>
  );
};

export default SnackbarBase;
