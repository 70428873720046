export enum MessageTypes {
  ERROR_MSG,
  SUCCESS_MSG
}

export enum MessageKeyTypes {
  ERROR = 'error',
  SUCCESS = 'success'
}

export enum MessageContentTypes {
  ERROR_UPDATE = 'errorMessage',
  SUCCESS_UPDATE = 'successMessage'
}
