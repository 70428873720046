import { generateFileKey, generateUploadFileUrl, getListObject, uploadFile } from 'aws/upload';
import { openPopup, PopupType } from 'utils/popup-utils';

const handleFileUpload = (scenarioName: string, file: File, index: number, handleChangeCardTextField: Function) => {
  getListObject().on('success', (res) => {
    if (res.data && res.data.Contents) {
      const filesList = res.data.Contents.map((item) => {
        return item.Key;
      });
      if (filesList.indexOf(generateFileKey(scenarioName, file.name)) > -1) {
        openPopup(PopupType.IMAGE_WITH_THIS_NAME_EXIST);
      } else {
        uploadFile(file, scenarioName).on('success', () =>
          handleChangeCardTextField('url', index, generateUploadFileUrl(file.name, scenarioName))
        );
        openPopup(PopupType.IMAGE_UPLOADED);
      }
    }
  });
};

export const awsApi = { handleFileUpload };
