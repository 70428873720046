import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, TypographyProps } from '@mui/material';
import { TextContentPath } from 'i18n/i18n-config';

interface TextBaseProps extends TypographyProps {
  content?: TextContentPath;
  children?: string;
  className?: string;
}

const TextBase: React.FC<TextBaseProps> = ({ content, children, className, ...materialProps }) => {
  const { t } = useTranslation();
  const renderedText = content ? t(content) : children;

  //TODO: create function for sx
  return (
    <Typography className={className} {...materialProps}>
      {renderedText}
    </Typography>
  );
};

export default TextBase;
