import React from 'react';
import './VisitsCard.scss';
import { IconButton } from '@mui/material';
import { Ticket } from '../../../../../types/tickets.type';
import CardBase from '../../../../../components/CardBase/CardBase';
import { MemoizedIconComponent } from '../../../../../components/Icon/Icon';
import TextBase from '../../../../../components/TextBase/TextBase';
import { TicketStatuses } from '../../../../common/enums/ticket.enum';
import { VisitStatuses } from '../../../../common/enums/visit.enum';
import DateTimeSection from './DateTimeSection/DateTimeSection';

interface VisitsReportsProps {
  currentTicket: Ticket;
}

const hideDateTime = (ticketStatus: TicketStatuses | undefined) =>
  ticketStatus !== TicketStatuses.VISIT_SCHEDULED &&
  ticketStatus !== TicketStatuses.VISIT_RESCHEDULED &&
  ticketStatus !== TicketStatuses.VISIT_IN_PROGRESS;

const showTitleText = (ticketStatus: TicketStatuses | undefined) => {
  switch (ticketStatus) {
    case TicketStatuses.VISIT_SCHEDULED:
    case TicketStatuses.VISIT_RESCHEDULED:
      return VisitStatuses.SCHEDULED_VISIT;
    case TicketStatuses.VISIT_IN_PROGRESS:
      return VisitStatuses.VISIT_IN_PROGRESS;
    case TicketStatuses.WAITING_FOR_APPROVAL:
      return VisitStatuses.VISIT_REPORT;
    default:
      return VisitStatuses.VISIT_REPORT;
  }
};

const VisitsCard: React.FC<VisitsReportsProps> = ({ currentTicket }) => {
  return (
    <CardBase styles="reports-card">
      <div className="reports-card__title">
        <MemoizedIconComponent name={hideDateTime(currentTicket.ticketStatus) ? 'File' : 'Calendar'} />
        <div>
          <TextBase content={showTitleText(currentTicket.ticketStatus)} className="reports-card__title-text" />
          {hideDateTime(currentTicket.ticketStatus) && <DateTimeSection currentTicket={currentTicket} />}
        </div>
      </div>
      <div className="reports-card__section">
        {hideDateTime(currentTicket.ticketStatus) ? (
          <IconButton>
            <MemoizedIconComponent name={'ChevronRight'} />
          </IconButton>
        ) : (
          <DateTimeSection currentTicket={currentTicket} darkPrimaryColor={true} />
        )}
      </div>
    </CardBase>
  );
};

export default VisitsCard;
