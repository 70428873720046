import React from 'react';
import { Divider } from '@mui/material';
import './PatientDetailsSection.scss';
import TextBase from 'components/TextBase/TextBase';
import { emailAddress, phoneNumber } from 'views/DataManagement/Patients/patients.utils';
import { Patient } from 'types/patient.types';

interface PatientDetailsSectionProps {
  currentPatient: Patient;
}

const PatientDetailsSection: React.FC<PatientDetailsSectionProps> = ({ currentPatient }) => {
  return (
    <>
      <Divider />
      <div className="patient-details">
        <TextBase content={'ticket.details.patientDetails'} variant="h6" />
        <div className="patient-details__contact">
          <a
            className="patient-details__contact-phone text-decoration-unset"
            href={currentPatient.contact ? `tel:${phoneNumber(currentPatient.contact)}` : ''}
          >
            <TextBase variant="subtitle2">{phoneNumber(currentPatient.contact)}</TextBase>
          </a>
          <a
            className="patient-details__contact-email text-decoration-unset"
            href={currentPatient.contact ? `mailto:${emailAddress(currentPatient.contact)}` : ''}
          >
            <TextBase variant="subtitle2">{emailAddress(currentPatient.contact)}</TextBase>
          </a>
          <TextBase className="patient-details__contact-address" variant="subtitle2">
            {currentPatient.address}
          </TextBase>
        </div>
      </div>
      <Divider />
    </>
  );
};

export default PatientDetailsSection;
