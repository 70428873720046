import { dia } from '@clientio/rappid';
import { TextContentPath } from '../i18n/i18n-config';

export interface ListSelectOption {
  key: string;
  value: string | number | TextContentPath | Date | undefined;
  error?: string;
}

export interface CardSelectOption extends ListSelectOption {
  url: string;
}

export type SelectOption = ListSelectOption | CardSelectOption;

export const enum SelectType {
  LIST = 'list',
  CARD = 'card'
}

export interface embeddedCellsPosition {
  id: string;
  position: dia.Point;
}
