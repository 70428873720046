import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { PatientWithId } from 'types/patient.types';
import { fetchPatients } from './patients.actions';

export const patientsAdapter = createEntityAdapter<PatientWithId>({
  selectId: (patient) => patient.id,
  sortComparer: (a, b) => a.id.localeCompare(b.id)
});

export type PatientsState = EntityState<PatientWithId>;

const initialState: PatientsState = patientsAdapter.getInitialState({});

const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    sePatients: (state, action: PayloadAction<PatientWithId[]>) => {
      patientsAdapter.setAll(state as PatientsState, action.payload);
    },
    updatePatient: (state, action: PayloadAction<PatientWithId>) => {
      patientsAdapter.upsertOne(state as PatientsState, action.payload);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPatients.fulfilled, (state, { payload }) => {
      if (payload) {
        patientsAdapter.setAll(state as PatientsState, payload);
      }
    });
  }
});

export const { sePatients, updatePatient } = patientsSlice.actions;
const { reducer: patientsReducer } = patientsSlice;

export default patientsReducer;
