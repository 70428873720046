import React from 'react';
import './selectDetails.scss';
import { Button } from '@mui/material';
import { uniqueId } from 'lodash';
import ListOption from './ListOption';
import { ListSelectOption } from 'types/select-option.types';

interface listOptionsComponentProps {
  listOptions: ListSelectOption[];
  handleChangeListOptionTextField: Function;
  handleRemoveListItem: Function;
  handleAddListOption: Function;
}

const ListOptionsWrapper: React.FC<listOptionsComponentProps> = ({
  listOptions,
  handleChangeListOptionTextField,
  handleRemoveListItem,
  handleAddListOption
}) => {
  return (
    <div>
      <h3>List Options: </h3>
      <ul>
        {listOptions.map((item: ListSelectOption, index) => {
          return (
            <ListOption
              key={uniqueId()}
              listItem={item}
              handleChangeListOptionTextField={handleChangeListOptionTextField}
              handleRemoveListItem={handleRemoveListItem}
              index={index}
            />
          );
        })}
      </ul>
      <Button variant="outlined" size="small" onClick={() => handleAddListOption()}>
        Add option
      </Button>
    </div>
  );
};

export default ListOptionsWrapper;
