import React from 'react';
import { useSnackbarMessage } from 'views/common/hooks/useSnackbarMessage';
import { MessageTypes } from 'views/common/enums/message-types.enum';
import { TextContentPath } from 'i18n/i18n-config';

interface SnackbarBaseContentProps {
  messageType: MessageTypes;
  messageContent?: TextContentPath;
  messageFreeText?: string;
  ticketId?: string;
}

const SnackbarBaseContent: React.FC<SnackbarBaseContentProps> = ({
  messageType,
  messageContent,
  messageFreeText,
  ticketId
}) => {
  const messageComponent = useSnackbarMessage({ messageType, messageContent, messageFreeText, ticketId });

  return <React.Fragment>{messageComponent}</React.Fragment>;
};

export default SnackbarBaseContent;
