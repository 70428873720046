import React from 'react';
import TextBase from 'components/TextBase/TextBase';
import { TextContentPath } from 'i18n/i18n-config';

interface SuccessMessageProps {
  successMessage?: TextContentPath;
  successFreeText?: string;
  ticketId?: string;
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({ successMessage, successFreeText, ticketId }) => {
  return (
    <>
      <TextBase content={successMessage} variant="subtitle2">
        {successFreeText}
      </TextBase>
      {!!ticketId && <TextBase variant="subtitle2">{`#${ticketId}`}</TextBase>}
    </>
  );
};

export default SuccessMessage;
