import { TextContentPath } from '../i18n/i18n-config';
import { IconName } from '../components/Icon/Icon';
import { AppPaths } from './app-routing';

export interface MenuItem {
  title: TextContentPath & string;
  path: AppPaths;
  icon?: IconName;
  isEnabled?: boolean;
  children?: MenuItem[];
}

export const generalMenuItems: MenuItem[] = [
  {
    title: 'menuItems.home',
    path: AppPaths.HOME,
    icon: 'Home'
  },
  {
    title: 'menuItems.calendar',
    path: AppPaths.HOME,
    icon: 'Calendar'
  },
  {
    title: 'menuItems.inbox',
    path: AppPaths.INBOX,
    icon: 'Mail',
    isEnabled: true
  }
];

export const managerMenuItems: MenuItem[] = [
  {
    title: 'menuItems.tickets',
    path: AppPaths.TICKETS,
    icon: 'Flag',
    isEnabled: true
  },
  {
    title: 'menuItems.reportBuilder',
    path: AppPaths.HOME,
    icon: 'BarChart2'
  },
  {
    title: 'menuItems.scenarioManagement',
    path: AppPaths.SCENARIO_MANAGEMENT,
    isEnabled: true,
    icon: 'CheckSquare'
  },
  {
    title: 'menuItems.dataManagement',
    path: AppPaths.DATA_MANAGEMENT,
    icon: 'Database',
    isEnabled: true,
    children: [
      {
        title: 'menuItems.dataManagementPatients',
        path: AppPaths.DATA_MANAGEMENT_PATIENTS,
        isEnabled: true
      },
      {
        title: 'menuItems.dataManagementUsers',
        path: AppPaths.HOME
      },
      {
        title: 'menuItems.dataManagementServices',
        path: AppPaths.DATA_MANAGEMENT_SERVICES,
        isEnabled: true
      },
      {
        title: 'menuItems.dataManagementServiceProviders',
        path: AppPaths.HOME
      }
    ]
  }
];

export const breadcrumbNameMap: { [key: string]: string } = {
  [AppPaths.HOME]: 'menuItems.home',
  [AppPaths.TICKETS]: 'menuItems.tickets',
  [AppPaths.INBOX]: 'menuItems.inbox',
  [AppPaths.SCENARIO_BUILDER]: 'scenarioBuilder.title',
  [AppPaths.CHECKLIST_SCENARIO]: 'checklistScenario.title',
  [AppPaths.SCENARIO_MANAGEMENT]: 'menuItems.scenarioManagement',
  [AppPaths.DATA_MANAGEMENT]: 'menuItems.dataManagement',
  [AppPaths.DATA_MANAGEMENT_PATIENTS]: 'menuItems.dataManagementPatients',
  [AppPaths.DATA_MANAGEMENT_SERVICES]: 'menuItems.dataManagementServices'
};
