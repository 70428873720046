import { Action, configureStore, EntityState, ThunkAction } from '@reduxjs/toolkit';
import rootReducer from './root-reducer';
import { CareCoordinatorState } from './features/care-coordinator/care-coordinator.slice';
import { SnackbarState } from './features/snackbar/snackbar.slice';
import { ModalState } from './features/modal/modal.slice';
import { Ticket } from 'types/tickets.type';
import { ScenariosState } from 'redux-store/features/scenarios/scenarios.slice';
import { ServicesState } from 'redux-store/features/services/services.slice';
import { ServiceProvidersState } from 'redux-store/features/service-providers/service-providers.slice';
import { PatientsState } from 'redux-store/features/patients/patients.slice';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export type RootState = {
  careCoordinatorState: CareCoordinatorState;
  ticketsState: EntityState<Ticket> & { selectedTicket?: Ticket };
  snackbarState: SnackbarState;
  modalState: ModalState;
  scenariosState: ScenariosState;
  servicesState: ServicesState;
  serviceProvidersState: ServiceProvidersState;
  patientsState: PatientsState;
};

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default store;
