const setDefaultValue = (selectedInput: HTMLElement | null, defaultValue = 0): void => {
  if (selectedInput instanceof HTMLInputElement && !selectedInput.value) selectedInput.value = String(defaultValue);
};

const forbiddenCharacters = (event: KeyboardEvent) => {
  return event.key === '-' || event.key === '+' || event.key === '.' || event.key === ',' || event.key === 'e';
};

export const validateOnKeyDown = (selectedInput: HTMLElement | null, defaultValue = 0): void =>
  selectedInput?.addEventListener('keydown', (event) => {
    if (forbiddenCharacters(event)) event.preventDefault();
    setDefaultValue(selectedInput, defaultValue);
  });

export const validateOnKeyUp = (selectedInput: HTMLElement | null, defaultValue = 0): void =>
  selectedInput?.addEventListener('keyup', () => {
    setDefaultValue(selectedInput, defaultValue);
  });
