import React from 'react';
import './../checklist-scenario-modal.scss';
import ButtonBase from './../../../../components/ButtonBase/ButtonBase';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import TextBase from '../../../../components/TextBase/TextBase';

interface StepsProps {
  handleNextStep: () => void;
  message: string | undefined;
}

const StartStep: React.FC<StepsProps> = ({ handleNextStep, message }) => {
  return (
    <div className="start-step">
      <ContentPasteIcon style={{ fontWeight: 'lighter', fontSize: '48px' }} />
      <TextBase variant={'h4'} content={'checklistScenario.beginChecklistScenario'} />
      <TextBase variant={'body1'}>{message ? message : ''}</TextBase>
      <ButtonBase onClick={handleNextStep} variant="contained" content={'checklistScenario.startAssessment'} />
    </div>
  );
};

export default StartStep;
