import { dia } from '@clientio/rappid';
import { ShapesNames } from 'shapes/utils';
import { DefaultChecklistSelectNode, Node } from 'types/scenario-management.types';
import { SelectType } from 'types/select-option.types';
import { hasContentAnyText, isEmptyAnyObjectPropertyValue } from 'utils/generic-utils';

type AppCell = dia.Cell & { type: ShapesNames };
type UploadJSONFileData = { scenario: AppCell[] };

export const isValidScenario = (graph: dia.Graph, checklistNodes: Node<ShapesNames>[]): boolean => {
  const allElements = graph.getElements().filter((element) => element.prop('type') !== ShapesNames.SELECT_OPTION_SHAPE);
  const startShape = allElements.find((element) => element.prop('type') === ShapesNames.START_SHAPE);
  const endShape = allElements.find((element) => element.prop('type') === ShapesNames.END_SHAPE);

  if (!startShape || !endShape) return false;
  const isStartConnectedToEnd = graph.isNeighbor(startShape, endShape);
  if (isStartConnectedToEnd) return false;
  if (checklistNodes.length !== allElements.length) return false; // check if there are standalone flows
  if (checklistNodes[checklistNodes.length - 1].type !== ShapesNames.END_SHAPE) return false; // check if main flow has end shape
  if (!hasEveryChecklistNodeContent(checklistNodes)) return false; // check if every checklist node (from main flow) has content - text or image
  const elementsValidation = allElements.map((element) => isElementProperlyConnected(element, graph)); // check if each node has proper connection
  return !elementsValidation.includes(false);
};

const isElementProperlyConnected = (element: dia.Element, graph: dia.Graph): boolean => {
  const hasElementOneConnectedOutboundLink = graph.getConnectedLinks(element, { outbound: true }).length === 1;
  const hasElementOneConnectedInboundLink = graph.getConnectedLinks(element, { inbound: true }).length === 1;

  switch (element.prop('type')) {
    case ShapesNames.START_SHAPE:
      return hasElementOneConnectedOutboundLink;
    case ShapesNames.END_SHAPE:
      return hasElementOneConnectedInboundLink;
    case ShapesNames.SELECT_OPTION_SHAPE:
      return true;
    default:
      if (hasElementOneConnectedInboundLink) {
        return hasElementOneConnectedOutboundLink;
      }
      return false;
  }
};

export const isInvalidScenarioFile = (scenarioData: UploadJSONFileData) => {
  const { scenario: scenarioCells } = scenarioData;
  if (!scenarioCells || scenarioCells.length === 0) return true;
  const shapeNames = Object.values(ShapesNames);
  const existsStartShape = scenarioCells.find((cell) => cell.type === ShapesNames.START_SHAPE);
  if (!existsStartShape) return true;
  return !!scenarioCells.find((cell) => !shapeNames.includes(cell.type));
};

export const getChecklistNodes = (graph: dia.Graph): Node<ShapesNames>[] => {
  const sortedNodes = [] as dia.Element[];
  const allNodes = graph.getElements();
  const startNode = allNodes.find((node) => node.get('type') === ShapesNames.START_SHAPE);
  if (startNode) {
    sortedNodes.push(startNode);
    allNodes.forEach(() => {
      const lastElementInSortedNodes = sortedNodes[sortedNodes.length - 1];
      const [nextShape] = graph.getNeighbors(lastElementInSortedNodes, { outbound: true });
      nextShape && sortedNodes.push(nextShape);
    });
    return sortedNodes.map((node) => ({ type: node.get('type'), data: node.get('data') }));
  }
  return [];
};

const hasEveryChecklistNodeContent = (nodes: Node<ShapesNames>[]): boolean => {
  const checklistNodesValidation = nodes.map((node) => {
    const { contents } = node.data;
    if (!contents) return false;
    if (node.type === ShapesNames.SELECT_SHAPE && !haveSelectOptionsContent(node as DefaultChecklistSelectNode))
      return false; // check if select options have label, text or url
    return !!contents.blocks.find((block) => hasContentAnyText(block.text) || block.type === 'atomic');
  });

  return !checklistNodesValidation.includes(false);
};

const haveSelectOptionsContent = (node: DefaultChecklistSelectNode): boolean => {
  const { card, list, type } = node.data;
  if (!card || !list || !type || !node.data[type]?.length) return false;
  if (type === SelectType.LIST) {
    return !list.find((option) => isEmptyAnyObjectPropertyValue(option));
  }
  return !card.find((option) => isEmptyAnyObjectPropertyValue(option));
};

export enum LocalStorageVariables {
  CURRENT_SCENARIO_ID = 'currentScenarioId'
}
