import React from 'react';
import ModalBase from 'components/ModalBase/ModalBase';
import ButtonBase from 'components/ButtonBase/ButtonBase';
import TextBase from 'components/TextBase/TextBase';
import { useModal } from 'views/common/hooks/useModal';
import { ModalTypes } from 'views/common/enums/modal-types.enum';
import './ConfirmAssignedServicesModal.scss';
import { useSelectSelectedTicket, useSelectTickets } from 'redux-store/features/tickets/tickets.hooks';
import CheckIcon from 'assets/styled-icons/check/CheckIcon';
import { useMessages } from 'views/common/hooks/useMessage';
import { useTicketUpdate } from 'views/common/hooks/useTicketUpdate';
import { TicketStatuses } from 'views/common/enums/ticket.enum';

const ConfirmAssignedServicesModal: React.FC = () => {
  const { isModalOpen, onModalClose } = useModal(ModalTypes.CONFIRM_ASSIGNED_SERVICES);
  const { onModalClose: closeModalServicesAssignment } = useModal(ModalTypes.SERVICES_ASSIGNMENT);
  const { messages } = useMessages('ticket');
  const ticket = useSelectSelectedTicket();
  const tickets = useSelectTickets();
  const { handleTicketUpdate } = useTicketUpdate(messages);
  const confirmAssignedServices = () => {
    const updatedTicket = tickets.find((item) => item.id === ticket?.id);
    updatedTicket && handleTicketUpdate({ ...updatedTicket, ticketStatus: TicketStatuses.WAITING_FOR_APPROVAL });
    onModalClose();
    closeModalServicesAssignment();
  };

  const handleModalClose = () => {
    onModalClose();
  };

  return (
    <ModalBase isModalOpen={isModalOpen} closeModal={onModalClose} customHeader={true}>
      <div className="approve-report-modal">
        <CheckIcon />
        <TextBase variant={'h4'} content={'ticket.assignServicesModal.assignServices'} fontWeight={600} />
        <TextBase content={'ticket.assignServicesModal.assignServicesInfo'} fontWeight={400} />
        <div className="modal__btn-group">
          <ButtonBase
            content={'common.confirm'}
            size={'large'}
            type={'button'}
            variant={'contained'}
            onClick={() => confirmAssignedServices()}
          />
          <ButtonBase content={'common.cancel'} disableElevation size={'large'} onClick={handleModalClose} />
        </div>
      </div>
    </ModalBase>
  );
};

export default ConfirmAssignedServicesModal;
