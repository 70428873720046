import { useNavigate } from 'react-router-dom';

export enum AppPaths {
  TICKETS = 'tickets',
  INBOX = 'inbox',
  SCENARIO_BUILDER = 'scenario-builder',
  CHECKLIST_SCENARIO = 'checklist-scenario',
  SCENARIO_MANAGEMENT = 'scenario-management',
  DATA_MANAGEMENT = 'data-management',
  DATA_MANAGEMENT_PATIENTS = 'data-management/patients',
  DATA_MANAGEMENT_SERVICES = 'data-management/services',
  HOME = '/',
  ALL_PATHS = '*'
}

export const useAppNavigation = () => {
  const navigate = useNavigate();

  const handleNavigateTo = (path: AppPaths) => {
    navigate(`../${path}`);
  };
  return handleNavigateTo;
};
