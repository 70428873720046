import React from 'react';
import './header.scss';

const Header: React.FC = ({ children }) => {
  return (
    <div className="header">
      <div className="logo">LOGO</div>
      {children}
    </div>
  );
};

export default Header;
