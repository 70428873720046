import React, { useEffect } from 'react';
import ModalBase from 'components/ModalBase/ModalBase';
import ButtonBase from 'components/ButtonBase/ButtonBase';
import { InitialFormState, useForm } from 'views/common/hooks/useForm';
import AutocompleteBase from 'components/AutocompleteBase/AutocompleteBase';
import TextFieldBase from 'components/TextFieldBase/TextFieldBase';
import { ListSelectOption } from 'types/select-option.types';
import { useAppDispatch } from 'redux-store/hooks';
import { fetchCareCoordinators } from 'redux-store/features/care-coordinator/care-coordinator.actions';
import { useSelectCareCoordinators } from 'redux-store/features/care-coordinator/care-coordinator.hooks';
import {
  getCareCoordinators,
  getScenarios,
  getSelectedCareCoordinator,
  getSelectedScenario,
  initialAssignmentFormState
} from '../patients.utils';
import { generateTicket } from 'utils/ticket-generator';
import './AssignCCToPatientModal.scss';
import { ModalTypes } from 'views/common/enums/modal-types.enum';
import { useModal } from 'views/common/hooks/useModal';
import { addMessageToQueue } from 'redux-store/features/snackbar/snackbar.slice';
import { MessageTypes } from 'views/common/enums/message-types.enum';
import { useSelectScenarios } from 'redux-store/features/scenarios/scenarios.hooks';
import { fetchPatients, updatePatient } from 'redux-store/features/patients/patients.actions';
import { fetchTickets, createTicket } from 'redux-store/features/tickets/tickets.actions';
import { PatientWithId } from 'types/patient.types';

interface AssignCCToPatientModalProps {
  selectedPatient: PatientWithId;
}

export interface InitialModalFormState extends InitialFormState {
  patient: ListSelectOption | undefined;
  careCoordinator: ListSelectOption | undefined;
  checklistScenario: ListSelectOption | undefined;
}

const AssignCCToPatientModal: React.FC<AssignCCToPatientModalProps> = ({ selectedPatient }) => {
  const scenarios = useSelectScenarios();
  const { onChange, values, isFormInvalid } = useForm<InitialModalFormState>({
    ...initialAssignmentFormState,
    patient: { key: selectedPatient.id.toString(), value: selectedPatient.name }
  });
  const { isModalOpen, onModalClose } = useModal(ModalTypes.ASSIGN_CC_TO_PATIENT);
  const dispatch = useAppDispatch();
  const careCoordinators = useSelectCareCoordinators();

  const fetchAllTickets = async (): Promise<void> => {
    dispatch(fetchTickets());
  };

  const getCareCoordinatorsList = (): void => {
    dispatch(fetchCareCoordinators());
  };

  const addTicket = () => {
    const { careCoordinator, checklistScenario } = values;
    dispatch(fetchCareCoordinators());
    dispatch(
      createTicket({
        ...generateTicket({
          ticketOwner: getSelectedCareCoordinator(careCoordinator?.key ?? '', careCoordinators),
          checklistScenario: getSelectedScenario(checklistScenario?.key ?? '', scenarios),
          patientAssigned: selectedPatient
        })
      })
    );
  };

  const handlePatientUpdate = async () => {
    try {
      dispatch(
        updatePatient({
          ...selectedPatient,
          assignedCoordinator: values.careCoordinator?.value as string
        })
      );
      addTicket();
      dispatch(fetchPatients());
      dispatch(
        addMessageToQueue({
          key: 'assign-success',
          content: 'ticket.successAssignment',
          type: MessageTypes.SUCCESS_MSG
        })
      );
    } catch (error) {
      dispatch(
        addMessageToQueue({
          key: 'assign-error',
          content: 'ticket.errorAssignment',
          type: MessageTypes.SUCCESS_MSG
        })
      );
    }
  };

  const onSubmitForm = (): void => {
    if (isFormInvalid(values)) return;
    handlePatientUpdate();
    onModalClose();
  };

  useEffect(() => {
    fetchAllTickets();
    getCareCoordinatorsList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModalBase
      isModalOpen={isModalOpen}
      closeModal={onModalClose}
      headerText={'ccAssignmentToPatient.assignCareCoordinator'}
    >
      <form className="assignment-modal modal" onSubmit={onSubmitForm}>
        <TextFieldBase
          fullWidth
          disabled
          labelText={'ccAssignmentToPatient.patient'}
          name={'patient'}
          value={values?.patient?.value}
        />
        <AutocompleteBase
          fullWidth={true}
          autocompleteName={'careCoordinator'}
          label={'ccAssignmentToPatient.careCoordinator'}
          options={getCareCoordinators(careCoordinators)}
          selectedOption={values.careCoordinator}
          autocompletePlaceholder={'ccAssignmentToPatient.careCoordinatorName'}
          handleChange={onChange}
        />
        <AutocompleteBase
          fullWidth={true}
          autocompleteName={'checklistScenario'}
          label={'ccAssignmentToPatient.checklistScenario'}
          options={getScenarios(scenarios)}
          selectedOption={values.checklistScenario}
          autocompletePlaceholder={'ccAssignmentToPatient.checklistScenarioName'}
          handleChange={onChange}
        />
        <div className="modal__btn-group">
          <ButtonBase content={'common.cancel'} size={'large'} type={'button'} onClick={onModalClose} />
          <ButtonBase
            disabled={isFormInvalid(values)}
            content={'ccAssignmentToPatient.assignCoordinator'}
            variant={'contained'}
            disableElevation
            size={'large'}
            type={'submit'}
          />
        </div>
      </form>
    </ModalBase>
  );
};

export default AssignCCToPatientModal;
