import React, { ChangeEvent, FormEvent } from 'react';
import ModalBase from 'components/ModalBase/ModalBase';
import ButtonBase from 'components/ButtonBase/ButtonBase';
import { useModal } from 'views/common/hooks/useModal';
import { ModalTypes } from 'views/common/enums/modal-types.enum';
import TextFieldBase from 'components/TextFieldBase/TextFieldBase';
import { InitialFormState, useForm } from 'views/common/hooks/useForm';
import { ListSelectOption } from 'types/select-option.types';
import './NeedRevisitModal.scss';
import { useSelectSelectedTicket } from 'redux-store/features/tickets/tickets.hooks';
import { useTicketUpdate } from 'views/common/hooks/useTicketUpdate';
import { TicketStatuses, TicketStatusLabels } from 'views/common/enums/ticket.enum';
import { setSelectedTicket } from 'redux-store/features/tickets/tickets.slice';
import { useAppDispatch } from 'redux-store/hooks';
import { updatedMemo } from 'views/Tickets/tickets.utils';
import { useMessages } from 'views/common/hooks/useMessage';
import { fetchTickets } from 'redux-store/features/tickets/tickets.actions';

interface InitialNeedRevisitFormState extends InitialFormState {
  revisitReason: ListSelectOption | undefined;
}

const initialDisapproveReportModalState: InitialNeedRevisitFormState = {
  revisitReason: {
    key: 'revisitReason',
    value: ''
  }
};

const NeedRevisitModal: React.FC = () => {
  const { isModalOpen, onModalClose } = useModal(ModalTypes.NEED_REVISIT);
  const { onChange, values, isFormInvalid, handleResetForm } = useForm<InitialNeedRevisitFormState>(
    initialDisapproveReportModalState
  );
  const { messages } = useMessages('ticket');
  const ticket = useSelectSelectedTicket();
  const dispatch = useAppDispatch();

  const { handleTicketUpdate } = useTicketUpdate(messages);

  const updateReasonField = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (!values.revisitReason) return;
    onChange('revisitReason', { key: values.revisitReason?.key, value: e.target.value });
  };

  const isEndVisitFormValid = (values: InitialNeedRevisitFormState): boolean => {
    const optionalValues = ['revisitReason'] as (keyof InitialNeedRevisitFormState)[];
    return !isFormInvalid(values, optionalValues);
  };

  const handleConfirmRevisitModal = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (ticket && isEndVisitFormValid(values)) {
      handleTicketUpdate({
        ...ticket,
        ticketStatus: TicketStatuses.NEED_REVISIT,
        ticketStatusLabel: TicketStatusLabels.PRE,
        memo: updatedMemo(ticket, values.revisitReason?.value as string)
      });
      dispatch(setSelectedTicket({ id: '' }));
      dispatch(fetchTickets());
      onModalClose();
      handleResetForm();
    }
  };

  return (
    <ModalBase isModalOpen={isModalOpen} closeModal={onModalClose} headerText={'ticket.needRevisitModal.title'}>
      <form className="need-revisit-modal modal" onSubmit={(e) => handleConfirmRevisitModal(e)}>
        <TextFieldBase
          multiline
          minRows="2"
          maxRows="4"
          labelText="ticket.needRevisitModal.label"
          placeholderText="ticket.needRevisitModal.placeholder"
          value={values.revisitReason?.value}
          onChange={updateReasonField}
        />
        <div className="modal__btn-group">
          <ButtonBase
            disabled={!values.revisitReason?.value}
            content={'common.confirm'}
            size={'large'}
            type={'submit'}
            variant={'contained'}
          />
          <ButtonBase onClick={onModalClose} content={'common.cancel'} size={'large'} type={'button'} />
        </div>
      </form>
    </ModalBase>
  );
};

export default NeedRevisitModal;
