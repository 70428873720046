import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalTypes } from '../../../views/common/enums/modal-types.enum';

export type ModalState = { [key in ModalTypes]: boolean };

const initialState = Object.values(ModalTypes).reduce((acc, key) => {
  acc[key] = false;
  return acc;
}, {} as Record<string, boolean>) as ModalState;

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalOpen: (state, action: PayloadAction<ModalTypes>) => {
      state[action.payload] = true;
    },
    setModalClose: (state, action: PayloadAction<ModalTypes>) => {
      state[action.payload] = false;
    }
  }
});

export const { setModalOpen, setModalClose } = modalSlice.actions;
const { reducer: modalReducer } = modalSlice;

export default modalReducer;
