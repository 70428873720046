import React from 'react';
import { Trans } from 'react-i18next';
import { TextField } from '@mui/material';
import './PageChangerInput.scss';
import { validateOnKeyDown, validateOnKeyUp } from '../../../../utils/number-input-utils';
import { ListSelectOption } from '../../../../types/select-option.types';

interface PageChangerInputProps {
  count: number;
  page: number;
  rowsPerPage: ListSelectOption;
  handleChangePage: (event: unknown, newPage: number) => void;
}

const getTotalPages = (count: number, rowsPerPage: number): number => {
  return Math.ceil(count / rowsPerPage);
};

const PageChangerInput: React.FC<PageChangerInputProps> = ({ count, page, rowsPerPage, handleChangePage }) => {
  const numberInput = document.getElementById('pageChangerInput');
  validateOnKeyDown(numberInput, 1);
  validateOnKeyUp(numberInput, 1);
  return (
    <div className="page-changer">
      <TextField
        id="pageChangerInput"
        type="number"
        variant="outlined"
        size={'small'}
        value={page + 1}
        className="page-changer__input"
        onChange={(e) => handleChangePage(e, parseInt(e?.target?.value, 10) - 1)}
      />
      <Trans i18nKey="table.pageOfPages" pages={getTotalPages(count, rowsPerPage.value as number)}>
        of ${{ pages: getTotalPages(count, rowsPerPage.value as number) }}
      </Trans>
    </div>
  );
};

export default PageChangerInput;
