import React from 'react';
import './AssignedServices.scss';
import { Ticket } from '../../../../types/tickets.type';
import TextBase from '../../../../components/TextBase/TextBase';
import { TicketStatuses } from '../../../common/enums/ticket.enum';
import ServicesCard from './ServicesCard/ServicesCard';

interface AssignedServicesProps {
  currentTicket: Ticket;
}

const showPlaceholder = (ticketStatus: TicketStatuses) =>
  ticketStatus !== TicketStatuses.WAITING_FOR_APPROVAL &&
  ticketStatus !== TicketStatuses.DISAPPROVED &&
  ticketStatus !== TicketStatuses.CLOSED &&
  ticketStatus !== TicketStatuses.CANCELLED &&
  ticketStatus !== TicketStatuses.VISIT_FINISHED;

const AssignedServices: React.FC<AssignedServicesProps> = ({ currentTicket }) => {
  return (
    <div className="services">
      <div className="services__header">
        <TextBase content={'ticket.details.assignedServices'} variant="h6" className="services-title" />
        {currentTicket.careGrade && (
          <div className="services__header-grade">
            <TextBase variant="caption" fontWeight="600" className="color-dark">
              {currentTicket.careGrade}
            </TextBase>
          </div>
        )}
      </div>
      {showPlaceholder(currentTicket.ticketStatus) ? (
        <TextBase content={'ticket.details.noAssignedServices'} variant="caption" className="color-dark-disabled" />
      ) : (
        <ServicesCard currentTicket={currentTicket} />
      )}
    </div>
  );
};

export default AssignedServices;
