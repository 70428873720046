import React, { ChangeEvent, FormEvent, useEffect } from 'react';
import { add, addMinutes } from 'date-fns';
import ModalBase from 'components/ModalBase/ModalBase';
import ButtonBase from 'components/ButtonBase/ButtonBase';
import { InitialFormState, useForm } from 'views/common/hooks/useForm';
import { ListSelectOption } from 'types/select-option.types';
import DatePickerBase from 'components/DateTimePicker/DatePickerBase';
import TimePickerBase from 'components/DateTimePicker/TimePickerBase';
import { useModal } from 'views/common/hooks/useModal';
import { ModalTypes } from 'views/common/enums/modal-types.enum';
import { useSelectSelectedTicket } from 'redux-store/features/tickets/tickets.hooks';
import { TicketStatuses } from 'views/common/enums/ticket.enum';
import { Ticket } from 'types/tickets.type';
import { useTicketUpdate } from 'views/common/hooks/useTicketUpdate';
import './RescheduleVisitModal.scss';
import TextFieldBase from 'components/TextFieldBase/TextFieldBase';
import TextBase from 'components/TextBase/TextBase';
import { updatedMemo } from 'views/Tickets/tickets.utils';
import { fetchTickets } from 'redux-store/features/tickets/tickets.actions';
import { useAppDispatch } from 'redux-store/hooks';
import { useMessages } from 'views/common/hooks/useMessage';

interface InitialModalFormState extends InitialFormState {
  reason: ListSelectOption | undefined;
  ticketId: ListSelectOption | undefined;
  visitDate: ListSelectOption | undefined;
  startHour: ListSelectOption | undefined;
  endHour: ListSelectOption | undefined;
}

const initialRescheduleVisitModalState: InitialModalFormState = {
  reason: {
    key: 'reason',
    value: ''
  },
  ticketId: undefined,
  visitDate: undefined,
  startHour: undefined,
  endHour: undefined
};

const RescheduleVisitModal: React.FC = () => {
  const selectedTicket = useSelectSelectedTicket();
  const { isModalOpen, onModalClose } = useModal(ModalTypes.RESCHEDULE_VISIT);
  const { onChange, values, onSetError, isFormInvalid, handleResetForm } = useForm<InitialModalFormState>(
    initialRescheduleVisitModalState
  );
  const { messages } = useMessages('rescheduleVisit');
  const { handleTicketUpdate } = useTicketUpdate(messages);

  const dispatch = useAppDispatch();

  useEffect(() => {
    // selectedTicket is fetched from selector and then on component init injected into form values
    selectedTicket && onChange('ticketId', { key: selectedTicket?.id, value: selectedTicket?.id });
  }, [selectedTicket]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTimeChange = (eventTargetName: never, selectedValue: ListSelectOption | undefined) => {
    onChange(eventTargetName, selectedValue);
  };

  const updateReasonField = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (!values.reason) return;
    onChange('reason', { key: values.reason?.key, value: e.target.value });
  };

  const updateSelectedTicket = (startTime: string, endTime: string, startDate: string) => {
    if (!selectedTicket) return;
    const updatedTicket: Ticket = {
      ...selectedTicket,
      scheduledDate: { startTime, endTime, startDate },
      ticketStatus: TicketStatuses.VISIT_RESCHEDULED,
      lastUpdatedDate: new Date().toISOString(),
      memo: updatedMemo(selectedTicket, values.reason?.value ? values.reason?.value.toString() : '')
    };
    handleTicketUpdate(updatedTicket);
    dispatch(fetchTickets());
  };

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    const { startHour, endHour, visitDate } = values;
    if (!!startHour && !!endHour && !isFormInvalid(values)) {
      updateSelectedTicket(startHour.value as string, endHour.value as string, visitDate?.value as string);
      onModalClose();
    }
  };

  useEffect(() => {
    const newDate = new Date();

    if (values.startHour === undefined) {
      onChange('startHour', { key: 'startHour', value: newDate });
    }
    if (values.endHour === undefined) {
      onChange('endHour', { key: 'endHour', value: addMinutes(newDate, 30) });
    }
  }, [selectedTicket, isModalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseModal = () => {
    handleResetForm();
    onModalClose();
    onModalClose();
  };

  return (
    <ModalBase isModalOpen={isModalOpen} closeModal={handleCloseModal} headerText={'rescheduleVisit.rescheduleVisit'}>
      <form className="reschedule-visit-modal visit-modal modal" onSubmit={(e) => onFormSubmit(e)}>
        <div className="reschedule-visit-modal__reason">
          <TextFieldBase
            multiline
            minRows="2"
            maxRows="4"
            labelText="rescheduleVisit.reasonLabel"
            value={values.reason?.value}
            onChange={updateReasonField}
          />
        </div>
        <TextBase content={'rescheduleVisit.newDateTitle'} variant={'h6'} />
        <div>
          <DatePickerBase
            disablePast={false}
            handleChange={onChange}
            selectedDate={values.visitDate}
            pickerName={'visitDate'}
            labelText={'rescheduleVisit.newVisitDate'}
            handleError={(error) => onSetError('visitDate', error)}
          />
        </div>
        <div className="reschedule-visit-modal__timepicker">
          <div>
            <TimePickerBase
              selectedTime={values.startHour}
              handleChange={(eventTargetName, selectedValue) => handleTimeChange(eventTargetName, selectedValue)}
              pickerName={'startHour'}
              labelText={'rescheduleVisit.startHour'}
              handleError={(error) => onSetError('startHour', error)}
            />
          </div>
          <div className="reschedule-visit-modal__timepicker-separator">-</div>
          <div>
            <TimePickerBase
              selectedTime={values.endHour}
              handleChange={onChange}
              pickerName={'endHour'}
              labelText={'rescheduleVisit.endHour'}
              disabled={!values.startHour?.value}
              minTime={add(new Date(values.startHour?.value as string), { minutes: 15 })}
              handleError={(error) => onSetError('endHour', error)}
            />
          </div>
        </div>
        <div className="modal__btn-group">
          <ButtonBase content={'common.cancel'} size={'large'} type={'button'} onClick={handleCloseModal} />
          <ButtonBase
            content={'rescheduleVisit.rescheduleVisit'}
            variant={'contained'}
            disableElevation
            size={'large'}
            type={'submit'}
            disabled={isFormInvalid(values)}
          />
        </div>
      </form>
    </ModalBase>
  );
};

export default RescheduleVisitModal;
